.shareSec {
    &-text {
        font-weight: 500;
        font-size: 1.3em;
        text-align: center;
    }
    &-btn-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        @media (max-width: 300px) {
            gap: 8px;
        }
    }
    button {
        display: flex;
        min-width: max-content;
        flex-direction: column;
        align-items: center;
        background-color: transparent;
        border: 0;
        font-size: 0.8em;
    }
}
@import "../_mixins";
/* The Modal (background) */
.custom-modal {
  position: fixed; /* Stay in place */
  z-index: 1050; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

/* Modal Content */
.custom-modal-content {
  margin: 0 calc(100vw - 95vw) !important;
  width: auto !important;
  position: relative;
  background-color: #fefefe;
  padding: 0;
  border: 1px solid #888;
  border-radius: 1rem 1rem 0 0 !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  height: 100%;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.fade-out {
  -webkit-animation: fade-out 0.5s;
  animation: fade-out 0.5s;
}
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.custom-modal-header {
  align-items: center !important;
}

.custom-modal-title {
  margin: 0;
  @media (min-width: 1px) and (max-width: $screen-mobile-max) {
    font-size: 1rem;
  }
  @media (min-width: $screen-small-tablet-min) and (max-width: $screen-tablet-max) {
    font-size: 1rem;
  }
  @media (min-width: $screen-laptop-min) and (max-width: $screen-laptop-max) {
    font-size: 1rem;
  }
  @media (min-width: $screen-desktop-min) {
    font-size: 1.2rem;
  }
}

.custom-modal-body {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  p {
    @media (max-width: $screen-mobile-max) {
      font-size: .6rem;
    }
    @media (max-width: $screen-tablet-max) {
      font-size: .7rem;
    }
    @media (max-width: $screen-laptop-max) {
      font-size: .9rem;
    }
    @media (min-width: $screen-desktop-min) {
      font-size: 1rem;
    }
  }
}

.custom-modal-footer {
}

.custom-modal-close-btn {
  background-color: white !important;
  border-color: white !important;
  color: black !important;
}

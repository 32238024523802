@import "../_mixins";

.about-us-warpper{
    @include custom-container ;

    background-image: url(../../assets/img/aboutUs-normalsize.png);
    height: 447px;
    background-repeat: no-repeat;
    background-position: center;
    margin:95px auto 0;
    display: flex;
    justify-content: center;
    background-size: contain;

}

.about-us-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

.about-us-content-titleGroup {
    display:flex;
    flex-direction: column;
    text-align: center;
}

.about-us-content-firstTitle {
    max-width: 920px;
    color:#fff;
    font-size:38px;
    font-weight: 500;
    margin: 0 0 5px 0;
    .about-us-content-firstTitle-orange{
        color: #F09108;
    }
}


/* TOP DESCRIPTION SECTION START*/
.about-us-description-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 68px auto 95px;

    .left-wrapper {
        width: 50%;
        display: flex;
        flex-direction: column;
        flex: 1 1 0%;
        padding-right: 40px;

        h1 {
            text-align: left;
            color: #333333 !important;
            font-size: 32px;
            font-weight: 450;
            margin: 0 0 40px;
        }

        .bottom-wrapper {
            flex: 1 1 0%;
            position: relative;

            .image-container {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                img {
                    object-fit: contain;
                    height: 100%;
                    width: 100%;
                    max-width: 430px;
                }
            }
        }
    }

    .right-wrapper {
        width: 50%;
        display: flex;
        flex-direction: column;

        .subtitle {
            text-align: left;
            color: #939598 !important;
            font-size: 18px;

            a {
                color: #FB9600 !important;
            }
        }
    }
}
/* TOP DESCRIPTION SECTION END*/


.about-us-second-row-background{
    background-image: url(../../assets/img/house-about-us.svg);
    background-repeat: no-repeat;
    background-position: left;
    position: relative;
    min-width: auto !important;
    min-height: 273.98px !important;
    top: -60px !important;
  }

.about-us-second-row-title{
    text-align: left;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    font-size: 32px;
    font-weight: 450;
    margin: 0 0 0 60px;
    max-width: 456px;
    // min-width: fit-content;
}

.about-us-info-row{
    @include custom-container;
    margin: 30px auto 30px !important;
    .about-us-second-row-description{
        margin: 0 0 0 60px;
        max-width: 456px;
        text-align: left;
        letter-spacing: 0px;
        color: #939598;
        opacity: 1;
        font-size: 20px;
        margin-top: 46.31px;
    }
}
.about-us-third-row{
  margin: 0 auto 30px !important;
}

.about-us-third-row-title{
    text-align: center;
    letter-spacing: 0px;
    color: #F09108;
    opacity: 1;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;

}
.about-us-third-row-description{
    text-align: center;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    font-size: 32px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.about-us-third-row-phone-image{
    padding: 0px !important;
}
.about-us-third-row-phone-image-center{
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 0px !important;
}

///tablet/mobile*************
.about-us-third-row-info-left{
    text-align: right;
    justify-content: right;
    display: block !important;
    float: right;
    margin-top: 80px;
    max-width: 262px !important;
    // flex-direction: column;
   .about-us-third-row-info-left-title{
    text-align: right;
    letter-spacing: 0px;
    color: #414042;
    opacity: 1;
    font-size: 18px;
    font-weight: 450;
    // flex-direction: column;
   }
   .about-us-third-row-info-left-description{
    text-align: right;
    letter-spacing: 0px;
    color: #808285;
    opacity: 1;
    font-size: 12px;

    // flex-direction: column;
   }
}
///tablet/mobile*************
.about-us-third-row-info-right{
    text-align: left;
    justify-content: left;
    display: block !important;
    float: left;
    margin-top: 80px;
    max-width: 262px !important;
    // flex-direction: column;
   .about-us-third-row-info-right-title{
    text-align: left;
    letter-spacing: 0px;
    color: #414042;
    opacity: 1;
    font-size: 18px;
    font-weight: 450;
    // flex-direction: column;
   }
   .about-us-third-row-info-right-description{
    text-align: left;
    letter-spacing: 0px;
    color: #808285;
    opacity: 1;
    font-size: 12px;

    // flex-direction: column;
   }
}

.about-us-third-row-logo-col{
    padding: 0px !important;
    height: 58.65px;
    width: 58.65px;
    .about-us-third-row-logo-div{
        margin-top: 80px !important;
        position: relative;
        height: 70px;
        width: 70px;
        float: left !important;
    }
    .about-us-third-row-logo-div-right{//tablet mobile
        margin-top: 80px !important;
        position: relative;
        height: 70px;
        width: 70px;
        float: right !important;
    }
    .about-us-third-row-logo{
        height: 80px;
        width: 80px;
        position: relative;
    }
}

//mobile
.about-us-row-fourth{
    margin: 120px 0 120px 0 !important;
}
.about-us-row-fourth-image-div{
    max-height: 100% !important;
    max-width: 100% !important;
    .about-us-row-fourth-image{
        max-height: 100% !important;
        max-width: 100% !important;
    }
}

.about-us-row-fourth-title{
    text-align: left;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    font-size: 32px;
    font-weight: 500;
}
.about-us-row-fourth-description{
    text-align: left;
    letter-spacing: 0px;
    color: #939598;
    opacity: 1;
    font-size: 20px;
    margin-top: 25px;
}
.about-us-row-five{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 15px;
    opacity: 1;
    padding: 40px 30px 40px 30px;
    margin: auto;
    .about-us-row-five-video{
        // margin-bottom: 30px !important;
        position: relative;
        width: 100%;
        height: 300px;
        overflow: hidden;
        margin: auto;
        // padding-top: 380px;
        .responsive-iframe {
            position: relative;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            border: none;
        }
    }

    .about-us-row-five-title{
        text-align: left;
        letter-spacing: 0px;
        color: #F09108;
        opacity: 1;
        font-size: 28px;
        font-weight: 550;
        margin-bottom: 15px;
    }

    .about-us-row-five-description{
        text-align: left;
        letter-spacing: 0px;
        color: #6D6E71;
        opacity: 1;
        font-size: 18px;
        margin-bottom: 30px;
    }

    .about-us-row-five-app-logo-size{
        height: 56px;
        width: 180px;
        margin-right: 10px;
    }

}



//tablet
@media (min-width: $screen-tablet-min) and (max-width: $screen-laptop-max) {
    /* WHATS NEW STYLING START */
    .about-us-warpper {
        background-size: cover;
        border-radius: 15px;
        width: 100% !important;
        height: 100%;
        padding: 50px 0 ;
    }

    .about-us-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    .about-us-content-titleGroup {
        display:flex;
        flex-direction: column;
        text-align: center;
        max-width: 674px;
    }

    .about-us-content-firstTitle {
        max-width: 920px;
        color:#fff;
        font-size:34px !important;
        font-weight: 500;
        margin: 0 0 5px 0;
        text-align: center;
        .about-us-content-firstTitle-orange{
            color: #F09108;
        }
    }

    .about-us-second-row-background{
        background-image: url(../../assets/img/house-about-us.svg);
        background-size: 140% 75%;
        background-repeat: no-repeat;
        background-position: left;
        position: relative;
        max-width: 462px !important;
        max-height: 305.55px !important;
        top: -50px !important;
      }
    

    .about-us-second-row-title{
        text-align: left;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
        font-size: 28px;
        font-weight: 450;
        margin: 0 0 0 20px !important;
        max-width: 456px;
        position: relative;
        // min-width: fit-content;
    }

    .about-us-info-row{
        // @include custom-container;
        margin: 30px auto 30px !important;
        .about-us-second-row-description{
            margin: 20px 0 0 20px !important;
            max-width: 456px;
            text-align: left;
            letter-spacing: 0px;
            color: #939598;
            opacity: 1;
            font-size: 18px;
            margin-top: 46.31px;
        }
    }

    .about-us-third-row-phone-image{
        display: none;
    }



    ///tablet/mobile*************
    .about-us-third-row-info-left{
        text-align: left;
        justify-content: left;
        display: block !important;
        float: left;
        margin-top: 50px;
        max-width: 262px !important;
        // flex-direction: column;
    .about-us-third-row-info-left-title{
        text-align: left;
        letter-spacing: 0px;
        color: #414042;
        opacity: 1;
        font-size: 18px;
        font-weight: 450;
        // flex-direction: column;
    }
    .about-us-third-row-info-left-description{
        text-align: left;
        letter-spacing: 0px;
        color: #808285;
        opacity: 1;
        font-size: 12px;
        margin: 0px;

        // flex-direction: column;
    }
    }
///tablet/mobile*************
    .about-us-third-row-info-right{
        text-align: left;
        justify-content: left;
        display: block !important;
        float: left;
        margin-top: 50px;
        max-width: 262px !important;
        // flex-direction: column;
    .about-us-third-row-info-right-title{
        text-align: left;
        letter-spacing: 0px;
        color: #414042;
        opacity: 1;
        font-size: 18px;
        font-weight: 450;
        // flex-direction: column;
    }
    .about-us-third-row-info-right-description{
        text-align: left;
        letter-spacing: 0px;
        color: #808285;
        opacity: 1;
        font-size: 12px;

        // flex-direction: column;
    }
    }

    .about-us-third-row-logo-col{
        padding: 0px !important;
        height: 58.65px;
        width: 58.65px;
        .about-us-third-row-logo-div{
            margin-top: 50px !important;
            position: relative;
            height: 70px;
            width: 70px;
            float: left !important;
        }
        .about-us-third-row-logo-div-right{//tablet mobile
            margin-top: 50px !important;
            position: relative;
            height: 70px;
            width: 70px;
            float: left !important;
        }
        .about-us-third-row-logo{
            height: 95px;
            width: 95px;
            position: relative;
        }
    }


    .about-us-row-fourth{
        margin: 100px auto 100px !important;
    }
    .about-us-row-fourth-image-div{
        max-height: 100% !important;
        max-width: 100% !important;
        .about-us-row-fourth-image{
            max-height: 100% !important;
            max-width: 100% !important;
        }
    }

    .about-us-row-fourth-title{
        text-align: left;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
        font-size: 28px;
        font-weight: 550;
    }
    .about-us-row-fourth-description{
        text-align: left;
        letter-spacing: 0px;
        color: #939598;
        opacity: 1;
        font-size: 18px;
        margin-top: 25px;
    }

    .about-us-row-five{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 15px;
        opacity: 1;
        padding: 25px 10px 25px 10px;
        margin: auto;
        .about-us-row-five-video{
            // margin-bottom: 30px !important;
            position: relative;
            width: 100%;
            height: 300px;
            overflow: hidden;
            margin: auto;
            // padding-top: 380px;
            .responsive-iframe {
                position: relative;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                border: none;
            }
        }

        .about-us-row-five-title{
            text-align: center;
            letter-spacing: 0px;
            color: #F09108;
            opacity: 1;
            font-size: 28px;
            font-weight: 550;
            margin: 30px 0 15px 0;
        }

        .about-us-row-five-description{
            text-align: center;
            letter-spacing: 0px;
            color: #6D6E71;
            opacity: 1;
            font-size: 18px;
            margin-bottom: 30px;
        }
        .about-us-row-five-app-logo{
            text-align: center;

        }
        .about-us-row-five-app-logo-size{
            height: 56px;
            width: 180px;
            margin-right: 10px;
        }

    }
    
}

//mobile
@media (min-width: $screen-mobile-min) and (max-width: $screen-small-tablet-max) {
    .about-us-description-section {
        flex-direction: column !important;
        align-items: center;

        .left-wrapper {
            width: 100%;
            padding-right: 0px;
            margin: 0 0 25px;

            h1 {
                text-align: center;
                font-size: 22px;
                margin: 0 0 25px;
            }

            .bottom-wrapper {
                .image-container {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 70%;
                    }
                }
            }
        }

        .right-wrapper {
            width: 100%;

            .subtitle {
                text-align: center;
                font-size: 16px;
                margin: 0 0 12px;
            }
        }
    }

    .about-us-warpper { 
    background-size: cover;
    border-radius: 15px;
    width: 95% !important;
    height: 100%;
    padding: 40px 0 ;
    }
    .about-us-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    .about-us-content-titleGroup {
        display:flex;
        flex-direction: column;
        text-align: center;
        max-width: 674px;
    }

    .about-us-content-firstTitle {
        max-width: 674px;
        color:#fff;
        font-size: 24px !important;
        font-weight: 500;
        margin: 0 0 5px 0;
        text-align: center;
        .about-us-content-firstTitle-orange{
            color: #F09108;
        }
    }

    .about-us-second-row-background{
        background-image: url(../../assets/img/house-about-us.svg);
        background-size: 100% 75%;
        background-repeat: no-repeat;
        background-position: left;
        position: relative;
        max-width: 462px !important;
        max-height: 305.55px !important;
        top: -50px !important;
      }
    

    .about-us-second-row-title{
        text-align: center;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
        font-size: 22px;
        font-weight: 450;
        margin: 0 0 0 0px !important;
        max-width: 456px;
        position: relative;
        // min-width: fit-content;
    }

    .about-us-info-row{
        // @include custom-container;
        margin: 30px 0 30px 0;
        .about-us-second-row-description{
            margin: 20px 0 30px 0 !important;
            max-width: 456px;
            text-align: center;
            letter-spacing: 0px;
            color: #939598;
            opacity: 1;
            font-size: 16px;
        }
    }

    .about-us-third-row-phone-image{
        display: none;
    }

    .about-us-third-row-title{
        text-align: center;
        letter-spacing: 0px;
        color: #F09108;
        opacity: 1;
        font-size: 16px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 0px !important;
    }
    .about-us-third-row-description{
        text-align: center;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
        font-size: 22px;
        font-weight: 550;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 0px !important;
    }

    .about-us-third-row-info-left{
        text-align: left;
        justify-content: left;
        display: block !important;
        float: left;
        margin: 0 0 0 100px;
        max-width: 270px !important;
        // flex-direction: column;
    .about-us-third-row-info-left-title{
        text-align: left;
        letter-spacing: 0px;
        color: #414042;
        opacity: 1;
        font-size: 18px;
        font-weight: 500;
        // flex-direction: column;
    }
    .about-us-third-row-info-left-description{
        text-align: left;
        letter-spacing: 0px;
        color: #808285;
        opacity: 1;
        font-size: 12px;
        margin: 0px;

        // flex-direction: column;
    }
    }
///tablet/mobile*************
    .about-us-third-row-info-right{
        text-align: left;
        justify-content: left;
        display: block !important;
        float: left;
        margin: 0 0 0 100px;
        max-width: 270px !important;
        // flex-direction: column;
    .about-us-third-row-info-right-title{
        text-align: left;
        letter-spacing: 0px;
        color: #414042;
        opacity: 1;
        font-size: 18px;
        font-weight: 500;
        // flex-direction: column;
    }
    .about-us-third-row-info-right-description{
        text-align: left;
        letter-spacing: 0px;
        color: #808285;
        opacity: 1;
        font-size: 12px;

        // flex-direction: column;
    }
    }

    .about-us-third-row-logo-col{
        padding: 0px !important;
        height: 50px !important;
        width: 50px !important;
        .about-us-third-row-logo-div{
            margin-top: 50px !important;
            position: relative;
            height: 70px;
            width: 70px;
            float: left !important;
        }
        .about-us-third-row-logo-div-right{//tablet mobile
            margin-top: 50px !important;
            position: relative;
            height: 70px;
            width: 70px;
            float: left !important;
        }
        .about-us-third-row-logo{
            height: 95px;
            width: 95px;
            position: relative;
            margin-left: 20px;
        }
    }

    .about-us-row-fourth{
        margin: 80px 0 80px 0 !important;
    }
    .about-us-row-fourth-image-div{
        max-height: 100% !important;
        max-width: 100% !important;
        .about-us-row-fourth-image{
            max-height: 100% !important;
            max-width: 100% !important;
        }
    }

    .about-us-row-fourth-title{
        text-align: center;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
        font-size: 22px;
        font-weight: 550;
        padding: 30px 40px 20px 40px;
    }
    .about-us-row-fourth-description{
        text-align: center;
        letter-spacing: 0px;
        color: #939598;
        opacity: 1;
        font-size: 20px;
        margin-top: 0px;
    }
    
    .about-us-row-five{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 15px;
        opacity: 1;
        padding: 20px 10px 15px 10px;
        .about-us-row-five-video{
            // margin-bottom: 30px !important;
            position: relative;
            width: 100%;
            height: 280px;
            overflow: hidden;
            margin: auto;
            // padding-top: 380px;
            .responsive-iframe {
                position: relative;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                border: none;
            }
        }

        .about-us-row-five-title{
            text-align: center;
            letter-spacing: 0px;
            color: #F09108;
            opacity: 1;
            font-size: 22px;
            font-weight: 550;
            margin: 30px 0 15px 0;
        }

        .about-us-row-five-description{
            text-align: center;
            letter-spacing: 0px;
            color: #6D6E71;
            opacity: 1;
            font-size: 18px;
            margin-bottom: 30px;
        }
        .about-us-row-five-app-logo{
            text-align: center;

        }
        .about-us-row-five-app-logo-size{
            height: 45px;
            width: 142px;
            margin-right: 10px;
        }

    }


}



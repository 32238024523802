@import "../_mixins";

.mortgage-cal-wrapper {
  @include custom-container;
  margin: 42px auto 36px;
  padding: 28px 20px 10px 20px;

  .mortgage-cal-card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 15px;
    margin-top: 20px;
    padding: 30px;

    h1 {
      font-size: 34px;
      color: #414042;
      margin: 15px 0;
    }

    h2 {
      font-size: 24px;
      color: #414042;
      margin: 10px 0;
    }

    h4 {
      font-size: 15px;
      color: #808285;
      margin: 5px 0;
    }
  }

  .mortgage-cal-link {
    color: $nextsix-orange;
  }
}

.mortgage-cal-landing-wrapper {
  @include custom-container;
  .mortgage-cal-landing-card {
    border-radius: 15px;
    .row {
      align-items: center;  
      .mortgage-cal-card-img {
        // background: #08366B 0% 0% no-repeat padding-box;
        border-radius: 15px;
      }
      .mortgage-cal-description {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-content: space-between;
        h2 {
          color: #333333;
        }
        .mortgage-cal-text {
          color: #A7A9AC;
          font-weight: 300;
        }
        .mortgage-cal-btn {
          background: $nextsix-orange 0% 0% no-repeat padding-box;
          border-radius: 5px;
          border-color: $nextsix-orange;
          .mortgage-cal-btn-text {
            color: #FFFFFF;
          }
          &:active {
            background: $nextsix-orange 0% 0% no-repeat padding-box;
            border-color: $nextsix-orange;
          }
        }
      }    
    }
  }
}
@media (max-width: $screen-mobile-max) {
  .mortgage-cal-landing-wrapper {
    .mortgage-cal-landing-card {
      padding: 20px;
      .row {
        .mortgage-cal-description {
          padding: 15px 20px;
          height: fit-content;
          h2 {
            font-size: 24px;
          }
          .mortgage-cal-text {
            font-size: 16px;
          }
          .mortgage-cal-btn {
            width: 100%;
            padding: 10px 30px;
            .mortgage-cal-btn-text {
              font-size: 18px;
            }
          }
        }
      } 
    }
  }
}
@media (min-width: $screen-small-tablet-min) and (max-width: $screen-tablet-max) {
  .mortgage-cal-landing-wrapper {
    .mortgage-cal-landing-card {
      padding: 30px;
      .row {
        .mortgage-cal-description {
          padding: 20px 20px;
          height: fit-content;
          h2 {
            font-size: 26px;
          }
          .mortgage-cal-text {
            font-size: 18px;
          }
          .mortgage-cal-btn {
            padding: 10px 40px;
            .mortgage-cal-btn-text {
              font-size: 18px;
            }
          }
        }
      } 
    }
  }
}
@media (min-width: $screen-laptop-min) and (max-width: $screen-laptop-max) {
  .mortgage-cal-landing-wrapper {
    margin: 50px auto !important;
    .mortgage-cal-landing-card {
      padding: 20px;
      .row {
        .mortgage-cal-description {
          padding: 10px 10px;
          height: fit-content;
          h2 {
            font-size: 26px;
          }
          .mortgage-cal-text {
            font-size: 18px;
          }
          .mortgage-cal-btn {
            padding: 10px 40px;
            .mortgage-cal-btn-text {
              font-size: 18px;
            }
          }
        }
      } 
    }
  }
}
@media (min-width: $screen-desktop-min) {
  .mortgage-cal-landing-wrapper {
    .mortgage-cal-landing-card {
      padding: 30px;
      .mortgage-cal-description {
        margin: 30px;
        height: fit-content;
        width: 90%;
        h2 {
          font-size: 26px;
        }
        .mortgage-cal-text {
          font-size: 18px;
        }
        .mortgage-cal-btn {
          padding: 10px 30px;
          .mortgage-cal-btn-text {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.property-row-redirectLink2:hover {
  text-decoration: none;
}

.property-slider .image-gallery-image {
  width: 100%;
  border-radius: 13px !important;
  object-fit: cover;
  aspect-ratio: 1.3; 
}
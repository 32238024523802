.business-card-content {
  height: 100vh;
  overflow: hidden;
}

.business-card-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.business-card-nextsix-logo {
    margin:25px 0;
}
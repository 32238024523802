// color
$nextsix-orange: #f09108;
$red-tag: #cc0000;
$green-tag: #00a651;
$blue-tag: #1656ab;
$lightblack-text: #414042;
$grey-text: #a7a8ab;
$light-orange: #f9e0c0;
$black-filterText: #58595b;
$yellow-counter: #f7c312;
$dark-orange: #f38400;
$offline-grey: #808184;
$border-grey: #E7E7E7;


// media queries
$screen-mobile-min: 1px;
$screen-mobile-max: 599px;

$screen-small-tablet-min: 600px;
$screen-small-tablet-max: 767px;

$screen-tablet-min: 768px;
$screen-tablet-max: 991px;

$screen-laptop-min: 992px;
$screen-laptop-max: 1199px;

$screen-desktop-min: 1200px;



 
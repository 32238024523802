@import "../mixins";

$nextsix-orange: #f09108;

.info-card-image {
  height: 140px !important;
  width: 250px !important;
  border-radius: 10px !important;
}

.Property-List-Padding {
  padding: 0;

  .Property-List-Padding-headerTag-result {
    color: $nextsix-orange;
  }

  .Property-List-Padding-headerTag-description {
    margin-bottom: 25px;
  }
}

.Property-List-Padding-headerTag {
  font-size: 14px;
  color: rgb(82, 80, 80);
  margin-bottom: 5px;
}

.Property-List-Padding-headerTag-description-button {
  color: #1388c9;
  background-color: transparent;
  font-size: 11.5px;
  border: transparent;
  padding: 0 0 0 5px;
  text-decoration: underline #1388c9;
  :hover::after::selection:active:focus {
    background-color: transparent !important;
    font-size: 11.5px !important;
    border: transparent !important;
    padding: 0 0 0 5px !important;
  }
}

.property-search-content {
  padding: 10px 0 0 0;
  display: flex;
  max-width: 550px;
  min-width: fit-content;
  .property-search-bar {
    width: 100%;
    display: flex;
    margin: 0 0 10px 0;
    .search-button-action {
      color: #fff;
      background: $nextsix-orange;
      border-color: $nextsix-orange;
      box-shadow: none !important;
      border-radius: 0px 7px 7px 0;
      &:hover {
        box-shadow: 0px 0px 0 1px $nextsix-orange !important;
      }
      &:focus {
        background: $nextsix-orange !important;
        border-color: $nextsix-orange !important;
      }
    }
    .quicksearch {
      flex: 1;
      > div {
        border: none;
        border-radius: 0px;
        box-shadow: none;
      }
      .css-yk16xz-control {
        border-radius: 0 0 0 4px;
      }
    }

    .search-filter {
      &.sub-search {
        background-color: $nextsix-orange;
        border-radius: 7px 0 0 7px;
      }

      .search-dropdown-toggle {
        display: flex;
        align-items: center;
        color: #fff;
        margin: 0 15px 0 0 !important;
        padding: 0;
        &:hover {
          color: $nextsix-orange !important;
        }

        &:focus {
          box-shadow: none !important;
        }

        &:after {
          content: url(../../assets/img/dropdown-white-thin.svg) !important;
          border: none !important;
        }

        &.sub-search {
          &:hover {
            color: #fff !important;
          }
        }

        .search-filter-label.sub-search {
          margin-bottom: 0;
          padding: 0 0 0 10px;
          margin-right: 18px;
        }

        label {
          cursor: pointer !important;
        }
      }

      .search-dropdown-menu {
        max-height: 350px;
      }

      .search-dropdown-menu-double-select-container {
        width: fit-content;
      }

      .search-dropdown-menu-scroll {
        max-height: 350px;
        overflow-y: hidden;
      }
    }
  }
}

//in used in agent-search-page
.agent-property-buttonselection-padding {
  left: -16px !important;
  position: relative;
}

.agent-property-buttonselection {
  padding: 0 16px 10px 16px;
  .search-category-buttonselection {
    background: #ffffff;
    border-color: $nextsix-orange;
    margin: 0 5px 0 0;
    border-radius: 4px;
    padding: 6px 20px;
    box-shadow: none !important;
    color: #000;
    margin-top: 10px;

    &:hover {
      background: #f8d7a9;
      border-color: $nextsix-orange;
      color: $nextsix-orange;
    }
  }

  .search-category-buttonselection-selected {
    background: #f8d7a9;
    border-color: $nextsix-orange;
    margin: 0 5px 0 0;
    border-radius: 4px;
    padding: 6px 20px;
    box-shadow: none !important;
    color: #000;
  }
}

.multi-selection-label {
  background-color: #d4cccc;
  color: #707070;
  margin: 20px 10px 0 0;
  padding: 10px 10px 6px 10px;
  width: auto;
  border-radius: 6px;
}

.row-map .infinite-scroll-component__outerdiv {
  margin-right: 10px !important;
}

.col-map {
  order: 1;
}
.col-map-scroll-list {
  padding: "5px";
  order: 2;
}
.Property-List-Padding-skeleton {
  width: 100%;
}

@media (min-width: 1px) and (max-width: $screen-mobile-max) {
  .propSearch-map-and-list {
    > .breadcrumb {
      .breadcrumb-item {
        font-size: 12px;
        padding-left: 2px;
        &::before {
          padding-right: 2px;
        }
      }
    }
    .ps-sub-header-sticky {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      .propSearch-toggle-and-search {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .propSearch-redirect-and-search {
          order: 2;
          margin-top: -32px;
          width: 100%;
          .propSearch-redirect {
            display: flex;
            width: calc(100% - 110px);
            .btn {
              flex: 1;
            }
          }
          .property-search-bar-col-padding {
            flex: 1;
            width: 100%;
            .property-search-content {
              .property-search-bar {
                max-width: 100%;
                display: flex;
                margin: 0 0 10px 0;
                .search-button-action {
                  padding: 0px 10px;
                }
                .quicksearch {
                  max-width: calc(100vw - 155px);
                }
              }
              .rw-popup-container {
                margin: 0px -30px 0px -80px;
              }
            }
          }
        }
        .propSearch-map-toggle {
          display: flex;
          order: 1;
          align-self: flex-end;
          margin: 4px 5px 0px 0px;
          z-index: 2;
          align-items: center;
          .ps-map-switch-text {
            margin-right: 4px;
          }
        }
      }
      .filter-selection-col {
        gap: 10px;
        .propertyTitle {
          width: 100%;
        }
        .priceRange, .buildUp, .bedRoom, .moreFilter {
          width: calc(50% - 5px);
        }
        .propertyList-multidropdown {
          display: flex;
          width: 100%;
          > div {
            flex: 1;
          }
          .multiselect-container {
            width: 100% !important;
            flex: 1;
            .search-wrapper {
              width: 100%;
              padding: 5px !important;
              gap: 2px;
              span {
                padding: 0px 0px 0px 5px !important;
                font-size: 10px;
              }
              .searchBox {
                width: 100%;
              }
            }
          }
        }
        .propSearch-filter-dropdown {
          width: 100%;
          margin: 0px;
          .search-dropdown-filter {
            width: 100%;
            button {
              display: flex;
              flex: 1;
              align-items: center;
              .propSearch-filter-label {
                flex: 1;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }

  .btn-map-and-list {
    color: #ffffff !important;
    margin-left: 10px !important;
    background-color: #f09108 !important;
    border-radius: 7px !important;
    padding: 5px 10px !important;
    border-color: transparent !important;
    height: fit-content !important;
    width: fit-content !important;
    :hover:focus:active {
      background-color: #f09108 !important;
      border-color: transparent !important;
    }
  }

  .btn-map-and-list-agent {
    color: #ffffff !important;
    // margin-left: 10px !important;
    background-color: #f09108 !important;
    border-radius: 7px !important;
    padding: 5px 10px !important;
    border-color: transparent !important;
    height: fit-content !important;
    width: fit-content !important;
    :hover:focus:active {
      background-color: #f09108 !important;
      border-color: transparent !important;
    }
  }

  .col-map-scroll-list {
    padding: 0px !important;
  }

  .agent-list-col-padding {
    padding: 0 10px 0 10px !important;
  }

  .propSearch-redirect-and-search {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .propSearch-map-and-list {
    > .breadcrumb {
      .breadcrumb-item {
        font-size: 12px;
        padding-left: 2px;
        &::before {
          padding-right: 2px;
        }
      }
    }
    .ps-sub-header-sticky {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      .propSearch-toggle-and-search {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .propSearch-redirect-and-search {
          order: 2;
          margin-top: -32px;
          width: 100%;
          .propSearch-redirect {
            display: flex;
            width: calc(100% - 110px);
            .btn {
              flex: 1;
            }
          }
          .property-search-bar-col-padding {
            flex: 1;
            width: 100%;
            .property-search-content {
              min-width: 100%;
              .property-search-bar {
                width: 100% !important;
              }
              .rw-popup-container {
                margin: 0px -25px 0px -30px;
              }
            }
          }
        }
        .propSearch-map-toggle {
          display: flex;
          order: 1;
          align-self: flex-end;
          margin: 4px 5px 0px 0px;
          z-index: 2;
          align-items: center;
          .ps-map-switch-text {
            margin-right: 4px;
          }
        }
      }
      .filter-selection-col {
        gap: 10px 20px;
        .propertyTitle {
          width: 100%;
        }
        .priceRange, .buildUp, .bedRoom, .moreFilter {
          width: calc(50% - 10px);
        }
        .propertyList-multidropdown {
          display: flex;
          width: 100%;
          > div {
            flex: 1;
          }
          .multiselect-container {
            width: 100% !important;
            .search-wrapper {
              width: 100%;
              padding: 5px !important;
              gap: 2px;
              span {
                padding: 0px 0px 0px 5px !important;
                font-size: 10px;
              }
              .searchBox {
                width: 100%;
              }
            }
          }
        }
        .propSearch-filter-dropdown {
          width: 100%;
          margin: 0px;
          .search-dropdown-filter {
            width: 100%;
            button {
              display: flex;
              flex: 1;
              align-items: center;
              .propSearch-filter-label {
                flex: 1;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
  .Map-Warp {
    display: none !important;
  }
  .btn-map-and-list {
    color: #ffffff !important;
    margin-left: 10px !important;
    background-color: #f09108 !important;
    border-radius: 7px !important;
    padding: 5px 10px !important;
    border-color: transparent !important;
    height: fit-content !important;
    width: fit-content !important;
    :hover:focus:active {
      background-color: #f09108 !important;
      border-color: transparent !important;
    }
  }
  .btn-map-and-list-agent {
    color: #ffffff !important;
    // margin-left: 10px !important;
    background-color: #f09108 !important;
    border-radius: 7px !important;
    padding: 5px 10px !important;
    border-color: transparent !important;
    height: fit-content !important;
    width: fit-content !important;
    :hover:focus:active {
      background-color: #f09108 !important;
      border-color: transparent !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .propSearch-map-and-list {
    .ps-sub-header-sticky {
      .propSearch-toggle-and-search {
        .propSearch-redirect-and-search {
          width: calc(100% - 150px);
          .property-search-bar-col-padding {
            flex: 1;
            .property-search-content {
              .rw-popup-container {
                margin: 0px -40px 0px -80px;
              }
            }
          }
        }
      }
    }
  }
  .col-map {
    display: none !important;
  }
  .Map-Warp {
    display: none !important;
  }
  .btn-map-and-list {
    color: #ffffff !important;
    margin-left: 10px !important;
    background-color: #f09108 !important;
    border-radius: 7px !important;
    padding: 5px 10px !important;
    border-color: transparent !important;
    height: fit-content !important;
    width: fit-content !important;
    :hover:focus:active {
      background-color: #f09108 !important;
      border-color: transparent !important;
    }
  }
  .btn-map-and-list-agent {
    color: #ffffff !important;
    // margin-left: 10px !important;
    background-color: #f09108 !important;
    border-radius: 7px !important;
    padding: 5px 10px !important;
    border-color: transparent !important;
    height: fit-content !important;
    width: fit-content !important;
    :hover:focus:active {
      background-color: #f09108 !important;
      border-color: transparent !important;
    }
  }
}

.burger-filter-margin-padding {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0px !important;
}

.go-to-top-button {
  position: relative;
  width: 100%;
  height: 10px;
  font-size: 16px;
  padding: 0 20px 40px 30px;
  z-index: 1;
  cursor: pointer;
  color: $nextsix-orange !important;
}

.property-map-card {
  max-width: 250px;
  max-height: 400px;
}

.locationlogo {
  width: 20px;
  height: 15px;
}

.facilitylogo {
  width: 15px;
  height: 15px;
}

.facilitylogo-description {
  padding: 0 10px 0 5px;
  font-size: 12px;
}

.search-logo {
  width: 30px;
  height: 30px;
}

.property-card-facilitylogo-padding {
  padding-left: 8px;
  color: #000;
}

.pre-error-container {
  height: calc(100vh - 281px);
  max-width: fit-content;
  padding: 20px 0 0 20px;
}

.pre-error-container-height {
  height: 90px;
}

.col-map.col {
  padding: 0px !important;
  margin: 0px;
  width: fit-content;
}

.propertyList-container {
  margin: 0 !important;
}

.amount-property-found-styling {
  text-align: left;
  margin: 0px 0 25px 0;
  display: flex;
  justify-content: space-between;
  .amount-property-found {
    color: #f09108;
    margin: 0px;
  }
}

.amount-property-found-styling a{
  color: #f09108;
}

.amount-property-found-styling a:hover{
  color: #f09108;
}


.img-fluid.imgcardfixed {
  width: 100% !important;
  height: 160px !important;
  object-fit: cover !important;
}

.property-list-pricelabel {
  min-height: 220px;
  max-height: fit-content;
  padding: 18px 0 18px 0;

  h3 {
    color: #f38400;
    font-size: 22px;
  }
}

.property-list-pricelabel.card-title {
  min-height: auto !important;
  max-height: fit-content !important;
}

.property-list-card-subtitle {
  max-height: fit-content;
  min-height: 40px;
}

.property-list-card-address {
  max-height: fit-content;
  min-height: 40px;
}

.property-list-statuslabel {
  color: #fff;
  background: #e13247;
  position: relative;
  padding: 7px;
  margin: 0 5px 0 0;
  font-size: 12px;
  line-height: 16px;
  width: 59px;
  border-radius: 4px;
  text-align: center;
  height: 28px;
  min-width: fit-content;
}

.rent-type {
  background-color: #1e58a8;
}

.property-list-project-statuslabel {
  font-weight: 500;
  position: relative;
  padding: 5px 10px 5px 10px;
  vertical-align: baseline;
  font-size: 16px;
  line-height: 16px;
  border-radius: 4px;
  font-size: 16px;
  margin: 0;
  background-color: #6ccf24 !important;
  color: #fff;
  width: fit-content;
  text-align: center;
  height: 28px;
}

.property-list-price {
  color: #fb9600;
  font-weight: 500;
  font-size: 26px;
  padding-bottom: 10px;
}
.property-list-address {
  margin: 0 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}
.propertyList-card-price {
  color: #fb9600;
  font-weight: 500;
  font-size: 26px;
}

.property-list-statuslabel-slide {
  justify-content: space-between !important;
  align-items: center;
  display: flex;
}

.buylabel {
  background: #e13247 !important;
  width: fit-content;
  border-radius: 10px;
  text-align: left;
}

.property-list-date {
  text-align: right !important;
  float: right;
}

.property-list-dev-company {
  text-align: right !important;
  float: right;
  vertical-align: top;
  height: 40px;
  width: 100px;
  padding-top: -10px !important;
  margin-top: -10px !important;
}

.property-list-dev-company-logo {
  vertical-align: top;
  height: 40px;
  width: 100px;
}
.property-list-card-name {
  font-size: 19px;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 200px;
}
.property-list-card {
  background: #ffffff !important;
  border-radius: 20px !important;
  box-shadow: 10px #ededef !important;
  border-color: #ededef 1px !important;
  border: 1px !important;
  margin-right: 0px !important;
}

.property-list-ad {
  height: 434px;
  justify-content: center;
  display: flex;
  margin: auto;
  cursor: pointer;
  overflow: hidden;

  .property-list-adVideo {
    border-radius: 15px;
  }
}

.agent-list-ad {
  height: 350px;
  justify-content: center;
  display: flex;
  margin: auto;
  cursor: pointer;
  overflow: hidden;

  .agent-list-adVideo {
    border-radius: 15px;
  }
}

.property-list-container {
  @include custom-container2;
  background: #f5f6fa;
}

//dropdown menu start
.search-dropdown-menu-double-select-container.dropdown-menu.show,
.search-dropdown-menu-double-select-container {
  padding: 12px 0px 0 10px;
}
//dropdown menu end

//multi-select start
.filter-selection-col {
  display: flex;
  padding: 0 !important;
  flex-wrap: wrap;
}

.propertyList-multidropdown {
  margin-bottom: 15px;

  span.icon_down_dir {
    background-image: url(../../assets/img/searchProp-dropdown.svg) !important;
    background-repeat: no-repeat;
    background-position: center;
    width: 18px;
    height: 18px;
  }

  span.icon_down_dir:before {
    content: "";
  }

  .searchWrapper {
    padding: 0 0 0 15px !important;
  }
}

.multiselect-container.multiSelectContainer {
  padding-bottom: 0px !important;
}
//multi-select end

.property-list-empty {
  margin: 90px auto 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.property-list-propertyTab-infiniteScroll2 {
  max-height: 900px;
  min-height: fit-content;
  overflow-y: scroll;
  overflow-x: hidden;
}

.property-list-table-sorter {
  text-align: right !important;
  .property-list-sorter-label {
    margin: 0;
    font-size: 12.8px;
    color: #58595b;
  }
  .property-list-sorter-select {
    border: transparent;
    color: $nextsix-orange;
    background: transparent;
    text-overflow: ellipsis !important;
    max-width: 140px !important;
    font-size: 12.8px;
  }

  .property-list-sorter-option {
    color: rgb(108, 117, 125) !important;
  }

  .property-list-sorter-button {
    border: transparent;
    background: transparent;
    height: 30px;
    width: 40px;
  }
}

.property-list-Maplabel-infowindow {
  background-color: #00a82a;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px 5px 10px;
  border-radius: 7px;
  margin-bottom: 0px;
  height: 28px;
  position: relative;
}
.property-list-Maplabel-infowindow:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: #00a82a transparent transparent transparent;
}

.auction-date-range {
  border: 1px solid #939598;
  border-radius: 7px !important;
  height: 31px;
  width: 220px;
  padding-right: 7px;

  .react-datepicker-wrapper {
    vertical-align: middle;
  }

  .auction-date-range-input {
    color: #58595b !important;
    font-size: 12px;
    padding: 0px 15px 0 15px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: inherit;

    &:focus {
      box-shadow: none !important;
    }

    &:focus-visible {
      outline: none;
    }

    &:after {
      content: url(../../assets/img/dropdowngrey.svg) !important;
      border: 2px !important;
      border-color: $nextsix-orange;
      vertical-align: middle;
      margin-bottom: 4px;
    }
  }

  .auction-date-range-wrapper {
    .react-datepicker__close-icon {
      margin-right: -2px;

      &:after {
        background-color: $nextsix-orange;
      }
    }
  }

  .auction-date-range-calendar {
    margin: 0 10px;

    .react-datepicker__header {
      background-color: #ffffff;
      border-bottom: none;
    }

    .react-datepicker__triangle::after {
      border-bottom-color: #ffffff !important;
    }

    .react-datepicker__day--keyboard-selected {
      border-radius: 50%;
      background-color: #5692f7 !important;
    }

    .react-datepicker__day--range-start {
      background-color: #5692f7 !important;
    }

    .react-datepicker__day--range-end {
      background-color: #5692f7 !important;
    }

    .react-datepicker__day--in-range {
      border-radius: 50%;
      background-color: #a7c6fc;
    }

    .react-datepicker__day--in-selecting-range {
      border-radius: 50%;
    }
  }
}

// redirect, free text and map switch start
.propSearch-toggle-and-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px;
}

.propSearch-redirect-and-search {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.propSearch-sticky-search {
}

.propSearch-redirect {
  height: fit-content;
}

.propSearch-filter-dropdown {
  border-radius: 7px;
  border: 1px solid #939598;
  margin: 0 12px 12px 0;
  display: inline-table;
  padding: 0 15px;
  height: fit-content;
  min-height: 31px;
}

.propSearch-dropdown-toggle {
  padding: 0 !important;

  &:after {
    content: url(../../assets/img/searchProp-dropdown.svg) !important;
    border: 0px !important;
    margin: 0 !important;
    vertical-align: 0 !important;
  }
}

.propSearch-filter-label {
  font-size: 12px;
  margin-right: 15px;
  color: #58595b;
}

// redirect, free text and map switch end

.propSearch-map-and-list {
  margin: 25px auto 30px;
  padding: 0 15px;
}
.propSearch-result-count {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.propSearch-result-title {
  font-size: 28px;
}

.propSearch-result-number {
  color: $nextsix-orange;
}

.propSearch-result-text {
  font-size: 13px;
  margin-bottom: 0;
  font-weight: unset;
}

.propSearch-listing {
  display: grid;
  grid-column-gap: 15px;
}

.propSearch-listing-five-col {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.propSearch-listing-four-col {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.propSearch-listing-three-col {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.propSearch-listing-two-col {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.propSearch-listing-one-col {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.propCard {
  display: contents;
}

.propSearch-show-listing {
  overflow-x: hidden;
  height: fit-content; //was 870px Edited by Tzeh Pin
  overflow-y: auto;
  width: 100%;
  background-color: #f5f6fa;
}

.propSearch-no-result {
  height: 650px;
  color: #afafbe;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.propSearch-show-map {
  position: sticky !important;
  height: calc(100vh - 90px);
  top: 90px;
  border-radius: 15px !important;
  overflow: hidden !important;
  margin: 0 15px;
}

.propSearch-hide-content {
  display: none;
}

.ps-padding-full {
  padding: 0 15px;
}

.ps-width-full {
  width: 100%;
}

.ps-width-half {
  width: 50%;
}

//map switch start
.ps-map-switch-text {
  color: #58595b;
  font-size: 10px;
  margin-right: 10px;
}

.ps-map-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin-bottom: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.ps-map-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c5c5c5;
  transition: 0.4s;
  border: 1px solid #c6c6c6;
  height: 24px;
  width: 40px;
}

/* Rounded sliders */
.ps-map-slider.round {
  border-radius: 20px;
}

.ps-map-slider.round:before {
  border-radius: 50%;
}

.ps-map-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .ps-map-slider {
  background-color: $nextsix-orange;
  border-color: $nextsix-orange;
}

input:focus + .ps-map-slider {
  box-shadow: 0 $nextsix-orange;
}

input:checked + .ps-map-slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

//map switch end
 
.stateContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
  max-width: calc(100vw - 20px);
}

.stateP,
.typeP {
  width: 380px;
}

.showMoreLess {
  cursor: pointer;
  color: #007bff;
  :hover {
    text-decoration: underline !important;
  };
}
// Large devices (large tablets, 992px and up)
@media (min-width: $screen-laptop-min) and (max-width: $screen-laptop-max) {
  .propSearch-map-and-list {
    .ps-sub-header-sticky {
      height: fit-content;
      .propSearch-toggle-and-search {
        .propSearch-redirect-and-search {
          // width: calc(100% - 250px);
          .property-search-bar-col-padding {
            flex: 1;
            .property-search-content {
              .rw-popup-container {
                margin: 0px;
              }
            }
          }
        }
      }
    }
  }
  .Map-Warp {
    height: 800px;
    width: 100%;
    padding-top: 50px;
    border-radius: 15px !important; //
    overflow: hidden !important; //
  }

  .col-map {
    border-radius: 15px !important; //
    overflow: hidden !important; //
    margin: 10px 0px 0px 0px;
  }
  .row-map {
    border-radius: 15px !important; //
    overflow: hidden !important; //
  }

}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: $screen-desktop-min) {
  .Map-Warp {
    height: 870px;
    width: 100%;
    padding-top: 50px;
    border-radius: 15px !important; //
    overflow: hidden !important; //
  }
  .col-map {
    width: fit-content;
    right: 0px;
    bottom: 0px;
    max-height: fit-content;
    touch-action: manipulation;
    transform: translate3d(0px, 0px, 0px);
    margin: 10px 0px 0px 0px;

    border-radius: 15px !important; //
    overflow: hidden !important; //
  }
  .row-map {
    max-height: 960px !important;
    border-radius: 15px !important; //
    overflow: hidden !important; //
  }
  .Property-List-Padding {
    height: auto;
    position: fixed;
    top: 100px;
    max-width: 49%;
    min-width: 49%;
    z-index: 1;
  }
  .Property-List-Padding-skeleton {
    padding: 0;
    position: fixed;
    top: 100px;
    width: 50%;
    z-index: 1;
  }

  .ps-sub-header-sticky {
    position: sticky !important;
    top: 90px;
    z-index: 5;
    & > div {
      background: #f5f6fa;
    }
  }

  .property-search-bar-col-padding {
    padding-left: 10px;
    width: 32.9%;
  }

  .col-map-scroll-list {
    max-width: 1600px;
  }
  .propSearch-show-map {
    height: calc(100vh - 200px) !important;
    top: 200px !important;
  }
  .propSearch-map-and-list {
    width: 1250px;
    .ps-sub-header-sticky {
      height: fit-content;
      .propSearch-toggle-and-search {
        .propSearch-redirect-and-search {
          // width: calc(100% - 150px);
          .property-search-bar-col-padding {
            flex: 1;
            .property-search-content {
              .rw-popup-container {
                margin: 0px;
              }
            }
          }
        }
      }
    }
  }

}

@media (max-width: 820px) {
  .stateContainer {
    gap: 0px;
  }
}

.property-slider .image-gallery-image {
  width: 100%;
  border-radius: 13px !important;
  object-fit: cover;
  aspect-ratio: 1.3; 
}
@import "../variables";

// HERO SECTION
.hero-wrapper {
    padding-top: 7%;
    background-color: white;
    width: 100%;
}
.hero-container {
    padding: 1.7rem 0 0 0 !important;
}
.hero-row {
    margin: auto !important;
    width: 100% !important;
}
.hero-left-col {
    padding-bottom: 7.8125rem;
    padding-top: 3.75rem;
}
.hero-right-col {
    padding: 0 !important;
    overflow: hidden;
    position: relative;
}
.hero-title {
    font-size: 60px;
    font-weight: 500;
    padding-bottom: 1.375rem;
    line-height: normal;
}
.hero-subtitle {
    font-size: 20px;
    font-weight: 300;
    color: #9B9B9B;
    padding-bottom: 35.5px;
}
.hero-subtitle span {
    display: inline-block;
}
.hero-btn {
    border-radius: 7px !important;
    border: 1px solid #F09108 !important;
    background: white !important;
    color: #F09108 !important;
    padding: 0.5rem 1rem !important;
    font-size: 20px !important;
}
.hero-btn:hover {
    text-decoration: none !important;
    background: #fff3e3 !important
}
.hero-img {
    position: absolute;
    top: 0;
    width: 90% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: top right;
}


// FEATURED SECTION
.featured-wrapper {
    padding: 5rem 0;
    background-color: #F5F6FA;
    width: 100%;
}
.featured-title {
    font-size: 32px;
    font-weight: bold;
    color: #234652;
    text-align: center;
    line-height: normal;
    padding-bottom: 4.35rem;
    margin-bottom: 0 !important;
}
.featured-carousel-wrapper {
    text-align: center !important;
    margin: auto;
}
.featured-wrapper .hHJTFD {
    margin: 0 !important;
}
.featured-wrapper a:hover {
    text-decoration: none !important;
}
.featured-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.featured-card-title {
    min-height: 60px !important;
    width: 220px !important;
    text-align: center !important;
    color: #234652 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-style: italic !important;
    margin-bottom: 1rem;
}
.featured-card-img-container {
    min-height: 50px !important;
    overflow: hidden;
}
.featured-card-img {
    object-fit: cover;
    object-position: center;
    width: 100%;
}


// ABOUT SECTION
.about-wrapper {
    padding: 2rem 0 5rem 0;
    background-color: #F5F6FA;
    width: 100%;
}
.about-container {
    background: white;
    border-radius: 16px;
    padding: 2.125rem !important;
}
.about-row-top {
    margin: 0 0 3rem 0 !important;
}
.about-row-top .col {
    padding: 0 !important;
}
.about-row-bottom {
    margin: 0 0 0 0 !important;
}
.about-title {
    font-size: 32px;
    font-weight: bold;
    color: #234652;
    text-align: center;
    line-height: normal;
    padding-bottom: 2.35rem;
    margin-bottom: 0 !important;
}
.about-subtitle {
    font-size: 18px;
    font-weight: normal;
    color: #234652;
    text-align: center;
    line-height: normal;
    margin-bottom: 0 !important;
}
.about-subtitle span {
    font-weight: bold;
}
.about-video-left {
    padding: 0 !important;
}
.about-video-right {
    padding: 0 0 0 2.63rem !important;
    color: #234652 !important;
}
.about-video-title-1 {
    margin-bottom: 1.22rem;
}
.about-video-title-2 {
    font-size: 20px;
    color: #F09108 !important;
    font-weight: bold !important;
    margin-bottom: 1.934rem;
}
.about-social-container {
    margin-top: 1.55rem;
    display: flex;
    align-items: center;
}
.about-social-icon {
    height: 25px;
    width: 25px;
}
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}

// HomePro all win section
.tq-allwin {
    display: flex;
    align-items: center;
    padding: 80px 15px;
    gap: 20px;

    &-img {
        margin-left: -35px;
    }
    &-title {
        color: $nextsix-orange;
        font-size: 36px;
        font-weight: 500;
        margin-bottom: 20px;
    }
}


// RESPONSIVE
@media (min-width: $screen-laptop-min) {
    .hero-left-col {
        padding-left: 60px !important;
    }

    .hero-img {
        left: 30px;
    }
}

@media (max-width: $screen-laptop-min) {
    .hero-left-col {
        padding-bottom: 3.75rem;
        text-align: center !important;
    }
    .hero-right-col {
        height: 300px;
    }
    .about-video-left {
        height: 350px;
        margin-bottom: 2.63rem !important;
    }
    .about-video-right {
        padding: 0 0 0 0 !important;
    }
    .tq-allwin {
        flex-direction: column;
        align-items: center;
        &-img {
            margin-left: unset;
            img {
                width: 100%;
            }
        }
        &-text {
            text-align: center;
        }
    }
}
@media (max-width: 768px) {
    .about-video-left {
        height: 250px;
    }
}
@media (max-width: 576px) {
    .about-video-left {
        height: 200px;
    }
    .hero-right-col {
        height: 200px;
    }
}


.about-us-video-container {
    margin-bottom: 30px !important;
    position: relative;
    width: 100%;
    height: 90%;
    overflow: hidden;
    margin: auto;
    padding-top: 380px;
}
.about-us-video-mobile-container {
    margin-bottom: 30px !important;
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: auto;
    padding-top: 250px;
}
.containerbackground {
    background-color: #f7f9fd;
    width: 100%;
    overflow: hidden;
}
.feature-in {
    padding: 90px 0;
}
.feature-in .feature-text {
    text-align: center !important;
    color: #234652 !important;
    font-size: 34px !important;
    margin: 0 0 0 0 !important;
    font-weight: 500;
}

.featureContainer {
    position: relative;
    height: 45vh;
    width: 100%;
    display: flex;
    flex: 1;   
    justify-content: center;
    align-items: center;
}

.featureContainerCard {
    min-height: 80px !important;
    width: 100% !important;
    text-align: center !important;
    background: #fff !important;
    color: #234652 !important;
    font-size: 14px !important;
    box-sizing: border-box !important;
}
.cardDescription {
    margin: 0 20px 0 20px !important;
    font-style: italic;
}
.aboutus {
    text-align: center !important;
    color: #234652 !important;
    margin-bottom: 30px !important;
    margin-top: 50px !important;
    font-size: 42px !important;
    font-weight: 500;
}
.aboutus2 {
    text-align: center !important;
    color: #234652 !important;
    margin-bottom: 30px !important;
    margin-top: 50px !important;
    font-size: 36px !important;
}
.aboutusDescription {
    text-align: center !important;
    color: #234652 !important;
    font-size: 19px !important;
    margin-bottom: 60px !important;
}
.aboutusDescription2 {
    text-align: left !important;
    font-size: 19px !important;
    color: #234652 !important;
    margin-left: 20px !important;
    margin-bottom: 60px !important;
}
.aboutusDescriptionHasttag {
    color: #234652 !important;
    font-weight: 500;
}
.aboutusVideoDescription {
    text-align: left !important;
    margin-left: 20px !important;
    color: #234652 !important;
    font-size: 18px !important;
}
.aboutusVideoDescription2 {
    text-align: left !important;
    margin-left: 20px !important;
    margin-bottom: 20px !important;
    color: #f09108;
    font-size: 24px !important;
    margin-top: 20px;
    font-weight: 500;
}
.socialmedia {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
    margin-right: 15px !important;
    margin-left: 5px !important;
}
.CenterSocialmedia {
    text-align: center;
}
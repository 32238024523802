.agentSuggestion.rw-combobox.rw-widget{
    flex:1 ;
}

.agentSuggestion .rw-widget-picker {
    border:none;
    height: 40px;
}

.agentSuggestion .rw-combobox-input {
    box-shadow: none;
}

.agentSuggestion .as-tag {
    font-size: 12px;
    background-color: #1656AB;
    border-radius: 4px;
    margin: 0 10px 0 0;
    color: #fff;
    height: 20px;
    padding: 1px 8px;
    font-style: italic;
}

.agentSuggestion .ts-tag-info {
    white-space: nowrap;
}

.agentSuggestion .ts-tag {
    font-size: 12px;
    background-color: #00A651;
    border-radius: 4px;
    margin: 0 10px 0 0;
    color: #fff;
    height: 20px;
    padding: 1px 8px;
    font-style: italic;
}

// .agentSuggestion .ts-tag-title {
//     overflow: hidden;
//     text-overflow: ellipsis;
// }
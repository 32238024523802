@import "../mixins";

// custom
.agentsp-custom-m-t {
  margin-top: var(--agentsp-custom-m-t, 0);
}
.agentsp-custom-m-b {
  margin-bottom: var(--agentsp-custom-m-b, 0);
}
.agentsp-custom-m-x {
  margin-left: var(--agentsp-custom-m-x, 0);
  margin-right: var(--agentsp-custom-m-x, 0);
}
.agentsp-custom-m-y {
  margin-top: var(--agentsp-custom-m-y, 0);
  margin-bottom: var(--agentsp-custom-m-y, 0);
}
.agentsp-custom-p-x {
  padding-left: var(--agentsp-custom-p-x, 0) !important;
  padding-right: var(--agentsp-custom-p-x, 0) !important;
}
.agentsp-custom-p-y {
  padding-top: var(--agentsp-custom-p-y, 0);
  padding-bottom: var(--agentsp-custom-p-y, 0);
}
.agentsp-custom-p-s {
  padding-left: var(--agentsp-custom-p-s, 0) !important;
}
.agentsp-custom-p-e {
  padding-right: var(--agentsp-custom-p-e, 0) !important;
}
.agentsp-custom-m-b {
  margin-bottom: var(--agentsp-custom-m-b, 0);
}
.agentsp-custom-position {
  position: var(--agentsp-custom-position);
}
.agentsp-custom-height {
  height: var(--agentsp-custom-height);
}
.agentsp-custom-fs {
  font-size: var(--agentsp-custom-fs);
}

// wrapper
.agentsp-wrapper {
  @media (min-width: 1250px) {
    margin-right: auto;
    margin-left: auto;
    width: 1250px;
  }
}

// text
.agentsp-text-darker-grey {
  color: $offline-grey;
}

// border
.agentsp-selector {
  border: 1px solid $nextsix-orange;
  border-radius: 7px;
  height: 38px;
  box-sizing: border-box;
  width: 100%;
}

// select dropdown
.agentsp-select__option--is-selected {
  background-color: rgba($color: $nextsix-orange, $alpha: 1) !important;
  color: white !important;
  &.agentsp-select__option--is-focused {
    background-color: rgba($color: $nextsix-orange, $alpha: 1) !important;
    color: white !important;
  }
}
.agentsp-select__option--is-focused {
  background-color: rgba($color: $grey-text, $alpha: 0.1) !important;
}

// map
.agentsp-map-placeholder {
  position: sticky;
  border-radius: 7px !important;
  @media (max-width: 1199px) {
    top: 90px;
    height: calc(100vh - 90px);
  }
  @media (min-width: 1200px) {
    top: 200px;
    height: calc(100vh - 200px);
  }
  > div > div {
    border-radius: 7px !important;
  }
}
.agentsp-map-placeholder-wrapper {
  display: flex;
}
.agentsp-map-refiner-sticky {
  @media (min-width: 1200px) {
    position: sticky;
    top: 90px;
    z-index: 1;

    > div {
      background-color: #f5f6fa;
    }
  }
}

// list
.agentsp-list-placeholder-wrapper {
  height: fit-content; // initial max-height: 800px
  overflow: auto;
  .infinite-scroll-component {
    overflow: hidden !important;
  }
}
.agentsp-list-placeholder-wrapper-offset {
  $topOffset: 160px;

  @media (min-width: 1200px) {
    position: relative;
    top: -#{$topOffset};
  }
}
.agentsp-list-placeholder-card {
  height: 350px;
  border-radius: 15px !important;
  margin-bottom: 15px;

  .card-image {
    height: 200px;
    border-radius: 15px 15px 0px 0px !important;
  }
}
.agentsp-list-card {
  .agent-list-card-body {
    display: flex;
    flex-direction: column;
    border: 1px solid $border-grey;
    .card-img {
      aspect-ratio: 1;
      object-fit: cover;
      border-radius: 15px;
    }
    .agent-list-card-row-body2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      .status-wrapper {
        position: relative;
        top: unset;
        justify-content: center;
        p {
          height: fit-content;
          width: fit-content;
        }
        .rank {
          margin: 0px;
        }
      }
      .agent-name {
        display: flex;
        line-height: 1;
        p {
          flex: 1;
          text-align: center;
        }
      }
      .specialist {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        text-align: center;
        > div {
          display: flex;
          flex-direction: column;
          flex: 1 0;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          line-height: 1;
          svg {
            path {
              fill: white;
            }
          }
          &.hidden {
            position: absolute;
          }
          &:hover {
            p:nth-child(3) {
              &::after {
                content: '';
              }
            }
            p:nth-child(n+4) {
              display: flex;
            }
          }
          p {
            line-height: 1;
            align-items: center;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            &:nth-child(3) {
              font-size: 0px;
              &::after {
                content: '...';
              }
            }
            &:nth-child(n+4) {
              display: none;
            }
          }
        }
        .area-specialist {
          .area-tag {
            display: flex;
            width: fit-content;
            margin: auto;
          }
          .area-text {
            color: $blue-tag;
          }
        }
        .title-specialist {
          .title-tag {
            display: flex;
            width: fit-content;
            margin: auto;
          }
          .title-text {
            color: $green-tag;
          }
        }
      }
      .details {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
      }
      .sale-data {
        display: flex;
        justify-content: center;
      }
    }
  }
  .card {
    border-radius: 15px !important;
  }
}
.agentsp-list-summary {
  color: $nextsix-orange;
  font-weight: bold;

  &.sticky {
    position: sticky;
    top: var(--agentsp-custom-sticky-top, 0);
    z-index: 1;
  }
  .found {
    color: $grey-text;
    font-weight: normal;
  }
}

// button
.agentsp-button {
  padding: 0px 25px;
  border-radius: 7px;
  border: 1px transparent;
  color: white;
  min-width: 85px;
  background-color: $nextsix-orange;
  cursor: pointer;

  &.selector {
    background-color: #f5f6fa;
    color: $lightblack-text;
    font-size: 14px;
    transition: 0.2s ease-in-out;

    @media (max-width: 768px) {
      flex-grow: 1;
    }

    &.active {
      background-color: $light-orange;
      color: $nextsix-orange;
    }

    &:hover {
      color: $nextsix-orange;
    }
  }

  &.search {
    border-radius: 0px 7px 7px 0px;
    padding: 0px 15px;
    min-width: max-content;
  }

  &.mobile-toggle {
    height: 31px;
    padding: 0;
  }
}

// input
.agentsp-text-input-wrapper {
  width: 100%;
  height: 38px;
  .agentSuggestion {
    > div:first-child {
      border: 0px transparent;
      border-radius: 7px 0px 0px 7px;
      color: $lightblack-text;
      height: inherit;
      outline: none;
      box-shadow: none;
    }
  }
}

// toggle
.agentsp-show-map {
  &.text {
    font-size: 10px;
    color: $black-filterText;
  }
}

// switch
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.agentsp-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c5c5c5;
  transition: 0.4s;
  border: 1px solid #c6c6c6;
  height: 24px;
  width: 40px;
}

/* Rounded sliders */
.agentsp-slider.round {
  border-radius: 20px;
}

.agentsp-slider.round:before {
  border-radius: 50%;
}

.agentsp-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .agentsp-slider {
  background-color: $nextsix-orange;
  border-color: $nextsix-orange;
}

input:focus + .agentsp-slider {
  box-shadow: 0 $nextsix-orange;
}

input:checked + .agentsp-slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
@media (max-width: $screen-mobile-max) {
  .agentsp-list-card {
    .agent-list-card-body {
      .agent-list-card-row-body2 {
        width: 100%;
        height: 180px;
        padding: 10px 2px;
        .status-wrapper {
          height: 28px;
          margin-top: -28px;
          p {
            padding: 2px 5px;
          }
          .status {
            font-size: 16px;
          }
          .rank {
            font-size: 16px;
            margin: 0px;
          }
        }
        .agent-name {
          margin-bottom: 4px;
          p {
            font-size: 24px;
          }
        }
        .specialist {
          width: 100%;
          height: 36px;
          > div {
            margin: 0px;
            svg {
              width: 14px;
            }
            &:hover {
              background-color: white;
              p:nth-child(3) {
                font-size: 12px;
              }
            }
            p {
              font-size: 12px;
              width: 100%;
              margin: 2px 0px;
              &:nth-child(3) {
                &::after {
                  font-size: 12px;
                }
              }
            }
          }
          .area-specialist {
            .area-tag {
              gap: 2px;
              padding: 3px 6px;
            }
          }
          .title-specialist {
            .title-tag {
              gap: 2px;
              padding: 3px 6px;
            }
          }
        }
        .details {
          font-size: 16px;
          line-height: 1.4;
        }
        .sale-data {
          p {
            font-size: 14px;
            margin: 0px;
          }
        }
      }
    }
  }
}
@media (min-width: $screen-small-tablet-min) and (max-width: $screen-laptop-max) {
  .agentsp-list-card {
    .agent-list-card-body {
      .agent-list-card-row-body2 {
        width: 100%;
        height: 180px;
        padding: 10px 2px;
        .status-wrapper {
          height: 28px;
          margin-top: -28px;
          p {
            padding: 2px 5px;
          }
          .status {
            font-size: 14px;
          }
          .rank {
            font-size: 14px;
            margin: 0px;
          }
        }
        .agent-name {
          margin-bottom: 4px;
          p {
            font-size: 20px;
          }
        }
        .specialist {
          width: 100%;
          height: 36px;
          > div {
            margin: 0px;
            svg {
              width: 14px;
            }
            &:hover {
              background-color: white;
              p:nth-child(3) {
                font-size: 11px;
              }
            }
            p {
              font-size: 11px;
              width: 100%;
              margin: 2px 0px;
              &:nth-child(3) {
                &::after {
                  font-size: 11px;
                }
              }
            }
          }
          .area-specialist {
            .area-tag {
              gap: 2px;
              padding: 2px 6px;
            }
          }
          .title-specialist {
            .title-tag {
              gap: 2px;
              padding: 2px 6px;
            }
          }
        }
        .details {
          font-size: 15px;
          line-height: 1.4;
        }
        .sale-data {
          p {
            font-size: 13px;
            margin: 0px;
          }
        }
      }
    }
  }
}
@media (min-width: $screen-desktop-min) {
  .agentsp-list-card {
    .agent-list-card-body {
      .agent-list-card-row-body2 {
        width: 100%;
        height: 150px;
        padding: 10px 2px;
        .status-wrapper {
          height: 25px;
          margin-top: -25px;
          p {
            padding: 2px 5px;
          }
          .status {
            font-size: 12px;
          }
          .rank {
            font-size: 12px;
            margin: 0px;
          }
        }
        .agent-name {
          p {
            font-size: 16px;
          }
        }
        .specialist {
          width: 100%;
          height: 30px;
          > div {
            margin: 0px;
            svg {
              width: 10px;
            }
            &:hover {
              background-color: white;
              p:nth-child(3) {
                font-size: 9px;
              }
            }
            p {
              font-size: 9px;
              width: 100%;
              margin: 1px 0px;
              &:nth-child(3) {
                &::after {
                  font-size: 9px;
                }
              }
            }
          }
          .area-specialist {
            .area-tag {
              gap: 2px;
              padding: 2px 6px;
            }
          }
          .title-specialist {
            .title-tag {
              gap: 2px;
              padding: 2px 6px;
            }
          }
        }
        .details {
          line-height: 1.2;
        }
        .sale-data {
          p {
            font-size: 11px;
            margin: 0px;
          }
        }
      }
    }
  }
}
@import "../mixins";

/* EMPTY DIV STYLING START */
.agentPage-empty2 {
    margin: 90px auto 0;
    border-top: 1px solid rgba(0,0,0,.1);
}
/* EMPTY DIV STYLING END */

/* BACK TO SEARCH STYLING START */
.back-to-search2 {
    margin: 15px 0 20px 0;
    display: flex;
    align-items: center;
}

.back-to-search-button2 {
    cursor: pointer;
    background: transparent;
    min-width: 100px;
}

.back-to-search-text2 {
    color:#F09108;
    font-size:14px;
    cursor: pointer;
}

.url-path2 {
    color:#AFAFBE;
    font-size:14px;
    margin:0 0 0 80px;
}

.url-path-next2 {
    margin: 0 3px;
}
/* BACK TO SEARCH STYLING END */

/* AGENT INFO LEFT STYLING START */
.agentPage-info2 {
    background-color:#fff;
    border-radius: 15px;
    display: flex;
    padding: 30px 40px 30px;
}

.agentPage-infoAvatar-wrapper2 {
    display: flex;
}

.agentPage-infoAvatar2 {
    margin: 0 48px 0 0;
}

.agentPage-infoAvatar-starText2 {
    background-color: #F7C312;
    border-radius: 4px;
    margin-right: 4px;
    font-size: 14px;
    color:#fff;
    padding: 1px 5.5px;
}

.agentPage-infoAvatar-noRatingText {
    color:#A7A8AB;
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
}

.agentPage-infoAvatar-starImg2 {
    margin: 0 0 4px 4px;
}

.agentPage-infoAvatarImg2 {
    width: 153px;
    height: 153px;
    border-radius: 50%;
    object-fit: cover;
}

.agentPage-infoAvatar-right2 {
    display:flex;
    margin-right:50px;
    flex-direction: column;
}

.agentPage-infoAvatar-name2 {
    color:#F38400;
    font-size:26px;
    font-weight: 500;
}

.agentPage-infoAvatar-companySection2 {
    margin: 10px 0 27px 0px
}

.agentPage-infoAvatar-companyName2 {
    color:#A7A8AB;
    font-size: 18px;
    font-weight: 300;
}

.agentPage-infoAvatar-renId2 {
    color:#A7A8AB;
    font-size: 18px;
    font-weight: 300;
    border-left: 1px solid rgba(0,0,0,0.1);
    margin: 0 0 0 12px;
    padding: 0 0 0 19px;
}

.agentPage-infoAvatar-phoneButton2 {
    background-color: #F09108 !important;
    border-radius: 7px !important;
    padding: 0 16px !important;
    border-color: transparent !important;
    margin: 0 20px 0 0;
    height: 36px;
    width: fit-content;
}

.agentPage-infoAvatar-phoneButton2:hover,.agentPage-infoAvatar-phoneButton2:active, .agentPage-infoAvatar-phoneButton2:focus {
    background-color: #F09108  !important;
    border-color:#F09108  !important;
    color:#fff !important;
}

.agentPage-infoAvatar-whatsappButton2 {
    background-color: #42D700 !important;
    border-radius: 7px !important;
    border-color:transparent !important;
    padding: 0 !important;
    height: 36px;
    width: 149px;
}

.agentPage-infoAvatar-whatsappButton2:hover,.agentPage-infoAvatar-whatsappButton2:active, .agentPage-infoAvatar-whatsappButton2:focus {
    background-color: #42D700  !important;
    border-color:#42D700  !important;
    color:#fff !important;
}

.agentPage-infoAvatar-phoneIcon2 {
    margin-right:9px
}

.agentPage-infoAvatar-whatsappIcon2 {
    margin-right:2px
}

/* AGENT INFO LEFT STYLING END */

/* AGENT INFO RIGHT STYLING START */
.agentPage-info-right2 {
    display: flex;
    flex-direction: column;
}

.agentPage-info-rightTitle2 {
    color:#414042;
    font-size: 16px;
    font-weight: 500;
    margin:0 0 15px 0;
}

.agentPage-info-rightCounter2 {
    display: flex;
    justify-content: center;
    margin:0 0 35px 0;
}

.counterBoxBorder2 {
    border-right: 1px solid rgba(0,0,0,0.1);
}

.agentPage-info-rightCounterBox2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.agentPage-info-rightCount2 {
    color:#A7A8AB;
    font-size: 25px;
    font-weight: 500;
}

.agentPage-info-rightText2 {
    color:#A7A8AB;
    font-size: 10px;
    font-weight:400;
    width:65px;
    text-transform: uppercase;
}

.agentPage-infoAvatar-viewEye2 {
    margin: 0 3px 0 10px;
}
/* AGENT INFO RIGHT STYLING END */

/* AGENT ABOUT RIGHT STYLING START */
.agentPage-aboutMe2 {
    background-color: #fff;
    border-radius: 15px;
    margin: 40px 0 10px;
    width: 500px;
}

.agentPage-aboutMeTitle2 {
    color:#414042;
    font-size: 18px;
    font-weight: 500;
    margin:0 0 11px 0;
}

.agentPage-aboutMeText2 {
    color:#808285;
    font-size: 18px;
    width: 578px;
    margin:0 40px 0 0;
}

.agentPage-aboutMeAS2 {
    color:#fff;
    font-size: 17px;
    font-weight: 500;
    background-color: #1E58A8;
    border-radius: 6px;
    padding: 2px 12px;
    height: 30px;
    text-align: center;
    margin:0 0 10px 0;
    width: fit-content;
}

.agentPage-aboutMeTS2 {
    color:#fff;
    font-size: 17px;
    font-weight: 500;
    background-color: #2EAD4A;
    border-radius: 6px;
    padding: 2px 12px;
    height: 30px;
    text-align: center;
    margin:0 0 10px 0;
    width: fit-content;
}

.agentPage-aboutMe-textImg2 {
    margin:0 12px 3px 0;
}

.agentPage-aboutMe-text2 {
    color:#808285;
    font-size: 18px;
    margin:0 0 0 0;
}

.agentPage-aboutAS-block2 {
    margin:0 0 20px 0;
}

/* AGENT ABOUT RIGHT STYLING END */

/* TAB & MAP STYLING START */
.agentPage-propertyTab-wrapper {
    padding: 0 !important;
    margin:15px 0 0 0;
}

.agentPage-propertyTabMap2 {
  background-color: #fff;
  border-radius: 15px;
  margin: 26px auto 29px;
  display: flex;
  height: 664px;
  .agentPage-propertyTab2 {
    padding: 28px 10px !important;
    .agentPage-propertyTab-wrapper {
    }
  }
  .agentPage-propertyMap2 {
    padding: 0 !important;
  }
}

.agentPage-propertyTab-navItem2 {
    width: 50%;
    text-align: center;
}

.agentPage-propertyTab-navItem2 .nav-link {
    color:#404041 !important;
    font-size:14px;
}

.agentPage-propertyTab-navItem2 .nav-link.active {
    background-color: #F09108 !important;
    color:#fff !important;
    border-color:transparent !important;
}

.agentPage-propertyTab-navItem2 .nav-link:hover, .agentPage-propertyTab-navItem2 .nav-link:active, .agentPage-propertyTab-navItem2 .nav-link:focus {
    color:#fff !important;
    border-color: transparent !important;
    background-color: #F09108;
}

.agentPage-propertyTab-navBar2 {
    border-radius: 7px;
    border: 1px solid rgba(0,0,0,0.1);
    width: 99.99%;
}

.agentPage-propertyMap-content2 {
    height: 664px;
    width: 100%;
}
.agentPage-propertyMap-content2-map-preview{
    height: -webkit-fill-available;
    width: -webkit-fill-available;
}

.agentPage-propertyMap-content2 > div > div {
    border-radius: 0 15px 15px 0;
}

.agentPage-propertyTab-infiniteScroll2 {
    height: 568px !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}



.agentPage-propertyTab-plot2 {
    font-size: 10px;
    color: #fff;
    border-radius: 4px;
    background: #00A82A 0% 0% no-repeat padding-box;
    width: max-content;
    padding: 7px 9px;
    // text-align: center;
    position: absolute;
    transform: translateZ(0) translate(-50%, -50%);
    // backface-visibility: hidden;
}

// .agentPage-propertyTab-plot2::after {
//     content: " ";
//     position: absolute;
//     top: 100%; /* At the bottom of the tooltip */
//     left: 50%;
//     margin-left: -4px;
//     border-width: 4px;
//     border-style: solid;
//     border-color: #00A82A transparent transparent transparent;
//   }


/* TAB & MAP STYLING END */

/* CHROME LIKE BROWSWER STYLING START */
.custom-infinite-scrollbar2 {
    &::-webkit-scrollbar {
    width: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(0,0,0,0.1);
    }

    &::-webkit-scrollbar-thumb {
        background: #A7A8AB; 
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #F09108; 
    }
}

/* CHROME LIKE BROWSWER STYLING END */

/* FIREFOX BROWSWER STYLING START */
// .custom-infinite-scrollbar2 {
//     scrollbar-color: #A7A8AB rgba(0,0,0,0.1);
//     scrollbar-width:thin;
// }

// .custom-infinite-scrollbar2:hover {
//     scrollbar-color: #F09108 rgba(0,0,0,0.1);
// }
/* FIREBOX BROWSWER STYLING END */

/* SHARE STYLING START */
.agentSearch_flagModal > .modal-content {
    background: transparent;
    border: none;
}
.agentSearch_flagModal{
    margin: auto !important;
}
/* SHARE STYLING END*/

@media (min-width: $screen-desktop-min) {
    .agentPage-container2 {
        width: 1005px !important;
    }

    .agentPage-propertyTab-wrapper {

        .row {
            margin-right: 0px;
            margin-left: 0px;
        }

        .mb-3.col-12 {
            padding: 0
        }
    }
}

@media (min-width: $screen-tablet-min) and (max-width: $screen-laptop-max) {
    .agentPage-info2 {
        display: flex;
        flex-direction: column;
    }

    .agentPage-info-rightText2 {
        margin: auto;
    }

    .agentPage-propertyTab-navItem2 .nav-link {
        font-size: 13px;
    }
}

@media (min-width: $screen-mobile-min) and (max-width: $screen-small-tablet-max) { 
    .agentPage-propertyMap2 {
        display:none;
    }

    .agentPage-infoAvatar-wrapper2 {
        flex-direction: column;
        text-align: center;
    }

    .agentPage-info2 {
        flex-direction: column;
    }

    .agentPage-infoAvatar2 {
        margin: 0;
    }

    .agentPage-infoAvatar-right2 {
        margin-right: 0;
    }

    .agentPage-infoAvatar-contactSection2 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .agentPage-infoAvatar-phoneButton2 {
        margin:0 0 8px 0;
    }

    .agentPage-aboutMeTitle2 {
        font-size:16px;
    }

    .agentPage-propertyTab-navItem2 .nav-link {
        font-size:12px;
    }

    .agentPage-aboutMe2, .agentPage-aboutMeText2 {
        width: unset;
    }

    .url-path2 {
        margin: 0 0 0 30px;
    }

    .agentPage-loaderText {
        margin:10px 0 0 0;
    }
}

@import "../_variables.scss";

.pg404-container {
    margin: 200px 0 110px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-align: center;

    p, h1 {
        margin: unset;
    }

    .pg404-img {
        height: auto;
        max-width: 100%;
    }

    .pg404-h1 {
        color: $nextsix-orange;

        @media (max-width: 457px) {
            font-size: 32px;
        }
    }

    .pg404-desc {
        color: $black-filterText;
    }
    
    .pg404-btn {
        min-height: max-content;
        height: 40px;
        border-radius: 30px;
        padding: 0 20px;

        &.fill {
            border: 1px solid $nextsix-orange;
            background-color: $nextsix-orange;
            color: white;
        }

        &.report {
            border: 1px solid $blue-tag;
            background-color: transparent;
            color: $blue-tag;
        }
    }

    .pg404-link-wrapper {
        display: flex;

        .pg404-link {
            color: $nextsix-orange;
            padding: 0 15px;
            cursor: pointer;

            &:nth-child(2) {
                border-left: 1px solid #A7A9AC;
                border-right: 1px solid #A7A9AC;
            }
        }
    }
}

@import "../mixins";

.redirectLink:hover {
  text-decoration: none;
}

.emptyBlock {
  margin: 90px 0 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.displayNone {
  display: none;
}

.contentsBlock {
  @include custom-container;
}

.dropdown_stateLabel {
  text-transform: capitalize;
}

.resultsBlockAndMap {
  margin: 0 0 60px 0;
}

.resultsBlock {
  height: 800px;
  overflow-y: auto;
  width: 95%;
}

.mapBlock_agent_marker,
.mapBlock_infoCenter_marker {
  // text-align: center;
  position: absolute;
  transform: translateZ(0) translate(-50%, -50%);
  // backface-visibility: hidden;
}

.agentCard {
  border-radius: 15px;
  background-color: #fff;
  display: flex;
  margin: 0 0 20px 0;
}

.agentCard_firstBlock {
  position: relative;
  width: 175px;
}

.agentCard_currentStatus {
  position: absolute;
  top: 50%;
  display: flex;
  transform: translate(-50%, -50%);
  left: 50%;
}

.agentCard_agentImg {
  border-radius: 15px 0 0 15px;
}

.agentCard_agentImg_skeleton {
  border-radius: 15px 0 0 15px;
  padding-top: 5px;
}

.agentCard_online_status {
  font-size: 15px;
  color: #fff;
  height: 25px;
  width: 65px;
  background: $green-tag;
  text-align: center;
  border-radius: 5px;
  margin: 0 5px 0 0;
}

.agentCard_offline_status {
  font-size: 15px;
  color: #fff;
  height: 25px;
  width: 65px;
  background: #808184;
  text-align: center;
  border-radius: 5px;
  margin: 0 5px 0 0;
}

.agentCard_rank {
  font-size: 15px;
  color: #fff;
  height: 25px;
  width: max-content;
  background: $yellow-counter;
  text-align: center;
  border-radius: 5px;
  padding: 0 3px;
}

.agentCard_rankImg {
  margin: -1px 0 0 2px;
}

.agentCard_secondBlock {
  padding: 9px 24px;
  width: 380px;
}

.agentCard_name {
  color: $lightblack-text;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 5px 0;
}

.agentCard_asTag {
  display: flex;
  margin: 0 0 5px 0;
}

.agentCard_asTag_header {
  font-size: 10px;
  background-color: $blue-tag;
  border-radius: 4px;
  margin: 0 10px 0 0;
  color: #fff;
  height: fit-content;
  padding: 1px 8px;
  font-style: italic;
}

.agentCard_asTag_text {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 12px;
  color: $grey-text;
  white-space: nowrap;
}

.agentCard_asTag_img {
  margin: -4px 8px 0 0;
}

.agentCard_tsTag {
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;
  margin: 0 0 5px 0;
}

.agentCard_tsTag_header {
  font-size: 10px;
  background: $green-tag;
  border-radius: 4px;
  margin: 0 10px 0 0;
  color: #fff;
  height: fit-content;
  padding: 1px 8px;
  font-style: italic;
}

.agentCard_tsTag_text {
  margin: 0;
  font-size: 12px;
  color: $grey-text;
}

.agentCard_tsTag_text:last-child {
  overflow: hidden;
  text-overflow: ellipsis;
}

.agentCard_tsTag_img {
  margin: 0 8px 0 0;
}

.agentCard_numberCompany {
  color: $grey-text;
  margin: 0 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.agentCard_propCount {
  display: flex;
}

.agentCard_propCount_data {
  color: $grey-text;
  font-size: 10px;
}

.agentCard_propCount_number {
  color: $yellow-counter;
  font-weight: 350;
}

.agentCard_propCount_number {
  color: $yellow-counter;
  font-weight: 350;
}

.agentCard_ketupat{
  height: 75px;
  width: 45px;
  position: absolute;
  bottom: 0px;
  right: 10px;
}

.agentCard_ad {
  height: 180px;
  justify-content: center;
  display: flex;
  margin: 0 0 20px 0;
  cursor: pointer;
}

.agentCard_adVideo {
  border-radius: 15px;
}

.mapBlock {
  height: 830px;
  min-width: fit-content;
  margin: 0 0 0 0px;
}

.mapBlock > div > div {
  border-radius: 15px;
}

.mapBlock_agentImg {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  cursor: pointer;
}

.mapBlock_infoCenterImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mapBlock_infoCenter_header {
  display: flex !important;
  width: max-content;
  background: $dark-orange;
  min-height: 30px;
  max-height: fit-content;
  padding: 6px 11px;
  border-radius: 15px;
  transform: translate(0, 6px);
}

.mapBlock_infoCenter_area {
  font-size: 15px;
  color: #ffffff;
  margin: 0 8px 0 0;
  align-items: center;
  display: flex;
  max-width: 365px !important;
}

.mapBlock_flagInfoWindow {
  width: 360px;
  height: 555px;
  background: #fff;
  padding: 16px 18px;
  border-radius: 7px;
}

.mapBlock_skeleton {
  height: 100%;
}

.searchBar {
  margin: 10px 0 0 0;
}

.searchBar_input {
  height: 40px;
  width: 100%;
  max-width: 380px;
  border: none;
  border-radius: 7px 0 0 7px;
  display: flex;
}

.searchBar_button {
  border: none;
  background: none;
  padding: 0;
  height: 41px;
  width: 48px;
  background-color: $nextsix-orange;
  border-radius: 0 7px 7px 0;
}

.searchBar_input,
.searchBar_button:focus,
.searchBar_button:focus-visible {
  outline: none;
}

.filtersBlock {
  display: flex;
  margin: 20px 0 25px 0;
  align-items: center;
}

.filtersBlock_redirect {
  border: 1px solid $nextsix-orange;
  width: fit-content;
  border-radius: 7px;
  display: flex;
  // height: 40px;
  margin: 0 5px 0 0;
}

.filtersBlock_redirectButton {
  background: transparent !important;
  border: none !important;
  border-radius: 7px !important;
  color: $black-filterText !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  height: 38px;
  padding: 0 25px !important;
  font-weight: 400 !important;
}

.filtersBlock_redirectButton:hover {
  background: $light-orange !important;
  color: $nextsix-orange !important;
}

.active_redirectButton {
  background: $light-orange !important;
  color: $nextsix-orange !important;
}

.filter_dropdownLabel {
  font-size: 14px;
  color: #58595b;
}

.filter_dropdownToggle {
  margin: 0 5px 0 5px;
  padding: 5px 15px 0 15px !important;
  border-color: #939598 !important;
  border-radius: 7px !important;
  height: 40px;
}

.filter_dropdownToggle:hover {
  cursor: pointer;
  background: $light-orange;
}

.filter_dropdownToggle:after {
  content: url(../../assets/agentSearch/blackArrowDown.svg) !important;
  border: none !important;
  vertical-align: 1.5px !important;
}

.filter_dropdownToggle:focus,
.filter_dropdownToggle:focus-visible {
  box-shadow: none !important;
}

.recordSummary {
  font-size: 14px;
  color: $nextsix-orange;
  margin: 0 0 10px 0;
}

.recordSummary_text {
  color: #c3c3d7 !important;
}

.scrollToTop {
  font-size: 14px;
  color: $nextsix-orange;
  margin: 10px 0 0px 0;
  cursor: pointer;
}

.infoCenter_popup {
  width: 360px !important;
}

.infoCenter_popup_close {
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
}

.infoCenter_popup_content {
  padding: 16px 18px;
  // height: 68vh;
  background: #fff;
  border-radius: 8px;
  margin: 10px 0 0 0;
  position: relative;
}

.infoCenter_popup_content::after {
  content: "";
  position: absolute;
  top: -16px;
  left: 49%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}

.infoCenter_popup_nav {
  text-align: center;
  border: 1px solid #bcbec0;
  margin: auto;
  border-radius: 20px;
  // height: 35px;
}

.infoCenter_popup_navLink {
  background: transparent;
  color: #5d5d5d !important;
  font-size: 15px;
  padding: 4px !important;
  cursor: pointer;
  border-color: transparent !important;
  font-weight: 500;
}

.infoCenter_popup_navLink:hover {
  background: transparent;
  color: $dark-orange !important;
  font-size: 15px;
  padding: 4px !important;
  cursor: pointer;
  border-color: transparent !important;
}

.infoCenter_popup_navLink_property {
  background: $dark-orange;
  color: #fff;
  font-size: 15px;
  padding: 4px !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  border-color: transparent !important;
  cursor: pointer;
  font-weight: 500;
}

.infoCenter_popup_navLink_property:hover {
  color: #fff;
}

.infoCenter_popup_navLink_agent {
  background: $dark-orange;
  color: #fff;
  font-size: 15px;
  padding: 4px !important;
  border-top-right-radius: 16px !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 16px !important;
  border-color: transparent !important;
  cursor: pointer;
  margin-right: -1px;
}

.infoCenter_popup_navLink_agent:hover {
  color: #fff;
}

.infoCenter_popup_navItem {
  width: 50%;
  margin-top: -1px;
}

.infoCenter_popup_navItem_property {
  border-radius: 20px 0 0 20px;
  // border-right: 1px solid #BCBEC0;
}

.infoCenter_popup_navItem_agent {
  border-radius: 0 20px 20px 0;
}

.infoCenter_tabContent_agent {
  margin: 15px 0 0 0;
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

//info center agent
.agentCard2_wrapper {
  margin: 0 0 15px 0;
  padding: 0 10px;
}

.agentCard2 {
  background-color: #fff;
  display: flex;
}

.agentCard_firstBlock2 {
  position: relative;
  margin: 0 15px 0 0;
}

.agentCard_currentStatus2 {
  position: absolute;
  top: 50%;
  display: flex;
  transform: translate(-50%, -50%);
  left: 28%;
}

.agentCard_agentImg2 {
  border-radius: 7px;
  width: 75px;
}

.agentCard_status_online2 {
  background: $green-tag;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 14px;
  border: 1px solid #fff;
}

.agentCard_status_offline2 {
  background: #808184;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 14px;
  border: 1px solid #fff;
}

.agentCard_ranking2 {
  font-size: 15px;
  color: #fff;
  height: 25px;
  width: 65px;
  background: $green-tag;
  text-align: center;
  border-radius: 5px;
  margin: 0 5px 0 0;
}

.agentCard_rank2 {
  font-size: 12px;
  color: #fff;
  height: 20px;
  width: max-content;
  background: #f38400;
  text-align: center;
  border-radius: 5px;
  padding: 0 3px;
}

.agentCard_rankImg {
  margin: -1px 0 0 2px;
}

.agentCard_status_online2 {
  position: absolute;
}

.agentCard_secondBlock2 {
  width: 100%;
}

.agentCard_name2_row {
  display: flex;
  justify-content: space-between;
}

.agentCard_name2 {
  color: $lightblack-text;
  font-size: 15px;
  font-weight: 500;
  margin: 0 0 0 0;
}

.agentCard_activeListing2 {
  color: $grey-text;
  font-size: 12px;
  margin: 0;
}

.agentCard_asTag2 {
  display: flex;
  margin: 0 0 5px 0;
}

.agentCard_asTag_header2 {
  font-size: 10px;
  background-color: #1656ab;
  border-radius: 4px;
  margin: 0 10px 0 0;
  color: #fff;
  height: fit-content;
  padding: 0px 8px;
  font-style: italic;
}

.agentCard_asTag_text2 {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: 12px;
  color: $grey-text;
}

.agentCard_asTag_img2 {
  margin: -4px 8px 0 0;
}

.agentCard_tsTag2 {
  display: flex;
  white-space: nowrap;
  flex-wrap: wrap;
}

.agentCard_tsTag_header2 {
  font-size: 10px;
  background: $green-tag;
  border-radius: 4px;
  margin: 0 10px 0 0;
  color: #fff;
  height: fit-content;
  padding: 0 8px;
  font-style: italic;
}

.agentCard_tsTag_text2 {
  margin: 0;
  font-size: 12px;
  color: $grey-text;
}

.agentCard_tsTag_text2:last-child {
  overflow: hidden;
  text-overflow: ellipsis;
}

.agentCard_tsTag_img2 {
  margin: 0 8px 0 0;
}

.agentCard_about2 {
  word-break: break-all;
  color: $grey-text;
  font-size: 15px;
  margin: 5px 0 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  border-bottom: 1px solid #ededed;
  padding: 0 0 5px 0;
}

//info center property
.infoCenter_tabContent_property {
  margin: 10px 0 0 0;
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 10px -10px 0 0;
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 10px 0 0;
}

.infoCenter_propCard {
  border-radius: 0 0 7px 7px;
  margin: 0 0 20px 0;
  box-shadow: 0px 4px 11px #c4c8d480;
}

.propCard_top {
  position: relative;
}

.propCard_imgWrap {
  position: relative;
}

.propCard_imgWrap:after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0.6;
  border-radius: 7px 7px 0 0;
}

.propCard_img {
  width: 100%;
  height: 145px;
  object-fit: cover;
  border-radius: 7px 7px 0 0;
}

.propCard_topContent {
  position: absolute;
  top: 0;
  left: 0;
}

.propCard_topContent_innerWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  width: 319px;
  height: 145px;
  padding: 9px 12px;
}

.propCard_name {
  font-size: 17px;
  margin: 0;
}

.propCard_for {
  margin: 0;
  padding: 5px;
  font-size: 12px;
  background: #ed1c24;
  border-radius: 2px;
  height: 17px;
  width: 46px;
  text-align: center;
  text-transform: uppercase;
}

.propCard_rentType {
  margin-left: 10px;
  padding: 5px;
  font-size: 12px;
  background-color: #1e58a8;
  border-radius: 2px;
  height: 17px;
  text-align: center;
  text-transform: uppercase;
}

.propCard_price {
  margin: 5px 0 -5px 0;
  font-size: 17px;
}

.propCard_topContent_iconGroup {
  display: flex;
  justify-content: space-between;
}

.propCard_topContent_icon {
  margin: 0 5px 0 0;
}

.propCard_topContent_iconText {
  font-size: 11px;
  margin: 0 10px 0 0;
}

.propCard_topContent_createdDate {
  font-size: 11px;
}

.propCard_middle {
  background: #f5f6fa;
  border-radius: 7px;
  padding: 8px 12px;
  margin: 10px 10px 0 10px;
}

.propCard_description {
  word-break: break-all;
  color: #939598;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin: 0;
}

.propCard_avatar {
  width: 45px !important;
  height: 45px !important;
  margin: 0 13px 0 0;
}

.propCard_bottom {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 15px 10px;
}

.propCard_bottom_content {
  display: flex;
}

.propCard__agentInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.propCard__agentCompany {
  color: $black-filterText;
  font-size: 11px;
  margin: 0 0 -6px 0;
  font-weight: 500;
}

.propCard__agentName {
  color: $black-filterText;
  font-size: 15px;
  margin: 0 0 0 0;
  font-weight: 500;
}

.propCard_agentMobile_btn {
  cursor: pointer;
}

@media (min-width: $screen-desktop-min) {
  .agentCard_agentImg,
  .agentCard_agentImg_skeleton {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .agentCard {
    width: 100%;
    max-width: 555px;
    height: 180px;
  }
}

@media (min-width: $screen-laptop-min) and (max-width: $screen-laptop-max) {
  .agentCard_asTag_text {
    width: 106px;
  }

  .agentCard_ad {
    height: 105px;
    justify-content: center;
    display: flex;
    margin: 0 0 12px 0;
  }
}

@media (min-width: $screen-tablet-min) and (max-width: $screen-tablet-max) {
  .agentCard_asTag_text {
    width: 106px;
  }

  .agentCard_ad {
    height: 105px;
    justify-content: center;
    display: flex;
    margin: 0 0 12px 0;
  }
}

@media (min-width: $screen-tablet-min) and (max-width: $screen-laptop-max) {
  .agentCard_list {
    overflow: hidden !important;
  }

  .agentCard_firstBlock {
    width: 105px;
  }

  .agentCard {
    margin: 0 0 12px 0;
    height: 105px;
  }

  .agentCard_name {
    font-size: 16px;
    margin: 0;
  }

  .agentCard_agentImg,
  .agentCard_agentImg_skeleton {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .agentCard_online_status {
    font-size: 15px;
    color: #fff;
    height: 25px;
    width: 65px;
    background: $green-tag;
    text-align: center;
    border-radius: 5px;
    margin: 0 5px 0 0;
  }

  .agentCard_offline_status {
    font-size: 15px;
    color: #fff;
    height: 25px;
    width: 65px;
    background: #808184;
    text-align: center;
    border-radius: 5px;
    margin: 0 5px 0 0;
  }

  .agentCard_rankImg {
    width: 7px;
    height: 7px;
  }

  .agentCard_rank {
    font-size: 10px;
    height: 15px;
    width: max-content;
  }

  .agentCard_currentStatus {
    top: 50%;
    // bottom: -25%;
  }

  .agentCard_asTag_header {
    font-size: 14px;
    margin: 0 7px 0 0;
    height: fit-content;
    padding: 1px 8px;
  }

  .agentCard_asTag_text {
    font-size: 16px;
  }

  .agentCard_asTag_img {
    width: 8px;
  }

  .agentCard_tsTag_header {
    font-size: 14px;
    margin: 0 7px 0 0;
    height: fit-content;
    padding: 1px 8px;
  }

  .agentCard_tsTag_text {
    font-size: 14px;
  }

  .agentCard_tsTag_img {
    width: 8px;
  }

  .agentCard_secondBlock {
    padding: 6px 10px;
    width: 228px;
  }

  .agentCard_numberCompany {
    font-size: 14px;
  }

  .agentCard_propCount_data {
    font-size: 14px;
  }

  .agentCard_ad {
    height: 105px;
    justify-content: center;
    display: flex;
    margin: 0 0 12px 0;
  }

  .mapBlock {
    width: unset;
  }
}

@media (min-width: $screen-mobile-min) and (max-width: $screen-small-tablet-max) {
  .agentCard_list {
    overflow: hidden !important;
  }

  .searchBar_input {
    width: 86%;
  }

  .filtersBlock {
    flex-wrap: wrap;
  }

  .filter_dropdownToggle {
    margin: 8px 5px 0 0;
  }

  .resultsBlock {
    width: 100%;
  }

  .agentCard {
    margin: 0 0 12px 0;
    height: 130px;
  }

  .agentCard_firstBlock {
    width: 105px;
  }

  .agentCard_name {
    font-size: 17px;
  }

  .agentCard_agentImg,
  .agentCard_agentImg_skeleton {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .agentCard_online_status {
    font-size: 15px;
    color: #fff;
    height: 25px;
    width: 65px;
    background: $green-tag;
    text-align: center;
    border-radius: 5px;
    margin: 0 5px 0 0;
  }

  .agentCard_offline_status {
    font-size: 15px;
    color: #fff;
    height: 25px;
    width: 65px;
    background: #808184;
    text-align: center;
    border-radius: 5px;
    margin: 0 5px 0 0;
  }

  .agentCard_rankImg {
    width: 7px;
    height: 7px;
  }

  .agentCard_rank {
    font-size: 11px;
    height: 15px;
    width: max-content;
  }

  .agentCard_currentStatus {
    top: 50%;
    // bottom: -25%;
  }

  .agentCard_asTag_header {
    font-size: 14px;
    margin: 0 7px 0 0;
    height: fit-content;
    padding: 1px 8px;
  }

  .agentCard_asTag_text {
    font-size: 16px;
    width: 105px;
  }

  .agentCard_asTag_img {
    width: 8px;
  }

  .agentCard_tsTag_header {
    font-size: 14px;
    margin: 0 7px 0 0;
    height: fit-content;
    padding: 1px 8px;
  }

  .agentCard_tsTag_text {
    font-size: 14px;
  }

  .agentCard_tsTag_img {
    width: 8px;
  }

  .agentCard_secondBlock {
    padding: 6px 10px;
    width: 228px;
  }

  .agentCard_numberCompany {
    font-size: 14px;
  }

  .agentCard_propCount_data {
    font-size: 14px;
  }

  .agentCard_ad {
    height: 130px;
    justify-content: center;
    display: flex;
    margin: 0 0 12px 0;
  }

  .mapBlock {
    width: 100%;
    margin: 0;
  }
}
.resultsBlockPropertyList {
  overflow-x: hidden;
  height: 800px;
  overflow-y: auto;
  width: 100%;
  background-color: #f5f6fa;
}

@media (min-width: 1199px) {
  .resultsBlockPropertyList {
    height: 79vh !important;
    width: 98%;
    overflow-y: auto;
    background-color: #f5f6fa;
  }
}

@media (min-width: 1400px) {
  .resultsBlockPropertyList {
    height: 81vh !important;
    width: 98%;
    overflow-y: auto;
    background-color: #f5f6fa;
  }
}

@import "../_mixins";

.mortgage-label {
  font-size: 18px;
  color: #808285;
}
.mortgageCalculator-ketupat-span{
  vertical-align: top;
}
.mortgageCalculator-ketupat{
  height: 75px;
  width: 55px;
}

.mortgage-input {
  background: #F5F6FA 0% 0% no-repeat padding-box !important;
  border-radius: 7px !important;
  margin-bottom: 25px;
}

.down-payment-box {
  align-items: stretch;
  width: 100%;
  justify-content: space-between;
}

.down-payment-toggle-group {
  font-size: 16px;

  .toggle-button {
    color: #CACACA !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #939598 !important;
    border-radius: 7px !important;
    opacity: 0.5 !important;
    margin: 0 10px;
  }

  .toggle-button-active {
    color: #404041 !important;
    background: #EBF4FF 0% 0% no-repeat padding-box !important;
    border: 1px solid #C2D8FA !important;
    border-radius: 7px !important;
    opacity: 1 !important;
    margin: 0 10px;
  }
}

.total-amount-card {
  background: #FAFCFF 0% 0% no-repeat padding-box;
  border: 1px solid #C2D8FA;
  border-radius: 7px !important;
  padding: 20px;

  .total-amount-label {
    font-size: 14px;
    color: #808285;
  }

  .total-amount-text {
    color: #0549B7;
    padding-bottom: 15px;

    .total-amount-prefix {
      font-weight: 600;
      font-size: 24px;
      margin-right: 15px;
      vertical-align: top;
    }

    .total-amount-amount {
      font-weight: 500;
      font-size: 68px;
      line-height: 1;
    }
  }
}

.loan-amount-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .loan-amount {

    .loan-amount-label {
      font-size: 10px;
      color: #808285;
      margin: 0;
    }

    .loan-amount-text {
      font-size: 16px;
      color: #404041;
      margin: 0;
    }
  }

  .detailed-calculator {

    .detailed-calculator-btn {
      background: #0549B7 0% 0% no-repeat padding-box;
      border-radius: 7px;

      .detailed-calculator-btn-text {
        font-size: 14px;
        color: #FFFFFF;
      }
    }
  }
}

.disclaimer {
  color: #808080;
  font-size: 11.5px;
  margin: 10px;

  .disclaimer-title {
    margin-bottom: 5px;
  }

  .disclaimer-text {
    font-weight: 300;
  }
}
html {
    scroll-behavior: smooth;
}
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
   }
    to {
        transform: rotate(360deg);
   }
}
.App {
    text-align: center;
}
.error {
    color: #da1010 80;
    background-color: #f8f9fa;
    opacity: "0.7";
    border-radius: 10px;
}
.emptyspacing {
    height: calc(29pc - 2vh);
}
.App-logo {
    height: 40vmin;
    pointer-events: none;
}
.about-us-video-container {
    margin-bottom: 30px !important;
    position: relative;
    width: 100%;
    height: 90%;
    overflow: hidden;
    margin: auto;
    padding-top: 380px;
}
.about-us-video-mobile-container {
    margin-bottom: 30px !important;
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: auto;
    padding-top: 250px;
}
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 90%;
    border: none;
}
.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
.buyrentlabel {
    color: #fff;
    background: #f38400 !important;
    position: relative;
    padding: 5px 10px 5px 10px;
    font-size: 14px;
    line-height: 16px;
}
.buyrentlabel span {
    display: inline-block;
    width: 0;
    height: 0;
    position: absolute;
    right: -9px;
    top: 0;
    border: 0 solid transparent;
    border-bottom-width: 13px;
    border-top-width: 13px;
    border-left: 9px solid #f38400;
}
.buyrentlabel-slide {
    position: relative;
    top: 0;
    left: 0;
    width: 67px;
    text-align: center;
}
.statuslabel {
    color: #fff;
    background: #f38400 !important;
    position: relative;
    padding: 5px 10px 5px 10px;
    font-size: 14px;
    line-height: 16px;
}
.statuslabel span {
    display: inline-block;
    width: 0;
    height: 0;
    position: absolute;
    right: -9px;
    top: 0;
    border: 0 solid transparent;
    border-bottom-width: 13px;
    border-top-width: 13px;
    border-left: 9px solid #f38400;
}
.statuslabel-slide {
    position: relative;
    top: 0;
    left: 0;
    width: 100px;
    text-align: center;
}
.rentlabel {
    background: #60cd4c !important;
}
.rentlabel span {
    border-left: 9px solid #60cd4c;
}
.pricelabel h3 {
    color: #f38400;
    font-size: 22px;
}
.midnightBlue {
    background-color: #036 !important;
}
.dark-bg {
    background-color: #000 !important;
}
.wassapGreen {
    background-color: #92cd00 !important;
}
.lightgray-bg {
    background: #f1f1f1;
}
.boxcorner {
    border-radius: 20px !important;
}
.boxcornerimg {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}
.boxshadow {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75) !important;
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75) !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75) !important;
}
.boxshadow-soft {
    -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2) !important;
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2) !important;
}
.bg-next6 {
    background: #ff8d23 !important;
}
.breadcrumb.next6color {
    background-color: #f38400 !important;
    border-radius: 0rem !important;
}
.breadcrumb.next6color a {
    color: #fff;
}
.imgcardfixed {
    width: 100% !important;
    height: 18vw !important;
    object-fit: cover !important;
}
.imgprofilefixed {
    height: 17vh;
    width: 17vh;
    object-fit: cover;
    align-self: center !important;
}
.profile {
    padding: 0px 11px;
}
.card-img-overlay {
    padding: 1rem !important;
}
.btn-cancel {
    background-color: transparent !important;
    border: none !important;
    padding: 0 0 0 4 !important;
}
.btn-imgicon {
    cursor: default !important;
    background: rgba(0, 0, 0, 0.4) !important;
    color: #fff !important;
    padding: 1px 5px !important;
    font-size: 0.7rem !important;
}
.btn-imgicon svg {
    position: relative;
    font-size: 0.9rem !important;
}
.btn-loadmore {
    color: #212529 !important;
    background-color: #f8f9fa !important;
    -webkit-box-shadow: 0px 0px 6px 0px rgba(255, 89, 36, 0.6);
    -moz-box-shadow: 0px 0px 6px 0px rgba(255, 89, 36, 0.6);
    box-shadow: 0px 0px 6px 0px rgba(255, 89, 36, 0.6);
}
.btn-loadmore:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(253, 200, 54, 0.25) !important;
}
.bg-desc {
    background: #d1d1d1;
}
.profilename h4 {
    align-self: center;
    padding: 5px 30px;
}
.profilename small {
    align-self: center;
    font-size: 0.8rem !important;
}
.profilename .imgprofilefixed {
    height: 10vw;
    width: 10vw;
    object-fit: cover;
    align-self: center !important;
}
.iconfacilities svg {
    font-size: 2rem;
}
.bg-white {
    background: #fff;
}
.bg-black {
    background: #000;
}
.card-header {
    background: #fff !important;
}
.cardlike {
    float: right;
    z-index: 999999;
    text-align: right;
    padding: 0 1rem;
    position: absolute;
    right: 0px;
}
.navbarheader {
    font-size: calc(17px + 0.8vw);
}
.buttonright {
    float: center;
    text-align: center;
    right: 15px;
    float: right;
}
.liclass {
    display: "flex";
    justify-content: center;
    align-items: center;
}
.cardicons {
    padding: 1rem !important;
    position: absolute !important;
}
.btn-orange {
    background: #f09108 !important;
    color: #fff !important;
    border-color: #f09108 !important;
    height: 40px !important;
    width: 170px !important;
}
.btn-orange-submit {
    background: #f09108 !important;
    color: #fff !important;
    border-color: #f09108 !important;
    height: 40px !important;
    width: 100% !important;
}
.btn-orange-submit:hover {
    text-decoration: underline !important;
}
.logo {
    width: calc(4rem * 2);
    margin-left: 20px;
}
.btn-orange-getStarted {
    background: #f09108 !important;
    color: #fff !important;
    border-color: #f09108 !important;
}
.btn-login {
    background-color: #fff !important;
    color: #234652 !important;
    border-color: #fff !important;
    margin-right: 10px;
}
.tick-list {
    list-style-image: url("../../assets/agentApplication/icon\ check.svg");
    font-size: 16px !important;
}
.PackageTickList {
    list-style-image: url("../../assets/agentApplication/icon\ check.svg");
    margin: auto;
    display: table;
    text-align: left;
}
.property-ad {
    padding: 4% 0 3%;
}

.page-head{
    padding: 0 !important;
    margin-top: 7%;
}
.page-head-row{
    margin: auto !important;
    width: 100% !important;
}
.gif-col{
    padding: 0 !important;
}
.title {
    text-align: left !important;
    font-size: 46px;
    color: #234652 !important;
    line-height: 46px;
    font-weight: 500;
}
.paragraph {
    text-align: left !important;
    color: #234652 !important;
    font-size: 16px;
}
.gifStyling {
    max-height: 100% !important;
    max-width: 100% !important;
    height: auto !important;
    width: auto !important;
}
.containerbackground {
    background-color: #f7f9fd;
    width: 100%;
    overflow: hidden;
}
.containerbackground .rec-carousel-wrapper .rec-carousel-item {
    margin-right: 15px;
}
.containerbackground .rec-carousel-wrapper .rec-slider-container {
    overflow: initial;
}
.packageheader {
    text-align: center !important;
    font-size: 40px !important;
    color: #f09108 !important;
    margin-top: 50px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}
.packageheader p {
    font-weight: 500;
}
.packagelink {
    color: #00a82a !important;
    font-size: 34px !important;
}
.packagelink:hover {
    color: #00a82a !important;
}
.packageHeaderDescription {
    text-align: center !important;
    color: #234652 !important;
    font-size: 18px !important;
    margin-bottom: 20px !important;
}
.PackageBorder {
    border: 5px solid #00a82a;
    border-radius: 10px !important;
    min-width: 220px !important;
    width: 290px !important;
    text-align: center !important;
    margin: auto !important;
    background-color: #fff !important;
}
.PackageWithoutBorder {
    border-radius: 10px !important;
    min-width: 220px !important;
    width: 290px !important;
    text-align: center !important;
    margin: auto !important;
    background-color: #fff !important;
}
.PackageContainerStyleing {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    max-height: 100%;
    max-width: 1440px;
}
.ContainerStyleing {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
}
.ContainerStyleing .rec-carousel-wrapper .rec-slider-container {
    overflow: initial;
}
.PackagePlan {
    background-color: #fff !important;
    text-align: center !important;
    height: 320px !important;
    border-radius: 10px !important;
    min-width: 220px !important;
    max-width: 290px !important;
}
.tab {
    position: absolute !important;
    width: 220px !important;
    top: -51px !important;
    font-size: 18px !important;
    color: #fff;
    background: #f09108 !important;
    border: 2px solid #f09108 !important;
    transform: translate(-50%, 50%) !important;
    border-radius: 6px 6px 0 0 !important;
    padding: 3px 0 0 0;
}
.sign-up-page-ketupat{
  height: 90px;
  width: 45px;
  position: absolute;
  left: -20px;
  top:-27px
}
.PackagePlanList {
    background-color: #fff !important;
    height: 290px !important;
    min-width: 220px !important;
    max-width: 280px !important;
}
.PackageButtonDiv {
    background-color: #fff !important;
    text-align: center !important;
    height: 60px !important;
    border-radius: 10px !important;
}
.PackageButtonDiv .Start-Now-Button {
    background-color: #f09108 !important;
    color: #fff !important;
    width: 200px;
    border-color: transparent;
}
.PackageButtonDiv .Start-Now-Button:hover, .PackageButtonDiv .Start-Now-Button:focus, .PackageButtonDiv .Start-Now-Button:active {
    border-color: transparent !important;
    text-decoration: underline;
}
.Choose-Plan-Button {
    background-color: #fff !important;
    color: #f09108 !important;
    outline: #f09108 !important;
    width: 200px !important;
    border-color: #f09108 !important;
}
.Choose-Plan-Button:hover {
    background-color: #f09108 !important;
    color: #fff !important;
    outline: #f09108 !important;
    width: 200px !important;
    border-color: #f09108 !important;
}
.free-plan-logo-description {
    color: #00a82a !important;
    font-size: 20px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    font-weight: 500;
}
.essential-plan-logo-description {
    color: #989da9 !important;
    font-size: 20px !important;
    font-weight: 500;
    margin-top: 5px;
}
.pro-plan-logo-description {
    margin-top: 5px;
    color: #c2a94f !important;
    font-size: 20px !important;
    font-weight: 500;
}
.proplus-plan-logo-description {
    margin-top: 5px;
    color: #c28d4f !important;
    font-size: 20px !important;
    font-weight: 500;
}
.free-plan-title {
    color: #00a82a !important;
    font-size: 38px !important;
    height: 44px;
    font-weight: 600;
}
.Free-Plan-Description {
    font-size: 16px !important;
    color: #00a82a !important;
    font-weight: 500;
    font-style: italic;
}
.essential-plan-title {
    color: #989da9 !important;
    font-size: 38px !important;
    font-weight: 600;
}
.pro-plan-title {
    color: #c2a94f !important;
    font-size: 38px !important;
    font-weight: 600;
}
.proplus-plan-title {
    color: #c28d4f !important;
    font-size: 38px !important;
    font-weight: 600;
}
.Package-Period {
    color: #333 !important;
    font-size: 14px !important;
    font-weight: 500;
    font-style: italic;
}
.formtitle {
    margin-left: 30px !important;
    margin-bottom: 20px !important;
    color: #fff;
    font-size: 36px !important;
    font-weight: 500;
}
.formdescription {
    margin-left: 30px !important;
    margin-bottom: 20px !important;
    color: #fff;
    font-size: 19px !important;
}
.feature-in {
    padding: 3% 0 2%;
}
.feature-in .feature-text {
    text-align: center !important;
    color: #234652 !important;
    font-size: 34px !important;
    margin: 0 0 0 0 !important;
    font-weight: 500;
}
.featureCardItem {
    text-align: center !important;
    margin: auto;
    margin-bottom: 30px !important;
}
.featureContainer {
    position: relative;
    height: 45vh;
    width: 100%;
    display: flex;
    flex: 1;   
    justify-content: center;
    align-items: center;
}

.package-plan-wrapper {
    margin-top: 50px;
}

.package-plan-wrapper .rec-pagination .rec-dot_active {
    background-color: #f09108;
    box-shadow: 0 0 1px 3px #f09108;
}

// .rec.rec-arrow {
//     height: 0%;
//     width: 0%;
//     visibility: hidden;
// }

.featureContainerCard {
    min-height: 80px !important;
    width: 100% !important;
    text-align: center !important;
    background: #fff !important;
    color: #234652 !important;
    font-size: 14px !important;
    box-sizing: border-box !important;
}
.featureLogo {
    width: 200px;
    height: 100px;
}
.cardDescription {
    margin: 0 20px 0 20px !important;
    font-style: italic;
}
.errprompt {
    text-align: center;
    margin-bottom: 10px;
    margin-top: -10px;
}
.aboutus {
    text-align: center !important;
    color: #234652 !important;
    margin-bottom: 30px !important;
    margin-top: 50px !important;
    font-size: 42px !important;
    font-weight: 500;
}
.aboutus2 {
    text-align: center !important;
    color: #234652 !important;
    margin-bottom: 30px !important;
    margin-top: 50px !important;
    font-size: 36px !important;
}
.aboutusDescription {
    text-align: center !important;
    color: #234652 !important;
    font-size: 19px !important;
    margin-bottom: 60px !important;
}
.aboutusDescription2 {
    text-align: left !important;
    font-size: 19px !important;
    color: #234652 !important;
    margin-left: 20px !important;
    margin-bottom: 60px !important;
}
.aboutusDescriptionHasttag {
    color: #234652 !important;
    font-weight: 500;
}
.aboutusVideoDescription {
    text-align: left !important;
    margin-left: 20px !important;
    color: #234652 !important;
    font-size: 18px !important;
}
.aboutusVideoDescription2 {
    text-align: left !important;
    margin-left: 20px !important;
    margin-bottom: 20px !important;
    color: #f09108;
    font-size: 24px !important;
    margin-top: 20px;
    font-weight: 500;
}
.socialmedia {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
    margin-right: 15px !important;
    margin-left: 5px !important;
}
.CenterSocialmedia {
    text-align: center;
}
.footer {
    font-size: 12px;
    text-align: center !important;
    color: #fff !important;
    background-color: #06203a !important;
    padding: 10px;
}
.checkLogoWithCircle {
    text-align: center !important;
    margin-bottom: 50px !important;
    margin-top: 20px !important;
}
.checkTitle {
    margin-bottom: 50px !important;
    text-align: center !important;
}
.checkParagraph {
    margin-left: 50px !important;
    margin-right: 50px !important;
    margin-bottom: 50px !important;
    text-align: center !important;
}

.checkButton {
    margin:auto !important
}

.form {
    padding: 5% 0 1%;
    width: 100% !important;
}
.formBackground {
    background-color: #f09108;
}
.formBackground2 {
    background-color: #fff !important;
    margin-top: 30px !important;
    border-radius: 6px !important;
    width: 100% !important;
    margin-bottom: 30px !important;
    margin-right: 30px !important;
    padding: 40px 20px;
}
.house {
    opacity: 0.6 !important;
    margin-left: -170px !important;
}
.btn-orange2 {
    text-align: center;
    border-color: #ff9800;
    color: orange;
    border: 5px solid #6b6966;
    display: block;
    margin: 15px;
    width: 75px;
    opacity: 0.5;
}
.btn-orange2:hover {
    background: #f38400 !important;
    color: #fff !important;
    border-color: #f38400 !important;
}
.btn-orange2:focus {
    background: #f38400 !important;
    color: #fff !important;
    border-color: #f38400 !important;
}
.btn-orange2:active {
    background: #f38400 !important;
    color: #fff !important;
    border-color: #f38400 !important;
}
.btn-orange2select {
    background: #f38400 !important;
    color: #fff !important;
    border-color: #f38400 !important;
    width: 75px !important;
}
.btn-area {
    padding: 10px 0 0 0 !important;
    width: auto !important;
    display: flex !important;
    flex-wrap: wrap !important;
}
.btn-area .btn-orange2 {
    padding: 5px 20px 0px 20px !important;
    margin: 5px 4px 0 0 !important;
    width: auto !important;
}
.btn-area .btn-orange2select {
    padding: 5px 20px 0px 20px !important;
    margin: 5px 4px 0 0 !important;
    width: auto !important;
}
.orangetabs {
    border-bottom: 0px !important;
}
.orangetabs li {
    padding: 0 5px 0px 0px;
}
.orangetabs li a.active {
    background: #f38400 !important;
    color: #fff !important;
    border-color: #f38400 !important;
}
.orangetabs li a {
    background: #555 !important;
    color: #fff !important;
    padding: 10px 30px;
}
.blackButton {
    background-color: black;
    color: white;
}
.whiteButton {
    background-color: white;
    color: black;
}
.btn-app .wassapGreen {
    background-color: #92cd00 !important;
    border: 1px solid #92cd00 !important;
}
.btn-app .wassapGreen:hover {
    background-color: #92cd00 !important;
    border: 1px solid #92cd00 !important;
    box-shadow: 0 0 0 0.2rem rgba(134, 250, 2, 0.5);
    color: #fff !important;
}
.btn-app .wassapGreen:focus {
    color: #fff;
    background-color: #b8d66c;
    border-color: #b8d66c;
    box-shadow: 0 0 0 0.2rem rgba(134, 250, 2, 0.5);
}
.btn-app .contactemail {
    background-color: #01579b !important;
    border: 1px solid #0277bd !important;
}
.btn-app .contactemail:hover {
    background-color: #0277bd !important;
    border: 1px solid #01579b !important;
    box-shadow: 0 0 0 0.2rem rgba(1, 88, 155, 0.425);
    color: #fff !important;
}
.btn-app .contactemail:focus {
    color: #fff;
    background-color: #0288d1;
    border-color: #0277bd;
    box-shadow: 0 0 0 0.2rem rgba(1, 88, 155, 0.425);
}
.btn-app .contactphone {
    background-color: #f38400 !important;
    border: 1px solid #e65100 !important;
}
.btn-app .contactphone:hover {
    color: #fff !important;
    background-color: #e65100 !important;
    border-color: #f38400 !important;
    box-shadow: 0 0 0 0.2rem rgba(230, 80, 0, 0.418);
}
.btn-app .contactphone:focus {
    color: #fff;
    background-color: #e65100 !important;
    border-color: #f38400 !important;
    box-shadow: 0 0 0 0.2rem rgba(230, 80, 0, 0.418);
}
.btn-app button {
    width: 100%;
}
.btn-app svg {
    font-size: 50px;
}
.btn-app.small svg {
    font-size: 25px;
}
.btn-app.tiny svg {
    font-size: 1em;
}
.wdth100 {
    width: 100% !important;
}
.greytext {
    color: #7a7a7a;
}
.lightgreytext {
    color: #a3a3a3;
}
.fontbig {
    font-size: 2rem !important;
}
.fontmd {
    font-size: 1.5rem !important;
}
.fontsm {
    font-size: 1.2rem !important;
}
.fontstd {
    font-size: 1em !important;
}
.fontxs {
    font-size: 0.8em !important;
}
.fontnobold {
    font-weight: 400 !important;
}
.card.noborder {
    border: none !important;
}
.App-link {
    color: #61dafb;
}
.contactbox {
    padding: 30px;
    margin-top: 3rem;
}
.carouselimg {
    height: 50vh;
}
.bg-footer {
    background: #06203a;
}
.bg-footer h4 {
    color: #fff;
    font-size: 0.8rem !important;
}
.bg-footer ul {
    padding-inline-start: 0px;
    margin-top: 15px;
}
.bg-footer li {
    color: #fff;
    font-size: 0.7rem !important;
    list-style: none;
    margin-bottom: 10px;
}
.bg-footer li a {
    color: #fff;
}
.bg-footer li a:hover {
    color: #f09108;
    text-decoration: none;
}
.bg-footer li a.orangetext {
    color: #f09108;
}
.bg-footer li a.orangetext:hover {
    color: #fff;
    text-decoration: none;
}
.bg-search {
    padding: 30px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
.bg-orange {
    background: #faa541;
    background: linear-gradient(90deg, rgba(250, 165, 65, 1) 0%, rgba(245, 121, 60, 1) 100%);
    padding: 10px;
}
.bg-orange li a {
    color: #fff;
}
.bg-orange li a:hover {
    color: #06203a;
}
.bg-copyright {
    background: #f09108;
}
.searchbar {
    border-top-left-radius: 0 !important;
}
.brandDiv {
    border-right: 2px solid #fff;
    padding: 0px 20px 0px 0px;
}
.topauto {
    top: auto !important;
}
.bottomauto {
    bottom: auto !important;
}
.orangelist ul {
    list-style: none;
    margin: 0;
    padding-inline-start: 0px;
}
.orangelist li {
    display: inline-block;
    margin: 8px;
}
.next6agent {
    text-align: right !important;
}
.paddtopLg {
    padding-top: 133px !important;
}
.bg-transparent {
    background-color: transparent !important;
    position: absolute !important;
    width: 100% !important;
    margin-bottom: 100px !important;
}
.jumbotron.bannerhead {
    background-image: url(/src/assets/img/landing-page-banner.jpg);
    border-radius: 0px !important;
    min-height: 600px;
    margin-bottom: 0;
}
.searchrespon input {
    font-family: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", FontAwesome;
}
.advsearch {
    color: #fff 80 !important;
    text-align: center !important;
    border: 0px solid !important;
    font-size: 1rem !important;
}
button.advsearch:focus {
    outline: none !important;
    color: #f38400 !important;
}
.advancesearch .dropdown-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
    -webkit-transition: height 0.3s ease-in-out;
    -o-transition: height 0.3s ease-in-out;
    -moz-transition: height 0.3s ease-in-out;
    transition: height 0.3s ease-in-out;
}
.advancesearch ul {
    margin: 0 !important;
    -webkit-transition: height 0.3s ease-in-out;
    -o-transition: height 0.3s ease-in-out;
    -moz-transition: height 0.3s ease-in-out;
    transition: height 0.3s ease-in-out;
}
.advancesearch a {
    padding: 10px !important;
    color: #fff;
    font-size: 0.8rem !important;
    cursor: pointer;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}
.advancesearch a:hover {
    color: #f38400 !important;
}
.dropdownlink a {
    color: #f38400 !important;
}
.propertytitle {
    padding: 0 !important;
}
.propertytitle a.nav-link {
    padding: 0 !important;
}
.imagecardslider .slick-prev {
    top: 22% !important;
}
.imagecardslider .slick-next {
    top: 22% !important;
}
.captionslides p {
    color: #8a8a8a !important;
    font-size: 16px !important;
    margin: 9px 0 !important;
}
.captionslides p.pricing {
    color: #f38400 !important;
    font-size: 1.4rem !important;
    font-weight: 600;
}
.iconcard {
    list-style: none;
    padding-inline-start: 0 !important;
    display: inline-flex;
}
.iconcard li {
    margin-left: 15px;
    color: #8a8a8a;
}
.iconcard li:first-child {
    margin-left: 0;
}
.imageslider {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
}
.imageslider p {
    font-size: 15px;
    margin: 0;
    color: #fff;
}
.imageslider h3 {
    font-size: 1.2rem;
    margin: 0;
    color: #fff;
}
.imgoverlay {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem 1.5rem;
}
.imgoverlaytop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem 1.5rem;
}
.cardslides {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    padding: 2px;
}
.cardslides a {
    color: #f38400;
}
.cardslides a:hover {
    text-decoration: underline;
    color: #f38400;
}
.slick-prev {
    width: 50px !important;
    height: 45px !important;
    z-index: 99;
    left: -50px !important;
    top: 34% !important;
}
.slick-prev:before {
    font-size: 50px !important;
    line-height: 1;
    opacity: 0.75;
    color: #000 !important;
    content: url(/src/assets/img/button-back.png) !important;
}
.slick-next {
    width: 50px !important;
    height: 45px !important;
    z-index: 99;
    right: -1px !important;
    top: 34% !important;
}
.slick-next:before {
    font-size: 50px !important;
    line-height: 1;
    opacity: 0.75;
    color: #000 !important;
    content: url(/src/assets/img/button-next.png) !important;
    width: 100%;
    position: absolute;
    right: 0px;
    top: 1px;
}
.slick-slide .image {
    padding: 2px;
    height: 18vw;
    overflow: hidden;
}
.buyUnder .nav-link {
    padding: 0 !important;
    font-size: 1.5rem;
}
.statemg {
    margin-left: 10px !important;
}
.statemg a.statename {
    color: #f38400;
    font-size: 1.5rem;
    cursor: pointer;
}
.statemg a.statename:hover {
    text-decoration: underline;
}
.bg-services {
    background: #fcf9f7;
}
.paddBottom {
    padding-bottom: 15px;
}
.paddTop {
    padding-top: 19px;
}
.imgmargin {
    border-radius: 10px;
}
.img-gridfluid {
    position: absolute;
    width: 100%;
    bottom: 0;
}
.play-button {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 60px;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
}
.play-button:hover {
    background-color: rgba(0, 0, 0, 0.9);
}
.play-button:after {
    content: "";
    display: block;
    position: absolute;
    top: 16.5px;
    left: 40px;
    margin: 0 auto;
    border-style: solid;
    border-width: 12.5px 0 12.5px 20px;
    border-color: transparent transparent transparent rgba(255, 255, 255, 1);
}
.close-video::before {
    content: "✖";
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    padding: 20px;
    z-index: 1;
    line-height: 0.7;
    display: block;
    color: #fff;
}
.video-wrapper {
    position: relative;
    padding: 33.35% 0;
    height: 0;
}
.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.app .image-gallery {
    margin: 0 auto;
    width: 100%;
    transition: all 1s ease;
}
.app-sandbox {
    margin: 0 auto;
    width: 100%;
    transition: all 1s ease;
    margin: 40px auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.app-buttons li {
    display: block;
}
.app-interval-input-group {
    display: table;
}
.input-group-section {
    display: flex;
    flex-direction: row;
}
.app-interval-label {
    display: table-cell;
    vertical-align: middle;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 3px solid #ccc;
    border-right: none;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.app-interval-input {
    -webkit-appearance: none;
    display: table-cell;
    margin: 0;
    padding: 9px;
    border-radius: 5px;
    font-size: 14px;
    border: 3px solid #ccc;
    background: #fff;
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
input.app-interval-input {
    width: 65px;
}
.app-checkboxes {
    margin-top: 10px;
}
.app-checkboxes li {
    display: block;
}
.image-gallery-bullets .image-gallery-bullet:focus {
    background: #f38400;
    transform: scale(1.1);
}
.image-gallery-bullets .image-gallery-bullet:hover {
    background: #f38400;
    transform: scale(1.1);
}
.loading {
    background: #fff !important;
    z-index: 999999 !important;
}
.nav-tabs .nav-item.show .nav-link {
    color: #fff !important;
    background-color: #f38400 !important;
    border-color: transparent !important;
}

// .nav-tabs .nav-link.active {
//     color: #fff !important;
//     background-color: #f38400 !important;
//     border-color: transparent !important;
// }

// .nav-link.active:hover {
//     background-color: #555 !important;
// }

// .nav-link {
//     color: #f38400 !important;
// }
// .nav-link:hover {
//     color: #555 !important;
// }

.contactlinks {
    color: #fff !important;
}
.current-views {
    text-align: right !important;
}
.textvar {
    text-align: left !important;
}
.sidebarStyle {
    display: inline-block;
    margin: 12px;
    background-color: #404040;
    color: #fff;
    z-index: 1 !important;
    padding: 6px;
    font-weight: bold;
}
.mapContainer {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 45vw;
}
.mapboxgl-map {
    border-radius: 11px;
    border: 1px solid #d5d5d5;
}
.mapdiv {
    position: relative;
    width: 100%;
}
.mapboxgl-popup-content {
    position: relative;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 10px 10px 15px;
    pointer-events: auto;
    width: 230px;
}
.mapboxgl-popup-content h3 {
    font-size: 1.2rem;
}
.mapboxgl-popup-content p {
    font-size: 0.7rem;
    line-height: 1.4;
    margin: 0;
}
.react-rater-star.is-active {
    color: #f7b229 !important;
}
.overlayicon {
    left: 0;
    margin: 0px auto;
    position: relative;
    right: 0;
    z-index: 4;
    text-align: center;
    background: #000;
    padding: 10px;
}
.slideicons {
    margin: 0;
    padding: 0px;
    text-align: center;
}
.slide-imgicon {
    cursor: default !important;
    background: rgba(0, 0, 0, 0.4) !important;
    color: #fff !important;
    padding: 1px 5px !important;
    justify-self: center;
    margin: 5px;
}
.slide-imgicon span {
    font-size: 1rem;
    margin-left: 10px;
}
.slide-imgicon svg {
    position: relative;
    font-size: 1.5rem;
}
.image-gallery-slide {
    height: 30vw;
}
.view360 iframe {
    width: 100%;
    height: 700px;
}
.appstoreimg-width {
    width: calc(90px + 7vw) !important;
}
.googleplayimg-width {
    width: calc(90px + 7vw) !important;
}
.auctionWrapper {
    position: relative;
}
.auctionImage {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto !important;
    margin-top: -80px;
    margin-left: -175px;
}
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
   }
}
@media (max-width: 1320px) {
    .app-sandbox-content {
        padding: 0 20px;
   }
}
@media (max-width: 768px) {
    .app-header {
        font-size: 20px;
   }
    .app-buttons li {
        display: block;
        margin: 10px 0;
   }
    .app-buttons li + li {
        padding: 0;
   }
    .play-button {
        height: 40px;
        width: 65px;
   }
    .play-button:after {
        top: 11px;
        left: 27px;
        border-width: 8.5px 0 8.5px 12px;
   }
    .close-video::before {
        font-size: 16px;
        padding: 15px;
   }
}

@media (max-width: 1024px) {
    .app .image-gallery {
        width: 100%;
   }
    .app-sandbox {
        width: 100%;
   }

   .page-head{
    margin-top: 13%;
}
}
@media (min-width: 768px) {
    .image-gallery-icon:hover {
        color: #f38400 !important;
   }
}
@media (min-width: 200px) and (max-width: 575px) {
    .imgmargin {
        margin: 10px 0 !important;
   }
    .paddBottom {
        padding-bottom: 0;
   }
    .paddTop {
        padding-top: 0;
   }
    .img-gridfluid {
        position: relative !important;
        width: auto !important;
        bottom: 0;
   }
    .buyUnder .nav-link {
        font-size: 1.3rem;
   }
    .statemg a.statename {
        font-size: 1.3rem;
   }
    .calltoaction {
        padding: 20px;
   }
    .calltoaction h3 {
        text-align: center;
   }
    .calltoaction h5 {
        text-align: center;
   }
    .current-views {
        text-align: center !important;
   }
    .textvar {
        text-align: center !important;
   }
    ul.navicenter {
        justify-content: center;
   }

   .page-head{
    margin-top: 30%;
}
}
@media (min-width: 1024px) {
    .imagecardslider .slick-prev {
        top: 19% !important;
   }
    .imagecardslider .slick-next {
        top: 19% !important;
   }
}
@media (min-width: 200px) and (max-width: 980px) {
    .brandDiv {
        border-right: 0px solid #fff;
        padding: 0px 20px 0px 0px;
   }
    .slick-prev {
        width: 65px !important;
        top: 35% !important;
        left: -40px !important;
   }
    .slick-next {
        width: 65px !important;
        top: 35% !important;
   }
}
@media (min-width: 200px) and (max-width: 767px) {
    .contactbox {
        padding: 20px 0px !important;
        border-top: 1px solid #ddd;
   }
    .orangelist li {
        text-align: center !important;
        display: list-item;
   }
    .orangelist ul {
        text-align: center !important;
   }
    .responsivebtn {
        width: 100% !important;
        display: inline-grid !important;
        border-radius: 0 !important;
        margin: 0 !important;
   }
    .searchrespon input {
        border-radius: 0 !important;
        font-size: 0.8rem !important;
        font-family: apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", FontAwesome;
   }
    .searchrespon .btn {
        border-radius: 0 !important;
   }
    .jumbotron {
        padding: 2rem 0rem !important;
   }
    .bg-search {
        padding: 10px;
   }
    .orangetabs {
        border-bottom: 0px !important;
        white-space: nowrap !important;
   }
    .orangetabs li {
        padding: 0 2px 0px 0px;
   }
    .orangetabs li a {
        padding: 10px 10px;
        font-size: 10px;
        white-space: pre-wrap !important;
   }
    .brandDiv {
        text-align: center;
   }
    .statemg {
        margin-left: 0 !important;
   }
    .mapContainer {
        height: 50vh;
   }
    .image-gallery-slide {
        height: 60vw;
   }
    .view360 iframe {
        width: 100%;
        height: 200px;
   }
}
@media (min-width: 200px) and (max-width: 820.98px) {
    .imgcardfixed {
        height: 50vw !important;
   }
    .profilename .imgprofilefixed {
        height: 18vh;
        width: 18vh;
        align-self: center;
   }
    .imgprofilefixed {
        height: 18vh;
        width: 18vh;
   }
    .carouselimg {
        height: 40vh;
   }
    .bg-orange li a {
        text-align: center !important;
   }
    .brandDiv {
        margin: auto;
   }
    .brandDiv img {
        width: 150px;
        margin: auto;
   }
    .next6agent {
        text-align: center !important;
   }
    .imagecardslider .slick-prev {
        top: 23% !important;
   }
    .imagecardslider .slick-next {
        top: 23% !important;
   }
    .image-gallery-slide {
        height: 60vw;
   }
}

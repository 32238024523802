.sitemap-container {
  margin-top: 40px;
  margin-bottom: 20px;
}

.sitemap-inner-container {
  margin-top: 30px;
}

.sitemap-section {
  margin-bottom: 30px;
}

.sitemap-big-title {
  height: 40px;
  font-size: 32px;
  border-bottom: 1px solid #c6c6c6;
}

.sitemap-big-title2 {
  height: 40px;
  font-size: 28px;
  border-bottom: 1px solid #c6c6c6;
}

.sitemap-col {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 10px 0 0 !important;
}

.sitemap-col-combine {
  display: flex;
  flex-direction: column;
  padding: 0 15px 0 15px;
}

.sitemap-row-combined {
  margin-bottom: 40px;
}

.sitemap-col-combined {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sitemap-h6 {
  font-size: 14px;
  color: black;
}

.sitemap-li {
  list-style: none;
  font-size: 12px;
  font-weight: 400;
}

.sitemap-li-a {
  color: black;
  text-decoration: none;
}

@media (max-width: 767px) {
  .sitemap-inner-container {
    margin-top: 10px;
  }

  .sitemap-h6 {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .second-div {
    max-width: 540px;
  }
}

@media (min-width: 427px) and (max-width: 765px) {
  .second-div {
    max-width: 150px;
  }
  .inside-div {
    max-height: 230px;
    column-gap: 180px;
  }
}

@media (max-width: 426px) {
  .second-div {
    max-width: 100%;
  }
  .inside-div {
    max-height: fit-content;
    display: contents;
  }
}

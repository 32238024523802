.state-gallery-container {
  padding: 30px 0;
}

.state-gallery {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px 0;
  gap: 20px;
  justify-content: space-between;
}

.state-gallery-item {
}

.state-container {
  display: flex;
  width: 250px;
  height: 230px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}

.state-container-text {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 3px;
}

.state-container img {
  width: 100%;
  height: 100%;
  transition: 0.6s all ease-in-out;
  object-fit: cover;
}

.state-container:hover img {
  transform: scale(1.1);
}

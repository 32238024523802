@import "../_mixins";

.property-detail-bg-color {
  background-color: #f5f6fa;
}
.description-border {
  border-radius: 10px;
}
.Card-PropertyDetail-Info-Styling {
  padding: 20px 20px 20px 20px;
  border-radius: 15px !important;
  .property-row-iconsGroup2-wrapper {
    display: flex;
    gap: 2px;
    @media (max-width: 300px) {
      flex-direction: column;
    }
    .property-row-saleTag2 {
      display: flex;
      width: fit-content;
      border-radius: 5px;
      padding: 2px 5px;
      font-size: 14px;
      gap: 3px;
      &.bestDeal {
        color: white;
        background-color: #f09108;
      }
      &.rent-type {
        color: white;
      }
    }
  }
}
.Card-OtherDetail-Info-Styling {
  border-radius: 10px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  .other-searches-body {
    padding: 20px 30px;
    a {
      color: $nextsix-orange;
    }
  }
}
.image-gallery-slide {
  height: fit-content !important;
  .center {
    height: fit-content !important;
  }
}
.property-detail-h1 {
  font-size: 28px;
}
.property-detail-h2 {
  font-size: 20px;
}
.property-detail-info-h3 {
  font-size: 16px;
}
.property-other-searches-h3 {
  font-size: 14px;
}
.property-detail-table-padding {
  padding: 15px 10px;
}

.property-detail-table-padding > .table-container__table {
  border-radius: 10px;
  overflow: hidden;
  width: 85%;
  margin: auto;
}

@media (max-width: $screen-tablet-max) {
  .table-tab-bar-div {
    height: fit-content !important;
  }
}

@media (max-width: $screen-small-tablet-max) {
  .table-tab-bar-div {
    margin: 10px 15px 15px 15px !important;
    height: 32px !important;
  }

  .sales-trend-gain-result,
  .sales-trend-gain-result2 {
    padding: 0 40px 0 30px !important;
    margin: 33px 0 0 0 !important;
  }

  .sales-trend-gain-text {
    padding: 15px 0 0 0;
  }

  .sales-trend-gain-text,
  .sales-trend-gain-text2 {
    margin: 0 !important;
  }

  .property-detail-table-padding > .table-container__table {
    width: 100% !important;
  }
}

.property-detail-table-disclaimer {
  font-size: 12px;
  color: #9b9b9b;
  padding: 15px 10px;
  margin: 0px;
}
.property-detail-table-disclaimer-paragraph {
  font-size: 11px;
  color: #9b9b9b;
  padding: 0px 10px;
  margin: 0px 0px 10px 0px;
}
.property-detail-category-div {
  top: 87px;
  position: sticky;
  z-index: 5;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #ffffff;
  border-radius: 7px;
}
.property-detail-hr-line-under-button {
  margin-top: 1px;
}
.property-detail-category-button {
  padding: 10px;
  // cursor: pointer;
  opacity: 0.7 !important;
  background-color: #ffffff !important;
  color: #7a7a7a !important;
  border: 0px transparent !important;
  border-color: transparent !important;
  outline: 0 !important;
  border-bottom: 2px solid transparent !important;
  width: inherit;
  // height: 20px;
  justify-content: center;
  vertical-align: middle;
  font-size: 15px;
}

.property-detail-category-button-focus {
  border: 0px transparent;
  border-color: transparent;
  outline: 0px !important;
  border-bottom: 5px solid #f09108;
  opacity: 1;
  color: #f09108;
  background-color: #ffffff;
  padding: 10px;
  width: inherit;
  // height: 20px;
  justify-content: center;
  vertical-align: middle;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: 15px;
}

.property-detail-section {
  top: -100px;
  position: relative;
}

// .property-detail-category-button:focus{
//     border: 0px transparent;
//     border-color: transparent;
//     outline: 0px !important;
//     border-bottom: 5px solid #f09108;
//     opacity: 1;
//     color: #f09108;
//     background-color: #ffffff;
//     padding: 10px;
//     width: inherit;
//     // height: 20px;
//     justify-content: center;
//     vertical-align: middle;
//     border-bottom-left-radius: 3px;
//     border-bottom-right-radius: 3px;
// }
// .property-detail-category-button-focus{
//     border: 0px transparent;
//     border-color: transparent;
//     outline: 0px !important;
//     border-bottom: 5px solid #f09108;
//     opacity: 1;
//     color: #f09108;
//     background-color: #ffffff;
//     padding: 10px;
//     width: inherit;
//     // height: 20px;
//     justify-content: center;
//     vertical-align: middle;
//     border-bottom-left-radius: 3px;
//     border-bottom-right-radius: 3px;
// }

//   const Tab = styled.button`
//   padding: 10px 30px;
//   cursor: pointer;
//   opacity: 0.6;
//   background: white;
//   border: 0;
//   outline: 0;
//   border-bottom: 2px solid transparent;
//   border: 1px solid grey;
//   transition: ease border-bottom 250ms;
//   width: inherit;
//   height: 130px;
//   justify-content: center;
//   vertical-alignment: center;
//   ${({ active }) =>
//       active &&
//       `
//     border-bottom: 8px solid #f09108;
//     box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
//     opacity: 1;
//   `}
// `;
.table-tab-bar-div {
  border: 0.5px solid #f09108;
  border-radius: 7px;
  display: flex;
  margin: 25px 55px 0 55px;
  justify-content: center;
  height: 32px;
}

.table-tab-button {
  width: 100%;
  border: none;
  background-color: transparent;
  border-radius: 7px;
}

.table-tab-button.active {
  background-color: #f9e0c0 !important;
  color: #f09108 !important;
}

.sale-trend-container {
  padding: 15px 0 10px 0;
}

.sale-trend-average-price {
  color: #00a82a;
}

.sales-trend-gain-text {
  color: #808285;
  font-size: 16px;
  margin: 35px 0 0 15px;
}

.sales-trend-gain-text2 {
  color: #808285;
  font-size: 16px;
  margin: 20px 0 0 15px;
}

.sales-trend-gain-result {
  margin: 35px 0 0 55px;
  border-left: 0.5px solid #939598;
  padding: 0 40px 0 40px;
}

.sales-trend-gain-title {
  color: #808285;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0;
}

.sales-trend-gain-percent-text {
  color: #808285;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0;
}

.sales-trend-gain-percent, .sales-trend-gain-percent2 {
  font-size: 40px;
  font-weight: 500;
  margin: 0;
}

.sales-trend-gain-row2 {
  margin: 0 0 15px 0;
}

.sales-trend-gain-result2 {
  margin: 20px 0 0 55px;
  border-left: 0.5px solid #939598;
  padding: 0 40px 0 40px;
}

.data-not-avaiable {
  margin: auto;
  height: 100px;
  padding: 40px 0px;
  text-align: center;
  justify-content: center;
  display: block;
}
.data-not-avaiable-title {
  background-color: lightgray;
  height: auto;
  padding: 10px 20px;
  color: gray;
  min-width: 250px;
  max-width: 350px;
  margin: auto;
  // text-align: center;
  // justify-content: center;
}
.property-detail-download-row {
  margin-bottom: 20px;
}

.property-detail-download-item-row {
  width: 100%;
  background-color: lightgray;
  color: black;
  padding: 20px 20px 10px 20px;
  margin: 0 0 0 0px !important;
  border-bottom: 1px solid gray;
  justify-content: space-between;
}
.property-detail-storey-plan-slider {
  margin: 10px 0 10px 0;
}
.property-detail-storey-plan-slider-h5 {
  margin: 0 0 10px 10px;
}

.back-to-search-div {
  padding: 20px 0 20px 0;
  .back-url-div {
    margin: 0px;
    display: flex;
    // justify-content: space-between;
    width: auto;
  }
  .back-to-search-span {
    cursor: pointer;
    background: transparent !important;
    color: $nextsix-orange !important;
    min-width: 100px !important;
  }
  .url-path {
    color: #afafbe;
    padding: 2px 0 0 10px;
    font-size: 14px;
    max-width: fit-content !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}
@media (max-width: $screen-tablet-min) {
  .url-path {
    display: none;
  }
  .back-to-search-div {
    padding: 10px 0px;
    .back-url-div {
      margin: 0px;
      display: flex;
      flex-direction: column;
      width: auto;
    }
    .back-to-search-span {
      span {
        display: flex;
        gap: 4px;
        font-size: 18px;
        .img-back-logo {
          width: 14px;
          padding: 0px;
        }
      }
      
    }
    .breadcrumb {
      font-size: 12px;
      .breadcrumb-item {
        padding-left: 2px;
        &::before {
          padding-right: 2px;
        }
      }
    }
    .url-path {
      color: #afafbe;
      padding: 2px 0 0 10px;
      font-size: 12px;
    }
  }
}

.img-back-logo {
  height: 25px;
  width: 30px;
  padding: 0 10px 2px 0;
}

.project-enquiry-form-div {
  margin: 10px;
}

.project-enquiry-form-tnc {
  font-size: x-small;
  margin-bottom: 10px;
  color: grey;
}

.project-enquiry-errprompt-contact-us {
  color: red;
  font-size: 18px;
  padding: 0 0 3px 0;
  .errprompt-spacing {
    height: 0px;
  }
}

.project-enquiry-contact-us-recaptcha {
  padding: 0 0 17px 0;
}

.project-enquiry-checkTitle {
  margin-bottom: 50px !important;
  text-align: center !important;
  color: rgb(39, 136, 39);
}

.project-enquiry-checkParagraph {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center !important;
  color: grey;
}

.developer-detail {
  text-align: center;
  vertical-align: middle;
  .developer-logo {
    height: 60px;
    width: 100px;
    margin-bottom: 10px;
  }
  .developer-name {
    font-weight: 500;
    margin-bottom: 10px;
  }
}

.developerCard-Button {
  text-align: center;
  vertical-align: middle;
  min-width: 200px;
  .developer-call-button {
    text-align: center;
    vertical-align: middle;
    background-color: #fb9600;
    border-radius: 8px;
    color: #ffffff;
    border: none;
    min-width: 200px;
  }
  .developer-call-button:hover,
  :focus,
  :active,
  :visited {
    background-color: #fb9600 !important;
    color: #ffffff !important;
    border: none !important;
  }
  .developer-call-button-whatsapp {
    text-align: center;
    vertical-align: middle;
    background-color: #2ea134;
    border-radius: 8px;
    color: #ffffff;
    border: none;
    min-width: 200px;
    margin-top: 10px;
  }
  .developer-call-button-whatsapp:hover,
  :focus,
  :active,
  :visited {
    background-color: #2ea134 !important;
    color: #ffffff !important;
    border: none !important;
  }
}

.AgentCard-Detail-Info {
  padding: 20px 0 20px 0;
  border-radius: 7px !important;
  border: 0.5px solid #939598 !important;
  opacity: 1;
  .AgentCard-Button {
    padding: 0 20px 0 20px !important;
  }
}
.Property-Icon-Info-Padding {
  padding: 5px 0 10px 0;
}
.Property-Detail-Map-Warp {
  height: 260px;
  width: auto;
  border-radius: 15px !important; //
  overflow: hidden !important; //
}
.Property-Detail-Map-Warp-map-preview {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}

.corousel-card-styling {
  padding: 10px 0 10px 0;
  margin: 0 10px 0 10px;
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
}

.other-listing-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.other-listing-styling {
  padding: 30px 0 0 30px;
  font-size: 18px;
}
.other-listing-hr-line {
  height: 0.5px;
  border-width: 0;
  color: #808285;
  background-color: #808285;
}

.property-detail-otherlist-carousel {
  // .rec.rec-arrow {
  //     height: 0% !important;
  //     width: 0% !important;
  //     visibility: visible !important;
  // }

  // .sc-bdnxRM.rec.rec-arrow.rec.rec-arrow-right{
  //     background-image: url(/src/assets/img/courouselRight.svg) !important;
  //     width: 40px !important;
  //     height: 50px !important;
  //     color: $nextsix-orange !important;
  //     background: #ffffff !important;
  //     border: transparent 0px!important;
  //     outline: none !important;
  // }
  // .sc-bdnxRM.rec.rec-arrow.rec.rec-arrow-right{
  //     background-image: url(/src/assets/img/courouselRight.svg) !important;
  //     width: 40px !important;
  //     height: 50px !important;
  //     color: $nextsix-orange !important;
  //     background: #ffffff !important;
  //     border: transparent 0px!important;
  //     outline: none !important;

  // }
  // .sc-bdnxRM.rec.rec-arrow.rec.rec-arrow-left{

  //     background-image: url(/src/assets/img/courouselLeft.svg) !important;
  //     width: 40px !important;
  //     height: 50px !important;
  //     color: $nextsix-orange !important;
  //     background: #ffffff !important;
  //     border: transparent 0px!important;
  //     outline: none !important;

  // }
  // .sc-bdnxRM.rec.rec-arrow.rec.rec-arrow-left{
  //     background-image: url(/src/assets/img/courouselLeft.svg) !important;
  //     width: 40px !important;
  //     height: 50px !important;
  //     color: $nextsix-orange !important;
  //     background: #ffffff !important;
  //     border: transparent 0px!important;
  //     outline: none !important;

  // }
  position: relative;
  // margin:30px 0 50px 0 !important;

  .rec-carousel {
    .rec-arrow-left {
      position: absolute;
      left: -12px;
      z-index: 999;
      box-shadow: none !important;
      background-color: transparent !important;
      color: #3f3b44 !important;
      border: transparent !important;
      outline: none !important;

      &:hover,
      :focus {
        color: $nextsix-orange !important;
      }
    }

    .rec-arrow-right {
      position: absolute;
      right: -12px;
      z-index: 999;
      box-shadow: none !important;
      background-color: transparent !important;
      color: #3f3b44 !important;
      border: transparent !important;
      outline: none !important;

      &:hover,
      :focus {
        color: $nextsix-orange !important;
      }
    }
  }
  .rec.rec-pagination {
    width: auto !important;
  }
}

.auctionImage {
  margin-left: -155px !important;
}

.property-detail-address {
  font-size: 18px;
  color: #a7a9ac;
  text-align: left;
  opacity: 1;
  padding: 0 0 15px 0;
}
.property-detail-publishdate {
  font-size: 12px;
  text-align: left;
  letter-spacing: 0px;
  color: #a7a9ac;
  opacity: 1;
  margin: 0 0 10px 15px;
  width: 150px;
  margin-bottom: 0;
  max-width: 85%;
}
.property-detail-publishdate-v2 {
  margin: 0;
  width: auto;
}
.property-detai-project-margin {
  margin-bottom: 10px;
}
.property-detail-auctiondate {
  font-size: 18px;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  background-color: $nextsix-orange;
  border-radius: 7px;
  border: 0px;
  opacity: 1;
  margin-bottom: 5px !important;
}
.property-detail-pricelabel {
  font-size: 26px;
  text-align: left;
  letter-spacing: 0px;
  color: #f38400;
  opacity: 1;
  width: 90%;
}
.property-detail-pricelabel p{
  font-size: 28px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
// .propert-detail-name{
//     font-size: 22px;
//     text-align: left;
//     letter-spacing: 0px;
//     color: #414042;
//     opacity: 1;
// }
.property-detail-logo-icon-description {
  font-size: 12px;
  color: #a7a9ac;
}
.property-auctionlist-card-body {
  min-height: 275px !important;
  max-height: fit-content !important;
}
.other-auction-list-price {
  color: #fb9600 !important;
}
// .otherlisting-agentinfo-companyname{
//     font-size: 12px;
//     color: #808285;
//     text-align: left;
//     vertical-align: top;
//     display: inline;
// }
// .otherlisting-agentinfo-agentname{
//     font-size:16px ;
//     color: #414042;
//     display: inline;
//     // float: left;
//     // vertical-align: top;
//     padding-left: -100px;
// }
.property-detail-slider-image-container {
  .image-gallery-content.right {
    max-height: 400px !important;
    min-height: fit-content;
    .image-gallery-slide-wrapper.right {
      max-height: 400px !important;
      min-height: fit-content;
    }
    .image-gallery-swipe {
      max-height: 400px !important;
      min-height: fit-content;
    }
    .image-gallery-content.left .image-gallery-slide .image-gallery-image,
    .image-gallery-content.right .image-gallery-slide .image-gallery-image {
      max-height: 400px !important;
      min-height: fit-content;
    }
    .image-gallery-slides {
      max-height: 400px !important;
      min-height: fit-content;
    }
    .image-gallery-slide.center {
      max-height: 400px !important;
      min-height: fit-content;
    }
  }
  .image-gallery-image {
    max-height: 400px !important;
    max-width: auto !important;
  }
}
.property-detail-overlay-icon-bar {
  .overlayicon {
    color: #ffffff !important;
    background: #ffffff !important;
  }
}

.agent-info-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.agent-info-detail {
  max-width: fit-content !important;
  text-align: center !important;
  margin: auto !important;
  .agent-info-detail-rating {
    background: #f7c312;
    border-radius: 4px;
    font-size: 14px;
    padding: 1px 5.5px;
    margin-right: 4px;
    color: #ffffff;
  }
  .agent-info-detail-noRating {
    color: #a7a8ab;
    font-style: italic;
  }
  .agent-info-detail-ratingImg {
    margin: 0 0 4px 4px;
  }
}
.agent-info-detail-mobile-renid {
  padding: 10px 0 10px 0;
  color: #a7a8ab;

  .agent-info-detail-vertical-line {
    border-left: 1px solid #a7a8ab;
    padding: 0px 3px 0 2px;
    margin: 0 0 0 5px;
  }
}

.contact-agent-button {
  min-width: 177px !important;
  max-width: fit-content !important;
  height: 36px !important;
  background-color: #ffc107 !important;
  color: #000000 !important;
  margin: 15px 0 10px 0 !important;
  border-radius: 7px !important;
  border: transparent !important;
}
.view-agent-button {
  min-width: 177px !important;
  max-width: fit-content !important;
  border-radius: 7px !important;
  border-color: none 0px !important;
}

/* The actual timeline (the vertical ruler) */
.div-timeline {
  height: 350px;
  overflow-y: auto;
  border-radius: 15px;
  margin-bottom: 15px;
}

@media screen and (min-width: $screen-laptop-max) {
  .timeline {
    display: none;
  }
  .desktop-timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 15px;
    height: fit-content;
  }
}
@media screen and (max-width: 1199px) {
  .desktop-timeline {
    display: none;
  }
}
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  background-color: lightgray;
  border-radius: 15px;
  height: fit-content;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline-container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
  border-radius: 15px;
}

/* The circles on the timeline */
.timeline-container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: white;
  border: 4px solid #ff9f55;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
  border-radius: 15px;
}

/* Place the container to the left */
.timeline-container-left {
  left: 0;
}

/* Place the container to the right */
.timeline-container-right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.timeline-container-left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.timeline-container-right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.timeline-container-right::after {
  left: -16px;
}

/* The actual content */
.timeline-content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
}

.timeline-content-info {
  font-size: 15px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: $screen-mobile-max) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline-container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
    border-radius: 15px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline-container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
    border-radius: 15px;
  }

  /* Make sure all circles are at the same spot */
  .timeline-container-left::after,
  .timeline-container-right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .timeline-container-right {
    left: 0%;
  }
}

.iconfacilities-padding {
  padding: 0 0 0 15px !important;
  .p {
    font-size: 12px !important;
  }
}

.eye-padding {
  padding: 10px 30px 0 0;
}
// .propertyDetail-list-Maplabel-infowindow {
//   background-color: #00a82a;
//   padding: 5px 10px 5px 10px;
//   border-radius: 7px;
//   margin-bottom: 35px;
// }
.propertyDetail-list-Maplabel-infowindow {
  background-color: #00a82a;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px 5px 10px;
  border-radius: 7px;
  margin-bottom: 0px;
  // min-width: 100px;
  height: 28px;
  position: relative;
}
.propertyDetail-list-Maplabel-infowindow:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: #00a82a transparent transparent transparent;
}

.fslightbox-container {
  top: 90px !important;
}
.fslightbox-absoluted {
  top: -23px !important;
}
.property-detail-video-wrapper {
  position: inherit;
  padding: 33.35% 0;
  height: 0;
}
.property-detail-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.property-detail-view360 iframe {
  width: 100%;
  height: 400px;
}
@media (min-width: 200px) and (max-width: 767px) {
  .property-detail-view360 iframe {
    width: 100%;
    height: 200px;
  }
}

.property-detail-floorplan {
  .image-gallery-swipe {
    width: 100% !important;
    padding-bottom: 100% !important;

    .image-gallery-slides {
      position: absolute !important;
      width: 100% !important;
      height: 100% !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
  }

  .image-gallery-icon {
    display: flex !important;

    svg {
      width: 25px !important;
      height: 50px !important;
    }
  }

  .image-gallery-bullets {
    bottom: 6px !important;

    .image-gallery-bullet {
      padding: 2.8px !important;
      box-shadow: 0 1px 3px #1a1a1a90;
    }
    .image-gallery-bullet.active {
      transform: scale(1.3);
      border: 1px solid #fff;
      background: #fff;
    }
  }

  
}

.table-bordered td, .table-bordered th {
  align-content: center;
}

.page-link {
  color: #f09108 !important;
}

.page-item.active .page-link {
  background-color: #f09108 !important;
  border-color: #f09108 !important;
  color: white !important;
}

.pdp-share-btn {
  position: absolute;
  right: 0;
}

.pdp-share-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 15px;
}
@import "./_variables.scss";

// custom container start
@mixin custom-container(){
    @media (min-width: $screen-desktop-min) {
    min-width: 1250px !important;   
    }
}

@mixin custom-container2(){
    @media (min-width: $screen-desktop-min) {
    max-width: 2400px !important;   
    }
}
// custom container end

// custom carousel start
@mixin custom-carousel-wrapper {
    position: relative;
}

@mixin custom-carousel-left-arrow {
    position: absolute;
    left:-12px;
    z-index: 999;
    box-shadow: none !important;
    background-color: transparent !important;
    color:#3F3B44 !important;
    border: transparent !important;
    outline: none !important;

    &:hover, :focus {
        color:$nextsix-orange !important;
    }
}

@mixin custom-carousel-right-arrow {
    position: absolute;
    right: -12px;
    z-index: 999;
    box-shadow: none !important;
    background-color: transparent !important;
    color:#3F3B44 !important;
    border: transparent !important;
    outline: none !important;

    &:hover, :focus {
        color:$nextsix-orange !important;
    }
}

@mixin custom-carousel-pagination-dot {
    background: #BCBEC0;
    box-shadow: none !important;

    &:hover {
        box-shadow: transparent;
    }
}

@mixin custom-carousel-pagination-dot-active {
    background: $nextsix-orange;
    box-shadow: none !important;

    &:hover {
        box-shadow: transparent;
    }
}
// custom carousel end

// custom infinite scrollbar start
// @mixin custom-infinite-scrollbar {
//     &::-webkit-scrollbar {
//     width: 5px;
//     }

//     &::-webkit-scrollbar-track {
//         background-color: rgba(0,0,0,0.1);
//     }

//     &::-webkit-scrollbar-thumb {
//         background: #A7A8AB; 
//         border-radius: 10px;
//     }

//     &::-webkit-scrollbar-thumb:hover{
//         background: #F09108; 
//     }
// }
// custom infinite scrollbar end
.agentSignUp-main {
  background-image: url("../../assets/agentApplication/sign-up-bg-image.jpg");
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;
  height: 100vh;
  display: grid;
}

.agentSignUp-section-one {
  display: flex;
  margin: auto;
  max-width: 1080px;
  width: 100%;
}

.agentSignUp-content {
  width: 50%;
  text-align: left;
}

.agentSignUp-logo {
  margin: 40px 0 20px;
}

.agentSignUp-headline {
  font-size: 54px;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 1.3px;
  margin-bottom: 7px;
  line-height: 1;
  width: 433px;
}

.agentSignUp-textline {
  font-size: 20px;
  color: #ffffff;
  width: 426px;
  margin-top: 25px;
}

.agentSignUp-form {
  background: rgba(252, 252, 252, 0.56);
  box-shadow: 0px 0.96px 5px #adadad33;
  border-radius: 8px;
  width: 535px;
  height: 570px;
  padding: 60px 50px 80px;
}

.agentSignUp-form-group {
  display: flex;

  .agentSignUp-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: solid 1px #828282;
  }

  .agentSignUp-select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    max-width: 30%;
  }
}

.agentSignUp-input {
  height: 55px;
  padding: 15px;
  border: 0;
  width: 100%;
  margin-bottom: 18px;
  border-radius: 7px;

  &::placeholder {
    font-size: 18px;
    color: #828282;
  }

  &:focus-visible {
    outline: none;
  }
}

.agentSignUp-select {
  height: 54px;
  padding: 10px;
  border: 0;
  width: 100%;
  margin-bottom: 18px;
  border-radius: 7px;
  font-size: 18px;

  &:focus-visible {
    outline: none;
  }
}

.agentSignUp-submit {
  height: 54px;
  border: 0;
  width: 100%;
  border-radius: 7px;
  font-size: 18px;
  color: #fff;
  background: #f09108;
}

.agentSignUp-errorPrompt {
  color: red;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 1540px) {
  .agentSignUp-headline {
    font-size: 50px;
    width: 76%;
  }

  .agentSignUp-textline {
    width: 80%;
    font-size: 18px;
  }

  .agentSignUp-form {
    width: 500px;
    height: 490px;
    padding: 40px 30px;
  }

  .agentSignUp-input,
  .agentSignUp-select,
  .agentSignUp-submit {
    height: 48px;
  }
}

@media (max-width: 1024px) {
  .agentSignUp-section-one {
    max-width: 80%;
  }

  .agentSignUp-headline {
    font-size: 40px;
    width: 90%;
  }

  .agentSignUp-textline {
    width: 82%;
    font-size: 16px;
  }

  .agentSignUp-form {
    width: 420px;
    height: 390px;
    padding: 30px 25px;
  }

  .agentSignUp-input,
  .agentSignUp-select,
  .agentSignUp-submit {
    font-size: 14px;
    height: 38px;
  }

  .agentSignUp-input::placeholder {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .agentSignUp-section-one {
    max-width: 83%;
  }

  .agentSignUp-logo {
    margin: 20px 0 20px;
  }

  .agentSignUp-headline {
    font-size: 36px;
    width: 90%;
  }

  .agentSignUp-textline {
    width: 82%;
    font-size: 14px;
  }

  .agentSignUp-form {
    width: 420px;
    height: 390px;
    padding: 30px 25px;
  }

  .agentSignUp-input,
  .agentSignUp-select,
  .agentSignUp-submit {
    font-size: 14px;
    height: 38px;
  }

  .agentSignUp-input::placeholder {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .agentSignUp-content {
    width: 100%;
    text-align: center;
  }

  .agentSignUp-section-one {
    flex-direction: column;
  }

  .agentSignUp-logo {
    margin: -80px 0 50px;
  }

  .agentSignUp-headline {
    width: 100%;
    font-size: 24px;
  }

  .agentSignUp-textline {
    width: 295px;
    font-size: 16px;
    margin: 30px auto 40px;
  }

  .agentSignUp-form {
    width: 100%;
    height: 390px;
    padding: 30px 25px;
  }

  .agentSignUp-input,
  .agentSignUp-select,
  .agentSignUp-submit {
    font-size: 14px;
    height: 38px;
  }

  .agentSignUp-input::placeholder {
    font-size: 14px;
  }
}

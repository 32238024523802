@import "../_mixins";

.header2 {
    height: 90px; 
    background-color: #F5F6FA;
}

.header-thankyou {
    background-color: #FFFFFF !important;
}

.header-content2 {
    @include custom-container;
}

.header2.sticky2 {
    background-color: #F5F6FA; 
    box-shadow: 0 0 3px 0 rgba(0, 0,0, 0.5);
}

.header-right2{
    margin-left: auto;
}


.header-site-navigation-menu {
    margin-left: 50px;
}

.header-site-navigation-menu .header-site-navigation-menu-btn {
    color:#333333;
    font-size:16px;
    background-color: transparent;
    border-color: transparent;
    padding: 0;
    margin: 0 30px 0 0;
    }
    .header-site-navigation-menu .header-site-navigation-menu-btn:hover, .header-site-navigation-menu .header-site-navigation-menu-btn:active, .header-site-navigation-menu .header-site-navigation-menu-btn:focus {
        background-color: transparent !important;
        border-color:transparent !important;
        color:#333333 !important;
        text-decoration: none;
        cursor: pointer;
    }

@media(min-width:$screen-laptop-min){
    .language-dropdown-padding-mobile{
        display: none;
    }
    .header-right2 .navbar-collapse{
        display: none;
    }

    .header-right2 .btn-sign-in2 {
        color:#333333;
        font-size:16px;
        background-color: transparent;
        border-color: transparent;
        padding: 0;
        margin: 0 30px 0 0;
        }
        
        .header-right2 .btn-sign-in2:hover,.header-right2 .btn-sign-in2:active, .header-right2 .btn-sign-in2:focus {
            background-color: transparent !important;
            border-color:transparent !important;
            color:#333333 !important;
        }
        
        .header-right2 .btn-get-started2 {
            background-color: #F09108;
            border-radius: 7px;
            padding: 5px 26px;
            border-color:transparent !important;
        }
        
        .header-right2 .btn-get-started2:hover, .header-right2 .btn-get-started2:active, .header-right2 .btn-get-started2:focus {
            background-color: #F09108 !important;
            border-color:transparent !important;
        }

        .header-right2 .navbar-toggler{
            // border:transparent;
           display: none;
        
        }

}



@media(max-width:$screen-tablet-max){
    .header-right2{
        .btn-desktop-view{
            display: none;
        }
    }

    .header-site-navigation-menu-btn{
        display: none;
    }

    // .btn-language-mobile-view{
    //    display: flex;
    // }
    
    // .header-right2 .navbar-toggler{
    //     // border:transparent;
    //     position: relative;
    
    // }
    .header-right2{
        .navbar-toggler{
            position: absolute !important;
            z-index: 999 !important;
            right: 10px;
            top:30px;
            .headerMenuLogo{
                position: relative !important;
                z-index: 999 !important;
            }
            
        }
        button:focus{
            border:none;
            outline: none;
        }
    }
    .header-right2 .navbar-collapse{
        position: absolute;
        padding: 50px 0 30px 0;
        min-width: 200px;
        min-height: 150px;
        max-height: fit-content;
        background: #3B4144 0% 0% no-repeat padding-box;
        float:left;
        right: 10px;
        top:30px;
        border-radius: 10px;
        
        .btn-sign-in2{
            color:#FFFFFF;
            margin:0px;
            background-color: transparent !important;
            border-color:transparent !important;
        }
        .btn-get-started2{
            color:#FFFFFF;
            margin:0px;
            background-color: transparent !important;
            border-color:transparent !important;
            padding: 0px;
        }
        .btn-sign-in2:hover,.header-right2 .btn-sign-in2:active, .header-right2 .btn-sign-in2:focus {
            background-color: transparent !important;
            border-color:transparent !important;
            color:#FFFFFF !important;
        }
        .navbar-nav{
            padding: 0 10px 0 20px;
            text-align: right;
            float: right;
        }
    }
}

.language-dropdown-padding{
    padding-left: 10px; 
    .language-dropdown-filter {
        height: 37px;
        //margin: 8px 5px 0 0px;
        // padding-top: 10px;
        color: #58595B !important;
        border-radius: 7px !important;
        border-color: #939598 !important;
        .language-dropdown-toggle {
            color: #58595B !important;
            // margin: 0 5px 0 0 !important;
            font-size: 15.7px;
            padding: 5px 15px 0 15px;
            border-color: #939598 !important;
            z-index: 1;
            border-radius: 7px;
            

            &:hover {
                color: #58595B !important;
                //border-color:$nextsix-orange;
                //background: #f8d7a9;
            }

            &:focus {
                box-shadow: none !important;
            }

            &:after {
                content: url(../../assets/img/dropdowngrey.svg) !important;
                border: 2px !important;
                border-color: $nextsix-orange;
                vertical-align: middle;
                margin-bottom: 4px;
            }
        }

        .language-dropdown-menu {
            width: max-content;
            max-height: 350px;
        }

        .language-dropdown-menu-scroll {
            max-height: 350px;
            overflow-y: scroll;
        }

        .language-dropdown-option-selected{
            color: $nextsix-orange;
        }
        .language-dropdown-ketupat{
            height: 95px;
            width: 45px;
            position: absolute;
            top:0px;
            right:10px
        }
    }
}

.language-dropdown-padding-mobile{
    padding-left: 10px; 
    .language-dropdown-filter-mobile {
        height: 37px;
        //margin: 8px 5px 0 0px;
        // padding-top: 10px;
        color: #ffffff !important;
        border-radius: 7px !important;
        border-color: #939598 !important;
        .language-dropdown-toggle-mobile {
            color: #ffffff !important;
            // margin: 0 5px 0 0 !important;
            font-size: 15.7px;
            padding: 5px 15px 0 15px;
            border-color: #939598 !important;
            z-index: 1;
            border-radius: 7px;
            

            &:hover {
                color: #ffffff !important;
                //border-color:$nextsix-orange;
                //background: #f8d7a9;
            }

            &:focus {
                box-shadow: none !important;
            }

            &:after {
                content: url(../../assets/img/dropdowngrey.svg) !important;
                border: 2px !important;
                border-color: $nextsix-orange;
                vertical-align: middle;
                margin-bottom: 4px;
            }
        }

        .language-dropdown-menu-mobile {
            width: max-content;
            max-height: 350px;
        }

        .language-dropdown-menu-scroll-mobile {
            max-height: 350px;
            overflow-y: scroll;
        }

        .language-dropdown-option-selected-mobile{
            color: $nextsix-orange;
        }
        .language-dropdown-ketupat-mobile{
            height: 95px;
            width: 45px;
            position: absolute;
            top:0px;
            right:10px
        }
    }
}


.language-list-mobile{
    margin: 0 10px 0 0;
  .language-list-label-mobile{
    font-size: 14px;
    color: #FFFFFF;
  }
  .language-list-select-mobile{
    border:transparent;
    color:#FFFFFF;
    background: transparent;
    text-overflow: ellipsis !important;
    max-width: 140px !important;
  }
  .language-list-option-mobile{
    color: rgb(108, 117, 125)!important;
  }
  .language-list-img-mobile {
    color: #FFFFFF;
  }
}


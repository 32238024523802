@import "../_variables.scss";

$grey-input-field: #F5F6FA;
$border-colour: #EBEBEB;
$desc-text: #919191;

@media (max-width: 520px) {
    .msu-sec2 {
        padding: 30px 50px !important;
    }
}

@media (min-width: 520px) and (max-width: $screen-small-tablet-max) {
    .msu-sec2 {
        padding: 40px 100px !important;
    }
}

@media (max-width: $screen-small-tablet-max) {
    .msu-page-wrapper {
        flex-direction: column;
    }

    .msu-sec1 {
        width: 100%;
        height: max-content;
        padding: 20px 40px;
        // flex-direction: row;
        align-items: center;

        // justify-content: space-between;
        // &-wrapper {
        // padding-left: 40px;
        p {
            margin: 0;
            text-align: center;
        }

        // visibility: hidden;
        // position: absolute;
        // }
    }

    .msu-logo {
        // top:0;
        // left: 0;
        // padding: 20px;
        padding-bottom: 10px !important;
    }
}

@media (max-width: 850px) {
    .msu-input-wrapper1,
    .msu-input-wrapper2 {
        grid-template-columns: 1fr !important;
    }
}

@media (min-width: 850px) {
    .msu-input-long {
        grid-column: 1/3;
    }
}

//css for size tablet
@media (min-width: $screen-small-tablet-max) and (max-width: $screen-tablet-max) {
    .msu-sec1 {
        width: 30% !important;
        min-width: min-content;

        &-text {
            // min-width: 340px;
            width: 100%
        }
    }

    .msu-sec2 {
        width: 70% !important;
    }
}

//css for size tablet, laptop and desktop
@media (min-width: $screen-small-tablet-max) {
    .msu-page-wrapper {
        flex-direction: row;
    }

    .msu-sec1 {
        // flex-direction: column;
        width: 40%;
        height: 100vh;
        top: 0;
        position: sticky;
        padding: 172px 90px 0 72px;

        &-text {
            // min-width: 340px;
            width: 90%
        }
    }

    .msu-sec2 {
        width: 60%;
    }
}

.msu-page-wrapper {
    display: flex;
    // flex-direction: row;
    // height: 100vh;
}

.msu-sec1 {
    // width: 40%;
    // height: 100vh;
    // top: 0;
    // position: sticky;
    // padding: 172px 90px 0 72px;
    display: flex;
    flex-direction: column;
    background: no-repeat url('../../assets/merchantSignUp/msu-background.jpg');
    background-size: cover;
    color: white;

    &-title {
        font-size: 28px;
        font-weight: bold;
    }

    // &-text {
    //     // min-width: 340px;
    //     width: 90%
    // }
}

.msu-logo {
    // background: url('../../assets/merchantSignUp/msu-logo-merchant.svg') no-repeat;
    // height: 40px;
    width: 147px;
    padding-bottom: 95px;
}

.msu-sec2 {
    // width: 60%;
    padding: 68px 121px 68px 98px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    background-color: white;

    &-title {
        font-size: 32px;
        font-weight: bold;
        color: $nextsix-orange;
        margin-bottom: 40px;
        align-self: center;
    }

    &-title2 {
        font-size: 20px;
        font-weight: bold;
        padding-top: 26px;
    }

    &-desc {
        font-size: 15px;
        color: $desc-text;
        margin-bottom: 46px;
    }
}

.msu-input-wrapper1,
.msu-input-wrapper2 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 16px;
}

.msu-input-wrapper1 {
    border-bottom: 1px solid $border-colour;
    padding-bottom: 30px;
}

.msu-input-wrapper2 {
    padding-top: 50px;
    padding-bottom: 65px;
}

.msu-input {
    display: flex;
    flex-direction: column;

    &-label {
        font-size: 15px;
    }

    &-field {
        height: 48px;
        width: 100%;
        background-color: $grey-input-field;
        border: 0;
        border-radius: 6px;
        padding: 10px;

        &:focus {
            outline: none;
        }
    }

    &-upload {
        height: 48px;
        cursor: pointer;
        width: 100%;
        background: url('../../assets/merchantSignUp/msu-upload-file.svg') no-repeat;
        background-size: contain;
    }

}

.msu-input-upload-wrapper {
    max-width: 272px;
}

// .msu-input-long {
//     grid-column: 1/3;
// }

.msu-bc-container {
    width: 100%;
    padding-top: 20px;
    // display: grid;
    // justify-content: left;
    // grid-template-columns: repeat(auto-fit, 210px);
    // gap: 10px;
}

// .msu-img-wrapper {
//     // width: 100%;
//     // height: 100%;
//     // width: 210px;
//     // height: 297px;
//     max-width: 210px;
//     max-height: 297px;
//     position: relative;
//     border: 1px solid $border-colour;
//     border-radius: 6px;
//     overflow: hidden;

//     img {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//     }

//     .msu-img-dlt {
//         position: absolute;
//         top: 3%;
//         right: 4%;
//         width: 25px;
//         height: 25px;
//         background: url('../../assets/merchantSignUp/msu-img-dlt.svg') no-repeat;
//         background-size: contain;
//         cursor: pointer;
//     }
// }

.msu-alert {
    .alert {
        margin-top: 20px;
        margin-bottom: 0;
    }
}

.msu-errorPrompt {
    color: red;
    margin-bottom: 10px;
}

.msu-btn-submit {
    max-width: 272px;
    height: 48px;
    color: white;
    background: $nextsix-orange;
    border-radius: 7px;
    border: none;
}
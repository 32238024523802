@import "../_mixins";

.contact-us-empty {
    margin: 90px auto 0;
    // border-top: 1px solid rgba(0,0,0,.1);
}

.contact-us-container{
    @include custom-container;
    background: #F5F6FA;
    margin: 30px auto 30px ;
    justify-content: center !important;
}

.contact-us-card{
    background-color: #ffffff;
    padding: 25px;
    border-radius: 15px;
}

.contact-us-title{
    text-align: left;
    letter-spacing: 0px;
    color: #0D0D0D;
    opacity: 1;
    font-size: 34px;
    font-weight: bold;
    
}

.contact-us-description{
    font-size: 15px;
    text-align: left;
    letter-spacing: 0px;
    color: #808285;
    opacity: 1;
    padding: 10px 0 40px 0 !important;
}

.contact-us-form-info{
    min-width: fit-content;
    .contact-us-form-text{
        width: 100%;
        .col-form-label{
          display: none;
        }
        .contact-us-form-text-detail{
            padding: 0 0 0 0px;
            color: #808285 !important;
        }
        .contact-us-term-condition{
            font-size: 10px;
            text-align: left;
            letter-spacing: 0px;
            color: #A7A9AC;
            opacity: 1;
            padding: 10px 0 0 0;
        }
    }
}

.errprompt-contact-us{
    color: red;
    font-size: 20px;
    padding: 0 0 5px 0;
    .errprompt-spacing{
        height: 0px;
    }
}

.contact-us-recaptcha{
    padding: 0 0 27px 0;
}

.contact-us-submit-button{
    text-align: center;
    min-width: 100% !important;
    background: $nextsix-orange 0% 0% no-repeat padding-box !important;
    border-radius: 7px !important;
    opacity: 1 !important;
    color: #ffffff !important;
    border-color: $nextsix-orange !important;
    .btn.btn-secondary{
        min-width: fit-content !important;
    }
}

.contact-us-map-card{
    border-radius: 15px;
    border:0.5px solid #939598 !important;
    opacity: 1;
    width: 100%;
}

@media (max-width: 768px){
    .contact-us-map-card{
        border-radius: 15px;
        border:0.5px solid #939598 !important;
        opacity: 1;
        width: 100%;
        margin-top: 40px;
    }
}

.contact-us-contact-card{
    border-radius: 15px;
    border:0.5px solid #939598 !important;
    opacity: 1;
    width: 100%;
    margin-top: 30px;
    .contact-us-contact-info{
        padding: 30px 20px 30px 20px;
        font-size: 24;
        font-weight: bold;
        text-align: left;
        letter-spacing: 0px;
        color: #0D0D0D;
        opacity: 1;
    }
    .contact-us-social-media{
        float: right;
    }
    .logo-media{
        margin: 0 5px 0 5px;
    }
}

.contact-us-description-card{
    border-radius: 15px;
    border:0.5px solid #939598 !important;
    opacity: 1;
    width: 100%;
    margin-top: 30px;

    .contact-us-description-title{
        padding: 30px 20px 30px 20px;
        
        h1 {
            font-size: 16px !important;
            font-weight: bold;
            text-align: left;
            letter-spacing: 0px;
            color: #0D0D0D !important;
            margin-bottom: 26px;
        }

        .subtitle {
            p {
                font-size: 14px;
                text-align: left;
                letter-spacing: 0px;
                color: #808285 !important;
                font-weight: normal;

                a {
                    color: #FB9600 !important;
                }
            }
        }
    }
}

.row-map-contact-us{
    max-height: 300px !important;
    border-radius: 15px !important;
    overflow: hidden !important;
    margin: 0px !important;
        .contact-us-map-warp{
            width: fit-content;
            // position: fixed;
            // top: -150px;
            max-height: fit-content;
            touch-action: manipulation;
            transform: translate3d(0px, 0px, 0px);
            border-radius: 15px !important;//
            overflow: hidden !important;//
            .contact-us-map{
                min-height: 300px !important;
                width: fit-content !important;
                border-radius: 15px !important;//
                overflow: hidden !important;//
                
            }
        }
}

.contact-us-company-detail{
    padding: 20px;
    .contact-us-company-detail-title{
        font-size: 24;
        font-weight: bold;
        text-align: left;
        letter-spacing: 0px;
        color: #0D0D0D;
        opacity: 1;
        padding: 10px 0 0 0;
        .contact-us-company-detail-description{
            font-size: 14px;
            text-align: left;
            letter-spacing: 0px;
            color: #808285;
            opacity: 1;
            font-weight: normal;
        }
    }
}

.contact-us-modal-ok{
    margin: auto !important;
}
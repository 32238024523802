@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");
@import "../_variables.scss";

$bg-color: #f1f1f1;
$primary-color: rgb(43, 40, 40);
$highlight-color: #272424;
$light-color: #fff;
$border-color: #ddd;
$ff-primary: "Poppins", sans-serif;

@mixin break {
  thead {
    display: none;
    background-color: rgb(63, 62, 62);
  }

  tr {
    display: block;
    margin-bottom: 25px;
  }

  td {
    display: block;
    position: relative;
    padding-left: 120px;
    text-align: left;
    border-bottom: 5px;

    &:last-child {
      border-bottom: 1px solid $border-color;
    }

    &::before {
      content: attr(data-heading);
      position: absolute;
      top: 0;
      left: 0;
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: $primary-color;
      color: $light-color;
      font-size: 0.75rem;
      padding: 0 5px;
      justify-content: center;
      font-weight: 500;
      text-align: center;
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: $ff-primary;
}

.header {
  padding: 50px 15px;
  background-color: $bg-color;
  text-align: center;
  margin-bottom: 40px;

  h1 {
    font-size: 40px;
    font-weight: 300;
  }
}

.table-container {
  max-width: 100%;
  margin: 0 auto 40px;

  &__title {
    background-color: $highlight-color;
    color: $light-color;
    text-align: center;
    padding: 10px;

    h2 {
      font-size: 24px;
      font-weight: 300;
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        background-color: transparent;
      }
    }

    td {
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      padding: 7px;
      text-align: center;
    }
    .td-btm-border {
      border-bottom: 2px solid black !important;
      padding-bottom: 15px !important;
    }
    .td-text-align-left {
      text-align: left;
    }

    th {
      border: 1px solid $border-color;
      padding: 10px;
      text-align: center;
      background-color: $highlight-color;
      color: $light-color;
      font-weight: 500;
    }

    tr {
      &:nth-child(even) {
        background-color: $bg-color;
      }
    }

    &--break-xl {
      @media (max-width: 1920px) {
        @include break;
      }
    }

    &--break-lg {
      @media (max-width: 1080px) {
        @include break;
      }
    }

    &--break-md {
      @media (max-width: 1024px) {
        @include break;
      }
    }

    &--break-sm {
      @media (max-width: 575px) {
        @include break;
      }
    }
  }
}
//pagination

.pagination-container {
  display: flex;
  position: relative;
  border-top: 1px solid #939598;
  & .sticky {
    position: relative;
    border-top: 1px solid #939598;
    background-color: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(8px);
    bottom: 0px;
  }
  & ul {
    display: flex;
    flex: 1;
    justify-content: center;
    & li {
      & button {
        border: none;
        background-color: transparent;
        border-radius: 10px;
        &:not(:disabled):hover {
          background-color: #f9e0c0;
        }
        &[aria-current="true"] {
          color: #f09108;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-mobile-max) {
  .pagination-container {
    padding: 45px 0px 0px 0px !important;
    width: 100%;
    & .sticky {
      width: calc(100% - 30px);
      padding: 35px 0px 0px 0px !important;
      font-size: 1em;
      & button {
        padding: 5px 15px;
      }
    }
    & ul {
      padding-top: 20px;
      & li {
        text-align: center;
        &:has(img) {
          position: absolute;
          top: 0px;
          width: 25%;
          &:first-child {
            left: 0px;
          }
          &:nth-child(2) {
            left: 25%;
          }
          &:nth-last-child(2) {
            right: 25%;
          }
          &:last-child {
            right: 0px;
          }
        }
        &:not(:has(img)){
          width: 100%;
        }
        & button {
          padding: 10px 15px;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-small-tablet-min) {
  .ps-width-half, .col-md-6 {
    & .pagination-container {
      width: 50vw;
      & .sticky {
        width: 50vw;
      }
      & ul {
        & li {
          & button {
            padding: 10px 10px;
          }
        }
      }
    }
  }
  .pagination-container {
    padding: 10px 0px;
    width: 100%;
    max-width: 1220px;
    & .sticky {
      width: 100%;
      max-width: 1220px;
      padding: 5px 0px;
    }
    & ul {
      & li {
        & button {
          padding: 10px 35px;
        }
      }
    }
  }
}

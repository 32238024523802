@import "../mixins";
.property-list-card-body {
  margin-bottom: 30px;
  border-radius: 15px !important;
  border: 1px solid $border-grey;
  &.homePage {
    display: flex;
    flex-direction: column;
  }
  &.bestDeal-card {
    border: 2px solid $nextsix-orange !important;
  }
  &.property-list-project {
    .property-row-nameText2 {
      -webkit-line-clamp: 3 !important;
    }
  }
  .property-list-cardImg2 {
    border-radius: 13px !important;
    object-fit: cover;
    aspect-ratio: 1.3;
  }
  .property-list-card-row-body2 {
    display: flex;
    flex-direction: column;
    .property-row-tagGroup2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .property-row-saleTag2-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        flex: 1;
        .property-row-saleTag2 {
          display: flex;
          align-items: center;
          background-color: $border-grey;
          color: #fff;
          border-radius: 4px;
          white-space: nowrap;
          &.bestDeal {
            background-color: $nextsix-orange;
          }
          &.buy {
            background-color: $green-tag;
          }
          &.rent {
            background-color: $red-tag;
          }
          &.rent-type {
            background-color: $blue-tag;
          }
          &.new-project {
            background-color: #6ccf24;
          }
        }
      }
      .property-row-createdTag2 {
        color: $grey-text;
      }
      .property-row-devLogoTag2 {
        // color: #A7A9AC;
        display: flex;
        justify-content: right;
        .property-row-devLogoTag2-img {
          object-fit: contain;
        }
      }
    }
    .property-row-locGroup2 {
      color: $grey-text;
      display: flex;
      .property-row-locText2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .property-row-priceText2-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: baseline;
      align-items: baseline;
      color: $nextsix-orange;
    }
    .property-row-priceText2 {
      color: $nextsix-orange;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .property-row-nameText2 {
      display: -webkit-box;
      color: #404041;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .property-row-nameText3 {
      line-height: 1.2;
      color: $grey-text;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: normal; /* allows text to wrap */
      overflow: hidden;
      -webkit-line-clamp: 2;
    }
    .property-row-iconsGroup2-wrapper {
      flex-direction: row;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-end;
      .property-row-iconsGroup2 {
        display: flex;
        color: $grey-text;
        font-size: 12px;
        margin: 0 10px 0 0;
        align-self: flex-end;
        gap: 4px;
        .property-row-iconImg2 {
          width: 12px;
        }
        .property-row-iconText2 {
          display: flex;
          vertical-align: bottom;
        }
      }
    }
  }
  .property-row-footer2 {
    border-top: 1px solid $border-grey;
    margin: 0px 5px;
    .property-row-footerGroup2 {
      margin: 5px 0 5px 0;
      display: flex;
      .property-row-footerAvatar2 {
        margin: 0 14px 0 10px;
        object-fit: cover;
      }
      .property-row-footerAgentInfo2 {
        display: flex;
        flex-direction: column;
        flex: 1;
        .property-row-footerCompany2 {
          font-size: 12px;
          color: $grey-text;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: auto;
        }
        .property-row-footerAgentName2 {
          font-size: 16px;
          color: #414042;
          font-weight: 500;
          line-height: 1.1;
        }
      }
    }
  }
}
/*  MOBILE START  */
@media (min-width: 1px) and (max-width: $screen-mobile-max) {
  .property-list-card-body {
    margin: 0px auto 20px;
    // Customize for Home Page
    &.homePage {
      width: 290px;
      .property-list-card-row-body2 {
        height: 200px;
      }
    }
    // Customize for Developer Project Search Page
    &.property-list-project {
      .property-list-card-row-body2 {
        height: 250px;
        .property-row-tagGroup2 {
          flex-direction: row;
        }
      }
    }
    // Customize for Agent Detail Page
    &.agentDetail {
      .property-list-card-row-body2 {
        height: 220px;
        .property-row-tagGroup2 {
          .property-row-createdTag2 {
            font-size: 12px;
          }
        }
        .property-row-locGroup2 {
          font-size: 14px;
        }
        .property-row-priceText2 {
          font-size: 28px;
        }
        .property-row-nameText2 {
          font-size: 16px;
          -webkit-line-clamp: 2;
        }
        .property-row-nameText3 {
          font-size: 13px;
        }
        .property-row-iconsGroup2-wrapper {
          gap: 8px;
          .property-row-iconsGroup2 {
            font-size: 12px;
            gap: 6px;
            .property-row-iconImg2 {
              width: 14px;
            }
          }
        }
      }
    }
    // General Style
    .property-list-cardImg2 {
      aspect-ratio: 1.3;
    }
    .property-list-card-row-body2 {
      height: 220px;
      padding: 5px 10px 15px 10px;
      .property-row-tagGroup2 {
        flex-direction: column-reverse;
        gap: 5px;
        .property-row-saleTag2-wrapper {
          flex-wrap: wrap;
          gap: 1px;
          .property-row-saleTag2 {
            font-size: 10px;
            padding: 0px 4px;
            gap: 2px;
            &.bestDeal {
              .bestDeal-icon {
                width: 15px;
              }
            }
          }
        }
        .property-row-createdTag2 {
          font-size: 10px;
          text-align: left;
          align-self: flex-start;
          line-height: 1.4;
          &.property-row-createdTag2-v2 {
            flex: 1;
          }
        }
        .property-row-devLogoTag2 {
          font-size: 12px;
          height: 40px;
          width: 60px;
          .property-row-devLogoTag2-img {
            margin: 2px;
          }
        }
      }
      .property-row-locGroup2 {
        font-size: 12px;
        margin: 3px 0;
        .property-row-locText2 {
          margin: 0 0 0 5px;
        }
      }
      .property-row-priceText2-wrapper {
        h6 {
          font-size: 12px;
          line-height: 1;
          margin: 0px;
        }
      }
      .property-row-priceText2 {
        font-size: 19px;
        margin-bottom: 0.1rem;
        line-height: 1;
      }
      .property-row-nameText2 {
        font-size: 14px;
        margin: 0px 0px 2px 0px;
      }
      .property-row-nameText3 {
        font-size: 10px;
        margin: 0px 0px 5px;
        line-height: 1.2;
        -webkit-line-clamp: 2; /* limits the text to 2 lines */
      }
      .property-row-iconsGroup2-wrapper {
        align-items: center;
        gap: 0;
        .property-row-iconsGroup2 {
          font-size: 12px;
          margin: 0 5px 0 0;
          gap: 4px;
          .property-row-iconImg2 {
            width: 12px;
          }
        }
      }
    }
    .property-row-footer2 {
      margin: 0px 5px;
      .property-row-footerGroup2 {
        margin: 5px 0 5px 0;
        .property-row-footerAvatar2 {
          margin: 0 10px 0 10px;
        }
        .property-row-footerAgentInfo2 {
          .property-row-footerCompany2 {
            font-size: 12px;
          }
          .property-row-footerAgentName2 {
            font-size: 14px;
            line-height: 1.1;
          }
        }
      }
    }
  }
} 
/*  MOBILE END  */
/*  TABLET START  */
@media (min-width: $screen-small-tablet-min) and (max-width: $screen-tablet-max) {
  .property-list-card-body {
    &.homePage {
      width: 280px;
      .property-list-card-row-body2 {
        height: 200px;
      }
    }
    &.property-list-project {
      .property-list-card-row-body2 {
        height: 230px;
      }
    }
    // Customize for Agent Detail Page
    &.agentDetail {
      .property-list-card-row-body2 {
        .property-row-tagGroup2 {
          .property-row-createdTag2 {
            font-size: 10px;
          }
        }
        .property-row-locGroup2 {
          font-size: 12px;
          .locIcon2 {
            width: 10px;
          }
        }
        .property-row-priceText2 {
          font-size: 22px;
        }
        .property-row-nameText2 {
          font-size: 16px;
        }
        .property-row-nameText3 {
          font-size: 13px;
        }
        .property-row-iconsGroup2-wrapper {
          gap: 6px;
          .property-row-iconsGroup2 {
            font-size: 12px;
            gap: 4px;
            align-self:self-end;
            .property-row-iconImg2 {
              width: 12px;
            }
          }
        }
      }
    }
    .property-list-card-row-body2 {
      height: 200px;
      padding: 5px 15px 15px 15px;
      .property-row-tagGroup2 {
        align-items: flex-start;
        .property-row-saleTag2-wrapper {
          gap: 2px;
          flex-wrap: wrap;
          .property-row-saleTag2 {
            font-size: 10px;
            padding: 2px 5px;
            gap: 2px;
            &.bestDeal {
              .bestDeal-icon {
                width: 12px;
              }
            }
          }
        }
        .property-row-createdTag2 {
          font-size: 10px;
          text-align: right;
          align-self: flex-start;
          line-height: 1.4;
          min-width: 80px;
          &.property-row-createdTag2-v2 {
            flex: 0 1 30%;
          }
        }
        .property-row-devLogoTag2 {
          font-size: 12px;
          height: 50px;
          width: 70px;
          .property-row-devLogoTag2-img {
            margin: 2px;
          }
        }
      }
      .property-row-locGroup2 {
        font-size: 12px;
        margin: 3px 0;
        .property-row-locText2 {
          margin: 0 0 0 5px;
        }
      }
      .property-row-priceText2-wrapper {
        h6 {
          font-size: 12px;
          line-height: 1;
          margin: 0px;
        }
      }
      .property-row-priceText2 {
        font-size: 20px;
        margin-bottom: 0.1rem;
        line-height: 1;
      }
      .property-row-nameText2 {
        font-size: 16px;
        margin: 0px 0 2px;
      }
      .property-row-nameText3 {
        font-size: 10px;
        margin: 0px 0px 5px;
        line-height: 1.2;
        -webkit-line-clamp: 2; /* limits the text to 2 lines */
      }
      .property-row-iconsGroup2-wrapper {
        .property-row-iconsGroup2 {
          font-size: 12px;
          margin: 0 10px 0 0;
          gap: 4px;
          .property-row-iconImg2 {
            width: 12px;
          }
        }
      }
    }
    .property-row-footer2 {
      margin: 0px 5px;
      .property-row-footerGroup2 {
        margin: 5px 0 5px 0;
        .property-row-footerAvatar2 {
          margin: 0 10px 0 10px;
        }
        .property-row-footerAgentInfo2 {
          .property-row-footerCompany2 {
            font-size: 12px;
          }
          .property-row-footerAgentName2 {
            font-size: 14px;
            line-height: 1.1;
          }
        }
      }
    }
  }
}
/*  TABLET END  */
/*  LARGE TABLET START  */
@media (min-width: $screen-laptop-min) and (max-width: $screen-laptop-max) {
  .property-list-card-body {
    margin-bottom: 30px;
    &.homePage {
      width: 270px;
      .property-list-card-row-body2 {
        height: 200px;
      }
    }
    &.property-list-project {
      .property-list-card-row-body2 {
        height: 230px;
      }
    }
    // Customize for Agent Detail Page
    &.agentDetail {
      .property-list-card-row-body2 {
        .property-row-tagGroup2 {
          .property-row-createdTag2 {
            font-size: 9px;
          }
        }
        .property-row-locGroup2 {
          font-size: 11px;
          .locIcon2 {
            width: 10px;
          }
          .property-row-locText2 {
            margin: auto 0px auto 4px;
          }
        }
        .property-row-priceText2 {
          font-size: 18px;
        }
        .property-row-nameText2 {
          font-size: 13px;
        }
        .property-row-nameText3 {
          font-size: 11px;
        }
        .property-row-iconsGroup2-wrapper {
          gap: 4px;
          .property-row-iconsGroup2 {
            font-size: 10px;
            gap: 2px;
            align-self:self-end;
            .property-row-iconImg2 {
              width: 12px;
            }
          }
        }
      }
    }
    .property-list-card-row-body2 {
      height: 200px;
      padding: 5px 15px 15px 15px;
      .property-row-tagGroup2 {
        .property-row-saleTag2-wrapper {
          flex-wrap: wrap;
          gap: 2px;
          .property-row-saleTag2 {
            font-size: 10px;
            padding: 2px 5px;
            gap: 2px;
            &.bestDeal {
              .bestDeal-icon {
                width: 12px;
              }
            }
          }
        }
        .property-row-createdTag2 {
          font-size: 10px;
          text-align: right;
          align-self: flex-end;
          line-height: 1.4;
          &.property-row-createdTag2-v2 {
            flex: 0 1 40%;
          }
        }
        .property-row-devLogoTag2 {
          font-size: 12px;
          height: 50px;
          width: 70px;
          .property-row-devLogoTag2-img {
            margin: 2px;
          }
        }
      }
      .property-row-locGroup2 {
        font-size: 12px;
        margin: 3px 0;
        .property-row-locText2 {
          margin: 0 0 0 5px;
        }
      }
      .property-row-priceText2-wrapper {
        h6 {
          font-size: 12px;
          line-height: 1;
          margin: 0px;
        }
      }
      .property-row-priceText2 {
        font-size: 24px;
        margin-bottom: 0.1rem;
        line-height: 1;
      }
      .property-row-nameText2 {
        font-size: 16px;
        margin: 0px 0 2px;
      }
      .property-row-nameText3 {
        font-size: 10px;
        margin: 0px 0px 5px;
        line-height: 1.2;
        -webkit-line-clamp: 2; /* limits the text to 2 lines */
      }
      .property-row-iconsGroup2-wrapper {
        .property-row-iconsGroup2 {
          font-size: 12px;
          margin: 0 10px 0 0;
          gap: 4px;
          .property-row-iconImg2 {
            width: 12px;
          }
        }
      }
    }
    .property-row-footer2 {
      margin: 0px 5px;
      .property-row-footerGroup2 {
        margin: 5px 0 5px 0;
        .property-row-footerAvatar2 {
          margin: 0 10px 0 10px;
        }
        .property-row-footerAgentInfo2 {
          .property-row-footerCompany2 {
            font-size: 12px;
          }
          .property-row-footerAgentName2 {
            font-size: 14px;
            line-height: 1.1;
          }
        }
      }
    }
  }
}
/*  LARGE TABLET END  */
/*  DESKTOP START  */
@media (min-width: $screen-desktop-min) {
  .property-list-card-body {
    margin-bottom: 30px;
    // Customize for Home Page
    &.homePage {
      width: 270px;
      .property-list-card-row-body2 {
        height: 200px;
      }
    }
    // Customize for Developer Project
    &.property-list-project {
      .property-list-card-row-body2 {
        height: 250px;
      }
    }
    // Customize for Agent Detail Page
    &.agentDetail {
      .property-list-card-row-body2 {
        .property-row-tagGroup2 {
          .property-row-createdTag2 {
            font-size: 9px;
          }
        }
        .property-row-locGroup2 {
          font-size: 10px;
          .locIcon2 {
            width: 8px;
          }
          .property-row-locText2 {
            margin: auto 0px auto 2px;
          }
        }
        .property-row-priceText2 {
          font-size: 20px;
        }
        .property-row-nameText2 {
          font-size: 13px;
        }
        .property-row-nameText3 {
          font-size: 11px;
        }
        .property-row-iconsGroup2-wrapper {
          gap: 4px;
          .property-row-iconsGroup2 {
            font-size: 10px;
            gap: 2px;
            align-self:self-end;
            .property-row-iconImg2 {
              width: 12px;
            }
          }
        }
      }
    }
    .property-list-card-row-body2 {
      height: 200px;
      padding: 5px 15px 15px 15px;
      .property-row-tagGroup2 {
        .property-row-saleTag2-wrapper {
          gap: 2px;
          .property-row-saleTag2 {
            font-size: 10px;
            padding: 2px 5px;
            gap: 2px;
            &.bestDeal {
              .bestDeal-icon {
                width: 15px;
              }
            }
          }
        }
        .property-row-createdTag2 {
          font-size: 11px;
          text-align: right;
          align-self: flex-end;
          line-height: 1.2;
          &.property-row-createdTag2-v2 {
            flex: 0 1 40%;
          }
        }
        .property-row-devLogoTag2 {
          font-size: 12px;
          height: 50px;
          width: 70px;
          .property-row-devLogoTag2-img {
            margin: 2px;
          }
        }
      }
      .property-row-locGroup2 {
        font-size: 14px;
        margin: 5px 0;
        .property-row-locText2 {
          margin: 0 0 0 8px;
        }
      }
      .property-row-priceText2-wrapper {
        h6 {
          font-size: 14px;
          line-height: 1;
          margin: 0px;
        }
      }
      .property-row-priceText2 {
        font-size: 26px;
        margin-bottom: 0.1rem;
        line-height: 1;
      }
      .property-row-nameText2 {
        font-size: 16px;
        padding: 0px;
        margin: 0px 0px 2px 0px;
      }
      .property-row-nameText3 {
        font-size: 12px;
        margin: 0px 0px 5px;
      }
      .property-row-iconsGroup2-wrapper {
        .property-row-iconsGroup2 {
          font-size: 12px;
          margin: 0 15px 0 0;
          gap: 5px;
          .property-row-iconImg2 {
            width: 14px;
          }
          .property-row-iconText2 {
            gap: 5px;
          }
        }
      }
    }
    .property-row-footer2 {
      margin: 0px 5px;
      .property-row-footerGroup2 {
        margin: 5px 0 5px 0;
        .property-row-footerAvatar2 {
          margin: 0 14px 0 10px;
        }
        .property-row-footerAgentInfo2 {
          .property-row-footerCompany2 {
            font-size: 12px;
          }
          .property-row-footerAgentName2 {
            font-size: 16px;
            line-height: 1.1;
          }
        }
      }
    }
  }
}
/*  DESKTOP END  */
@media (max-width: 380px) {
  .property-list-card-body {
    &.homePage {
      width: 250px;
    }
    .property-row-priceText2 {
      font-size: 18px !important;
    }
    .property-row-nameText2 {
      font-size: 14px !important;
    }
  }
}

.tabs-row {
  width: 100%;
  position: sticky;
  background: #f5f6fa;
  top: 90px;
  border-bottom: 1px solid #e7e7e7;
}

.btn-tab {
  margin: 0px;
  background-color: transparent !important;
  border-color: transparent !important;
  border-radius: 0 !important;
  border-bottom: 0.2rem solid transparent !important;
  height: 50px !important;
}

.btn-tab.active {
  border-bottom: 0.25rem solid #fb9600 !important;
}

.stats-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.stats-h1 {
  display: flex;
  flex: 1;
  justify-content: start;
  align-items: center;
  height: 40px;
  font-size: 32px;
}

.stats-h2 {
  font-size: 28px;
}

.stats-card {
  flex-basis: 30%;
  margin: 0 10px;
}

.stats-h6 {
  text-align: center;
}

.stats-small-div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5fce48;
}

.stats-span {
  font-size: 12px;
}

.prop-card {
  border-radius: 10px;
  height: 400px;
  width: 280px;
  background-size: cover;
  align-content: end;
  color: white;
}

.prop-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.prop-content-h5 {
  font-style: bold;
  margin: 0;
}

.prop-content-btn-container {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
}

.prop-content-btn-container a:hover {
  text-decoration: none;
  color: white;
}
.prop-content-btn {
  border: 1px solid #fb9600 !important;
  border-radius: 700px;
  width: 100px;
  background: #fb9600 !important;
  color: white;
  display: flex;
  justify-content: center;
}

.prop-card-container {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.area-small-tag-a:hover {
  text-decoration: none;
}

.area-small-tag {
  min-width: 100px;
  padding: 5px 25px;
  border-radius: 5px;
  cursor: pointer;
}

.area-small-tag-a:hover > .area-small-tag {
  background: #fb9600 !important;
  color: white !important;
}

.area-small-tag-showmore {
  display: flex;
  gap: 10px;
}

.area-small-tag-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.landing-container {
  display: flex;
  flex-direction: column;
  background-color: #f5f6fa;
  padding: 10px 0;
}

.title-h3 {
  text-align: center;
}

#content {
  padding: 20px 0;
  scroll-margin-top: 150px;
}

#property,
#area {
  padding: 40px 0;
  scroll-margin-top: 150px;
}

@import "../_mixins";

.blog_details {
	position: relative;
	& > summary::marker {
		content: '';
	}
	& > summary:after {
		content: '^';
		display: inline-block;
		padding: 0 4px;
		transform: rotate(180deg) scaleX(1.4) scaleY(0.8);
		transition: 0.25s;
	}
}
.blog_details[open]  {
	& p {
		display: block;
		padding-left: 14px;
	}
	& > summary:after {
		content: '^';
		display: inline-block;
		padding: 0 4px;
		transform: rotate(0deg) scaleX(1.4) scaleY(0.8);
		transition: .25s;
	}
}

.seo-footer-ul {
  list-style: none !important;
  padding-left: 10px !important;
  display: block !important;
}
.seo-footer-ul-nopadding {
  list-style: none !important;
  padding-left: 0px !important;
  display: block !important;
}
summary {
  display: block !important;
}
.seo-footer {
  max-width: 100%;
}
.seo-footer-seperator {
  padding: 0 5px 0 0;
}
.seo-footer-header-name {
  color: #f09108;
  font-weight: 500;
  font-size: 16px;
  padding: 20px 0 20px 0;
}
.seo-footer-title-name {
  font-weight: 500;
  font-size: 14px;
  padding: 10px 0 10px 0;
  color: #fff;
}
.seo-footer-description {
  // padding: 10px 0 10px 0;
  font-size: 12px;
  color: #fff;
}
.seo-footer-row {
  padding: 20px 0 0 0;
}
.seo-footer-col {
  padding: 0 0 25px 15px;
}
.seo-footer-show-more-btn {
  padding-left: 0;
  background-color: transparent;
  color: #fff;
  font-size: 13px;
  text-decoration: underline;
  border: transparent;

  &:hover {
    color: #f09108;
  }
}
.seo-footer-a-tag {
  font-size: 12px;
  color: #fff;
  text-decoration: none;
  &:hover {
    color: #f09108;
  }
}
.footer-top-wrapper2 {
  background-color: #06203a;
}

.footer-top-inner2 {
  display: flex;
  //align-items: center;
  padding: 40px 0;
  justify-content: space-between;
  border-bottom: 1px solid lightslategrey;
}

// .footer-top-innerLeft2 {

// }

.footer-top-innerRight2 {
  display: flex;
  width: 76%;
}

.footer-top-Listing-wrapper2 {
  max-width: 100%;
  background-color: #06203a;
}
.footer-top-Listing-wrapper2-col {
  padding: 10px 0px 0px 15px !important;
}
.footer-top-Listing-content {
  max-width: 100%;
  justify-content: end;
}
.footer-top-Listing-inner2 {
  display: flex;
  align-items: center;
  padding: 20px 0;
  justify-content: center;
  border-bottom: 1px solid lightslategrey
}
.footer-top-Listing-innerRight2 {
  // display: flex;
  width: 50%;
}
.footer-top-event2-listing,
.footer-top-contact2-listing,
.footer-top-term2-listing,
.footer-top-office2-listing {
  margin: 15px;
}

.footer-copyright2-listing {
  background-color: #f09108;
  text-align: center;
  color: #fff;
  font-size: 12px;
  padding: 10px 0;
  max-width: 100%;
}

.footer-Social2-listing {
  background-color: #f09108;
  text-align: center;
  color: #fff;
  font-size: 14px;
  padding: 10px 0;
  max-width: 100%;
}

.footer-title-list2 {
  list-style-type: none;
  padding: 0;
  margin: 12px 0 0 0;
}

.footer-title-listItem2 {
  color: #fff;
  font-size: 12px;
}

.footer-title-listItemLink2 {
  color: #fff;
  font-size: 12px;
}

.footer-title-listItemLink2:hover,
.footer-title-listItemLink2:active,
.footer-title-listItemLink2:focus {
  color: #f09108;
  font-size: 12px;
  text-decoration: none;
}

.footer-title-socialLink2 {
  color: #fff;
  font-size: 14px;
}

.footer-title-socialLink2:hover,
.footer-title-socialLink2:active,
.footer-title-socialLink2:focus {
  color: #fff;
  font-size: 14px;
  text-decoration: none !important;
}

.footer-title2 {
  font-size: 15px;
  color: #f09108;
  font-weight: 500;
}

.footer-copyright2 {
  background-color: #f09108;
  text-align: center;
  color: #fff;
  font-size: 12px;
  padding: 10px 0;
}

.footer-Social2-listing-col-left {
  display: flex;
  text-align: center;
  align-items: center;
  .footer-title-socialLink2 {
    display: flex;
    align-items: center;
  }
  img {
    height: 30px;
  }
}
.footer-Social2-listing-col-right {
  display: flex;
  text-align: right;
  align-items: center;
  img {
    height: 30px;
  }
}

@media (min-width: $screen-desktop-min) {
  .footer-Social2-listing-col-left {
    justify-content: flex-start;
    gap: 10px;
  }
  .footer-Social2-listing-col-right {
    justify-content: flex-end;
    gap: 0px 15px;
  }
  .footer-top-office2 {
    margin: 0 70px 0 0;
  }

  .public-holiday2 {
    width: 250px;
  }
}

@media (min-width: $screen-tablet-min) and (max-width: $screen-laptop-max) {
  .footer-Social2-listing-col-left {
    justify-content: flex-start;
    gap: 10px;
    .footer-title-socialLink2 {
      > img {
        height: 30px;
      }
    }
  }
  .footer-Social2-listing-col-right {
    justify-content: flex-end;
    gap: 0px 15px;
  }
  .footer-top-innerLeft2 {
    align-self: baseline;
    margin: 20px 0 0 0;
    width: 40%;
  }

  .footer-top-innerRight2 {
    flex-wrap: wrap;
  }

  .footer-top-contact2,
  .footer-top-term2 {
    margin: 0 0 28px 0;
  }
}

@media (min-width: $screen-mobile-min) and (max-width: $screen-small-tablet-max) {
  .seo-footer {
    display: flex;
    flex-direction: column;
    > div {
      .seo-footer-row {
        width: calc(100% - 20px)
      }
    }
  }
  .footer-Social2-listing {
    .container {
      .row {
        margin: 0px;
      }
    }
  }
  .footer-Social2-listing-col-left {
    flex-wrap: wrap;
    justify-content: center;
    .footer-title-socialLink2 {
      width: 100px;
      justify-content: center;
    }
  }
  .footer-Social2-listing-col-right {
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    gap: 2px 15px;
    .footer-social-text2 {
      width: 100%;
    }
  }
  .footer-top-inner2 {
    flex-direction: column;
    text-align: center;
    padding: 20px 0;
    align-items: center;
    .footer-top-innerRight2 {
      flex-direction: column;
      align-items: center;
    }
  }

  .footer-top-innerLeft2,
  .footer-top-contact2,
  .footer-top-term2,
  .footer-top-office2,
  .footer-top-merchant2 {
    margin: 0 0 28px 0;
  }

  .footer-title-list2 {
    margin: 0;
  }

  .footer-title-listItem2 {
    padding: 0 0 0 0 !important;
  }

  .footer-copyright-text2 {
    font-size: 10px;
  }
  .footer-social-text2 {
    width: 100%;
    font-size: 14px;
  }
}

@import "../_mixins";

.suggestion-wrap{
    display: flex;
    flex-direction: column;
    padding: 0.50em 0.75em;
    width: 100%;
}

.suggestion-top {
    display: flex;
    align-items: center;
    width: 100%;
}

.suggestion-icon{
    height: 16px;
    width: 16px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.suggestion-title-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
}

.suggestion-bottom {
    display: flex;
    width: 100%;
    padding-left: 24px;
}

.suggestion-title1{
    font-size: 15px;
    color: grey;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.suggestion-title2{
    font-size: 12px;
    color: #afafaf;
}

.suggestion-title3{
    font-size: 14px;
    color: #afafaf;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.click-search {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    flex-wrap: wrap;
}

.click-search-query {
    margin-left: 0.3em;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.click-search svg {
    margin: 0 0.4em;
}

.rw-list-optgroup {
    font-weight: 400 !important;
    padding: 1em 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #afafaf;
    position: sticky;
    top: 0;
    background: white;
}

.rw-list {
    overflow-x: hidden !important;
    padding-top: 0 !important;
}

.rw-list-option {
    padding: 0 !important;
}

@media (max-width: $screen-mobile-max) and (orientation: portrait) {
    .rw-popup-container {
        margin: 5px -65px 0px -52px;
        .rw-popup {
            .rw-list {
                .rw-list-optgroup {
                  padding: 5px 10px;
                  .click-search {
                    font-size: 12px;
                  }
                }
              }
        }
        
      }
}
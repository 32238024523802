@import "../_variables.scss";

$dap-btn-color: #E13247;

@media (max-width: 550px) {
    .dap-btn-confirm {
        padding: 0 24px !important;
    }
}

@media (max-width: $screen-small-tablet-max) {
    .dap-container {
        margin: 100px 50px 70px !important;
    }
}

.dap-container {
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // height: 430px;
    height: max-content;
    margin: 100px 130px 70px 130px;
    padding: 48px;
}

.dap-title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}

.dap-text {
    color: $grey-text;
    font-size: 16px;
    padding: 24px 0 32px 0;

    p {
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        gap: 10px;

        &::before {
            content: '';
            display: block;
            background: url('../../assets/icons/dap-x-circle-icon.svg') no-repeat;
            background-size: contain;
            width: 20px;
            height: 20px;
        }
    }

    .dap-email {
        color: $nextsix-orange;
        font-weight: 500;
    }
}

.dap-input {
    height: 44px;
    max-width: 375px;
    width: 100%;
    border: 1px solid #234652;
    border-radius: 6px;
    padding: 10px;

    &:focus {
        outline: none;
    }
}

.dap-btn-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 24px;
    margin: 22px;

    button {
        border: 0;
        font-size: 16px;
        width: max-content;
        height: 44px;
        border-radius: 7px;
    }

    .dap-btn-confirm {
        background-color: $dap-btn-color;
        color: white;
        padding: 0 36px;
    }

    .dap-btn-outline {
        background-color: transparent;
        color: $dap-btn-color;
        font-weight: 500;
    }

}

.dap-btn-ok {
    border: 0;
    font-size: 18px;
    min-width: 150px;
    height: 44px;
    border-radius: 7px;
    background-color: $nextsix-orange;
    color: white;
}

.dap-err-msg {
    color: red;
    margin-top: 10px;
}